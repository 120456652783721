import React, { useEffect, useState } from "react";
import infoIMG from "../../SVG/Terminos.svg";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerSolicitudInfoInscripcion,
  agregarSolicitudInfoInscripcion,
  obtenerCarrerasByNivelId,
} from "../../redux/solicitudesSlice";
import { toast } from "react-toastify";
import OperationStatus from "../../config/OperationStatus";
import ReactSelect from "../../Components/ReactSelect";
import FileContainer from "./FileContainer";
import Required from "../../Components/Required";

export default function Inscripcion(props) {
  const [listaNiveles, setListaNiveles] = useState([]);
  const [listaCarreras, setListaCarreras] = useState([]);
  const [listaRazones, setListaRazones] = useState([]);
  const [listaFormas, setListaFormas] = useState([]);
  const [listaAsesores, setListaAsesores] = useState([]);

  const dispatch = useDispatch();
  const { solicitudModel, carreras } = useSelector(
    (state) => state.solicitudes
  );
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const experienciaEducacionVirtual = watch("experienciaEducacionVirtual");
  const esMayor = watch("esMayor");
  const nivelId = watch("nivelId");
  const carreraId = watch("carreraId");

  useEffect(() => {
    dispatch(obtenerSolicitudInfoInscripcion(props.solicitudId));
  }, []);

  useEffect(() => {
    let a = solicitudModel?.carrerasNivelesAcademicos?.map((item, i) => {
      return { label: item.nivelAcademicoNombre, value: item.nivelId };
    });
    setListaNiveles(a);
    let c = solicitudModel?.razonesElegirnos?.map((item, i) => {
      return { label: item.razonNombre, value: item.razonElegirnosId };
    });
    setListaRazones(c);
    let d = solicitudModel?.formasConocernos?.map((item, i) => {
      return { label: item.formaNombre, value: item.formaConocernosId };
    });
    setListaFormas(d);
    let e = solicitudModel?.asesores?.map((item, i) => {
      return { label: item.asesorNombre, value: item.asesorId };
    });
    setListaAsesores(e);
  }, [solicitudModel.carrerasNivelesAcademicos]);

  useEffect(() => {
    if (nivelId) dispatch(obtenerCarrerasByNivelId(nivelId));
  }, [nivelId]);

  useEffect(() => {
    let a = carreras?.map((item, i) => {
      return {
        label: `(${item.codigo}) ${item.carreraNombre}`,
        value: item.carreraId,
      };
    });

    if (!a.some((x) => x.value == carreraId)) {
      setValue("carreraId", undefined);
    }

    setListaCarreras(a);
  }, [carreras]);

  useEffect(() => {
    if (solicitudModel) {
      setValue("solicitudId", solicitudModel.solicitudId);
      setValue("nivelId", solicitudModel.nivelId);
      setValue("fechaInicio", solicitudModel.fechaInicio);
      setValue("etapaActual", solicitudModel.etapaActual);
      setValue("carreraId", solicitudModel.carreraId);
      setValue("periodoId", solicitudModel.periodoId);
      setValue(
        "experienciaEducacionVirtual",
        solicitudModel.experienciaEducacionVirtual
      );
      if (solicitudModel.infoInscripcionModificado) {
        setValue("razonElegirnosId", solicitudModel.razonElegirnosId);
        setValue("formaConocernosId", solicitudModel.formaConocernosId);
        setValue("asesorId", solicitudModel.asesorId);
      }
      setValue("aceptaTerminos", solicitudModel.aceptaTerminos);
      setValue("encargadoLegalNombre", solicitudModel.encargadoLegalNombre);
      setValue("encargadoLegalDni", solicitudModel.encargadoLegalDni);
      setValue("encargadoLegalTelefono", solicitudModel.encargadoLegalTelefono);
      setValue("esMayor", solicitudModel.esMayor);
      setValue("firmaId", solicitudModel.firmaId);
      setValue("firma", dataURLtoFile(solicitudModel.firma, "firma"));
      setValue(
        "responsableDniAdjunto",
        dataURLtoFile(
          solicitudModel.responsableDniAdjunto,
          "responsableDNIAdjunto"
        )
      );
      setValue(
        "responsableDniAdjuntoId",
        solicitudModel.responsableDniAdjuntoId
      );
    }
  }, [solicitudModel]);

  const onSubmit = async (d) => {
    d.firma = dataURLtoFile(d.firma);

    if (d.etapaActual === 6) d.etapaActual += 1;
    d.estadoId = 7;
    if (esMayor) {
      d.encargadoLegalNombre = null;
      d.encargadoLegalDni = null;
      d.encargadoLegalTelefono = null;
    }
    d.solicitudId = props.solicitudId;

    let data = getFormData(d);

    let { payload } = await dispatch(agregarSolicitudInfoInscripcion(data));
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      props.onGoNext();
      props.handleStepChange(d.etapaActual);
    } else {
      toast.error(payload.message);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    if (typeof dataurl == "string") {
      var arr = dataurl?.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr?.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr?.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return dataurl;
    }
  }

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (object[key] != null) formData.append(key, object[key]);
    });
    return formData;
  }

  return (
    <FormProvider
      watch={watch}
      register={register}
      getValues={getValues}
      setValue={setValue}
      errors={errors}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="form-solicitud">
        <div className="form-c">
          <div className="title-container">
            <span className="form-title ">Inscripción</span>
            <span className="sub-title d-block">
              Perfil: {solicitudModel?.perfilNombre ?? "no determinado"}
            </span>
          </div>
          <br />
          <div className="grid-container col-2 container">
            <div>
              <div className="grid-container col-2 col-lg-2 col-md-2 col-sm-12">
                <div className="grid-item">
                  <div className="form-group">
                    <label>Nivel académico</label>
                    <Required />

                    <ReactSelect
                      name="nivelId"
                      control={control}
                      lista={listaNiveles}
                      rules={{ required: "Campo requerido" }}
                    />
                    {errors.nivelId && (
                      <p className="error">{errors.nivelId.message}</p>
                    )}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label>Carrera a matricular</label>
                    <Required />

                    <ReactSelect
                      name="carreraId"
                      control={control}
                      lista={listaCarreras}
                      defValue={carreraId}
                      rules={{ required: "Campo requerido" }}
                    />

                    {errors.carreraId && (
                      <p className="error">{errors.carreraId.message}</p>
                    )}
                  </div>
                </div>

                <div className="grid-item">
                  <div className="form-group">
                    <label>¿Cuándo desea iniciar?</label>
                    <Required />
                    <input
                      {...register("fechaInicio", {
                        required: "Campo requerido",
                      })}
                      className="form-control"
                      type="text"
                    />
                    {errors.fechaInicio && (
                      <p className="error">{errors.fechaInicio.message}</p>
                    )}
                  </div>
                </div>
                {!esMayor && (
                  <>
                    <div className="grid-item">
                      <div className="form-group">
                        <label>
                          Padre, madre, representante legal o persona encargada
                        </label>
                        <Required />
                        <input
                          {...register("encargadoLegalNombre", {
                            required: "Campo requerido",
                          })}
                          className="form-control"
                          type="text"
                        />
                        {errors.encargadoLegalNombre && (
                          <p className="error">
                            {errors.encargadoLegalNombre.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid-item">
                      <div className="form-group">
                        <label>Teléfono</label>
                        <Required />
                        <input
                          {...register("encargadoLegalTelefono", {
                            required: "Campo requerido",
                          })}
                          className="form-control"
                          type="text"
                        />
                        {errors.encargadoLegalTelefono && (
                          <p className="error">
                            {errors.encargadoLegalTelefono.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid-item">
                      <div className="form-group">
                        <label>No. de identificación</label>
                        <Required />
                        <input
                          {...register("encargadoLegalDni", {
                            required: "Campo requerido",
                          })}
                          className="form-control"
                          type="text"
                        />
                        {errors.encargadoLegalDni && (
                          <p className="error">
                            {errors.encargadoLegalDni.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="grid-item">
                  <div className="form-group">
                    <label>¿Tiene experiencia con educación virtual?</label>
                    <Required />
                    <div className="check-container">
                      <div className="form-control-check flex ">
                        <input
                          type="radio"
                          value="Nunca he llevado cursos virtuales"
                          checked={
                            experienciaEducacionVirtual ==
                            "Nunca he llevado cursos virtuales"
                          }
                          {...register("experienciaEducacionVirtual", {
                            required: "Campo requerido",
                          })}
                        />
                        <label>Nunca he llevado cursos virtuales.</label>
                      </div>
                      <div className="form-control-check flex">
                        <input
                          type="radio"
                          value="Si, con Zoom, Teams, Meet y similares"
                          checked={
                            experienciaEducacionVirtual ==
                            "Si, con Zoom, Teams, Meet y similares"
                          }
                          {...register("experienciaEducacionVirtual", {
                            required: "Campo requerido",
                          })}
                        />
                        <label>Si, con Zoom, Teams, Meet y similares</label>
                      </div>
                      <div className="form-control-check flex">
                        <input
                          type="radio"
                          value="Si, en modalidad 100% virtual"
                          checked={
                            experienciaEducacionVirtual ==
                            "Si, en modalidad 100% virtual"
                          }
                          {...register("experienciaEducacionVirtual", {
                            required: "Campo requerido",
                          })}
                        />
                        <label>Si, en modalidad 100% virtual</label>
                      </div>
                    </div>
                    {errors.experienciaEducacionVirtual && (
                      <p className="error">
                        {errors.experienciaEducacionVirtual.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid-item">
                  <div className="form-group">
                    <label>Razón para elegir CENFOTEC</label>
                    <Required />
                    <ReactSelect
                      name="razonElegirnosId"
                      control={control}
                      lista={listaRazones}
                      rules={{ required: "Campo requerido" }}
                    />
                    {errors.razonElegirnosId && (
                      <p className="error">{errors.razonElegirnosId.message}</p>
                    )}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label>Se enteró de CENFOTEC por:</label>
                    <Required />
                    <ReactSelect
                      name="formaConocernosId"
                      control={control}
                      lista={listaFormas}
                      rules={{ required: "Campo requerido" }}
                    />
                    {errors.formaConocernosId && (
                      <p className="error">
                        {errors.formaConocernosId.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label>Atendido por:</label>
                    <Required />
                    <ReactSelect
                      name="asesorId"
                      control={control}
                      lista={listaAsesores}
                      rules={{ required: "Campo requerido" }}
                    />
                    {errors.asesorId && (
                      <p className="error">{errors.asesorId.message}</p>
                    )}
                  </div>
                </div>
                {!esMayor && (
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Documento DNI de representante legal </label>
                      <Required />
                      <FileContainer
                        loading={false}
                        titulo={solicitudModel.responsableDniAdjuntoNombre}
                        name="responsableDniAdjunto"
                      />
                      {errors.responsableDniAdjunto && (
                        <p className="error">
                          {errors.responsableDniAdjunto.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="button-control">
              <img src={infoIMG} alt="ilustración Información personal" />
              <div>
                <button onClick={props.onGoBack}>Atrás</button>
                <button type="submit">Continuar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
