import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { toast } from 'react-toastify';
import Modal from '../Adm/Components/Modal';
import ReactSelectGroup from './ReactSelectGroup';

function SummerNoteReactV({
  name,
  control,
  rules = {},
  isEditing,
  isCreating,
  height = 200,
  lista,
  val = undefined,
}) {
  const [showModal, setShowModal] = useState(false);
  const [valor, setValor] = useState(undefined);

  useEffect(() => {
    window.jQuery(document.getElementsByName(name)[0]).summernote({
      height: height,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['myButton', ['Variable']],
      ],
      buttons: {
        Variable: HelloButton,
      },
      onCreateLink: function (link) {
        return link; // return origin link text
      },
      callbacks: {
        onChange: onChange,
      },
    });
  }, []);

  const {
    field: { onChange,value, ref },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: '',
  });
  var HelloButton = function (context) {
    var ui = context.ui;

    // create button
    var button = ui.button({
      contents: 'Variables',
      click: function () {
        window
          .jQuery(document.getElementsByName(name)[0])
          .summernote('restoreRange');

        setShowModal(true);
      },
    });

    return button.render(); // return button as jquery object
  };

  useEffect(() => {
    window
      .jQuery(document.getElementsByName(name)[0])
      .summernote('code', value);
  }, [isEditing, isCreating, val]);

  const closeModal = (e) => {
    e.preventDefault();
    setShowModal(false);
  };
  const insertarParametro = (e) => {
    e.preventDefault();
    if (!valor) {
      toast.error('Debe seleccionar una variable');
      return false;
    }
    window
      .jQuery(document.getElementsByName(name)[0])
      .summernote('restoreRange');
    window
      .jQuery(document.getElementsByName(name)[0])
      .summernote('insertText', `{{${valor}}}`);

    setShowModal(false);
    setValor(undefined);
  };

  return (
    <>
      <textarea
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
      ></textarea>

      <Modal
        estado={showModal}
        minHeight={true}
        cambiarEstado={closeModal}
        titulo={'Variables'}
        clase={'medium'}
        buttonGroup={
          <>
            <button
              type="button"
              className="button-text"
              onClick={insertarParametro}
            >
              Insertar
            </button>
          </>
        }
      >
        <>
          <div>
            <div className="form-group">
              <label>Variables</label>
              <abbr title="Campo obligatorio">
                <i className="material-icons important"></i>
              </abbr>
              <ReactSelectGroup
                lista={lista}
                value={valor}
                handleChange={(e) => {
                  setValor(e);
                }}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default SummerNoteReactV;
