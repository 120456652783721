
import WebApi from '../Api/WebApi'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ObtenerTodos = createAsyncThunk(
    "asesores/ObtenerTodos",
    async (c,{ rejectWithValue }) => {
        try {
             if(typeof c === 'undefined'){
                const res = await WebApi.api().get(`admin/asesores`)
            return res.data.result;
             }
             else{
                const res = await WebApi.api().get(`admin/asesores?Nombre=${c}`)
                return res.data.result;
             }
        } catch (err) {
            return { success: false, message: err, show: true };
        }
    },
);

export const ObtenerById = createAsyncThunk(
    "asesores/ObtenerById",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().get('admin/asesores/' + id)
            return res.data.result;
        } catch (err) {
            return { success: false, message: err, show: true };
        }
    }
);

export const Insertar = createAsyncThunk(
    "asesores/Insertar",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().post('admin/asesores', item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const Actualizar = createAsyncThunk(
    "asesores/Actualizar",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().put('admin/asesores/' + item.id, item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const Eliminar = createAsyncThunk(
    "asesores/Eliminar",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().delete('admin/asesores/' + item.id)
            return res.data;
        } catch (err) {
            return rejectWithValue(err)
        }
    }
);



const asesoresSlice = createSlice({
    name: 'asesores',
    initialState: {
        asesores: [],
        model: {},
    },
    reducers: {
    },
    extraReducers: {
        [ObtenerTodos.fulfilled]: (state, action) => {
            state.asesores = action.payload;
        },
        [ObtenerById.fulfilled]: (state, action) => {
            state.model = action.payload;
        },
    }
})

export default asesoresSlice.reducer;