import React from "react";
import "../css/modal.css";

function Notificacion({titulo, cuerpo, display, cambiarEstado, cambiarEstadoPadre}) {
  return (
    <div className={display? "active notifContainer" : "notifContainer"}>
      <div id="detailTitle">
      <button onClick={() => {cambiarEstado(false); cambiarEstadoPadre(true)}} className="btnBack">
      <i className='material-icons'></i>
        </button>
        <h3>{titulo}</h3>
      </div>
      <div className="notifInfo">
       {cuerpo}
      </div>
    </div>
  );
}

export default Notificacion;