import React, { useEffect } from "react";
import { useController } from "react-hook-form";

function Summernotereact({ name, control, rules = {}, isEditing,height=200 }) {

  useEffect(() => {
    window.jQuery(document.getElementsByName(name)[0]).summernote({
      height: height,
      toolbar: [
        ["style", ["style"]],
        ["font", ["bold", "underline", "clear"]],
        ["color", ["color"]],
        ["para", ["ul", "ol", "paragraph"]],
        ["table", ["table"]],
        ["insert", ["link", "picture", "video"]],
      ],
      callbacks: {
        onBlur: onBlur,
        onChange: onChange
      },
    })
  }, []);

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: "",
  });


  useEffect(() => {

    if (value !== "") {
      window.jQuery(document.getElementsByName(name)[0]).summernote('code', value);
    }
  }, [isEditing])

  return (
    <textarea
      ref={ref}
      name={name}
      value={value}
      onChange={onChange}
    ></textarea>
  );
}

export default Summernotereact
