import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import ModalRemove from "./ModalRemove";
import Multiselect from "./Multiselect";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerUsuariosGeneral,
  registrarUsuarioAdm,
  actualizarUsuarioAdm,
  activacionUsuarioAdm,
} from "../../redux/usuariosSlice";
import { obtenerRoles } from "../../redux/genericosSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "usuarioId", sortable: true },
  { label: "Nombre", accessor: "nombreCompleto", sortable: true },
  { label: "Usuario", accessor: "usuarioNombre", sortable: false },
  {
    label: "Roles",
    accessor: "roles",
    sortable: true,
    render: (item) => (
      <td>
        <ul>
          {item.roles.map((r, i) => (
            <li key={i}>{r.rolNombre}</li>
          ))}
        </ul>
      </td>
    ),
  },
  { label: "Correo", accessor: "correoElectronico", sortable: true },
  {
    label: "Estado",
    accessor: "estadoId",
    sortable: true,
    render: (item) => (
      <td style={{ color: item.color }}>
        {item.estadoId === 4 ? "Activo" : "Inactivo"}
      </td>
    ),
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function Usuarios(props) {
  const [showModal, setShowModal] = useState(false);
  const [tituloModal, setTituloModal] = useState("Agregar");
  const [removeState, changeRemoveState] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const { width } = WindowDimension();

  const multiSelectRef = useRef(null);
  const { submitAction } = useSubmit();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  let busqueda = useRef();

  const { usuarios } = useSelector((state) => state.usuarios);
  const { roles } = useSelector((state) => state.genericos);

  const showModalEdit = (item) => {
    setItemSelected(item);
    reset(item);
    setTituloModal("Editar");
    setShowModal(true);
  };

  const closeModalEdit = () => {
    setItemSelected({});
    multiSelectRef.current.resetSelectedValues();

    reset({ usuarioId: 0, nombre: "", apellido: "", telefono: "", correo: "" });
    setShowModal(false);
  };

  const showRemoveModalEdit = (item) => {
    setItemSelected(item);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    setItemSelected({});
    changeRemoveState(false);
  };

  const cargarDatos = () => {
    dispatch(obtenerUsuariosGeneral());
  };

  const cargarRoles = () => {
    dispatch(obtenerRoles());
  };

  const cargarUsuariosPorCaracter = () => {
    dispatch(obtenerUsuariosGeneral(busqueda.current.value));
  };

  const activacion = async (estado) => {
    submitAction(
      activacionUsuarioAdm,
      { usuarioId: itemSelected.usuarioId, estadoId: estado },
      () => {
        cargarDatos();
        changeRemoveState(false);
      }
    );
  };

  const onSubmit = async (data) => {
    submitAction(
      data.usuarioId > 0 ? actualizarUsuarioAdm : registrarUsuarioAdm,
      data,
      () => {
        closeModalEdit();
        cargarDatos();
      }
    );
  };

  useEffect(() => {
    cargarDatos();
    cargarRoles();
  }, [dispatch]);

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <div className="header-admin-bar-items">
          <button
            className="main-admin-button"
            onClick={() => {
              setShowModal(true);
              setTituloModal("Agregar");
              setValue("nombre", "apellido", "telefono", "correo", "estado");
              reset();
            }}
          >
            +
          </button>
          <h2>Usuarios</h2>
        </div>

        <div className="header-admin-bar-items ml-auto">
          <input
            type="search"
            className="form-control"
            placeholder="Búsqueda"
            ref={busqueda}
            onChange={cargarUsuariosPorCaracter}
          />
        </div>
      </div>

      <div className="table-container">
        {usuarios?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={usuarios}
              clase="table-adm"
              renderActionItems={(item) => (
                <td key={Math.random()}>
                  <button
                    className="modBtn text-primary "
                    onClick={() => {
                      showModalEdit(item);
                    }}
                  >
                    <i className="material-icons"></i>
                  </button>
                  <button
                    className="modBtn"
                    onClick={() => {
                      showRemoveModalEdit(item);
                    }}
                  >
                    {item.estadoId === 4 ? (
                      <i className="material-icons statusRed"></i>
                    ) : (
                      <i className="material-icons"></i>
                    )}
                  </button>
                </td>
              )}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <label>Nombre completo</label>
            <Required />
            <input
              className="form-control"
              name="nombreCompleto"
              {...register("nombreCompleto", { required: "Campo requerido" })}
            />
            {errors.nombreCompleto && (
              <p className="error">{errors.nombreCompleto.message}</p>
            )}
          </div>

          <div className="form-group">
            <label>Nombre de usuario</label>
            <Required />
            <input
              className="form-control"
              name="usuarioNombre"
              {...register("usuarioNombre", { required: "Campo requerido" })}
            />
            {errors.usuarioNombre && (
              <p className="error">{errors.usuarioNombre.message}</p>
            )}
          </div>

          <div className="form-group">
            <label>Roles</label>
            <Required />
            <Multiselect
              innerRef={multiSelectRef}
              options={roles}
              selectedValues={itemSelected.roles}
              name="roles"
              setValueArray="roles"
              displayValue="rolNombre"
              control={control}
              setValue={setValue}
              placeholder="Seleccione los roles"
              rules={{ required: "Campo requerido" }}
            />

            {errors.roles && <p className="error">{errors.roles.message}</p>}
          </div>
          <div className="form-group">
            <label>Correo</label>
            <input
              className="form-control"
              name="correoElectronico"
              {...register("correoElectronico", {
                required: "Campo requerido",
              })}
            />
            {errors.correoElectronico && (
              <p className="error">{errors.correcoElectronico.message}</p>
            )}
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={closeRemoveModalEdit}
        title="Eliminar plantillla"
        message={
          itemSelected.estadoId === 4
            ? "¿Desea desactivar el siguiente usuario?"
            : "¿Desea activar el siguiente usuario?"
        }
        item={itemSelected.usuarioNombre}
        btnGroup={
          <>
            <button
              className="button-text text-red"
              onClick={() => activacion(itemSelected.estadoId === 4 ? 5 : 4)}
            >
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default Usuarios;
