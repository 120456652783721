import { useForm, Controller } from "react-hook-form";
import {
  TextoCorto,
  TextoLargo,
  Opciones,
  Booleano,
  Editor,
  MultiOpciones,
  Radio,
  Numerico,
  Correo,
  Clave,
} from "./InputElements";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actualizarConfiguraciones,
  obtenerConfiguraciones,
  configuracionesGeneralesDefault,
} from "../../redux/configuracionesSlice";
import Modal from "./ModalRemove";
import useSubmit from "../../Hooks/useSubmit";

const Field = (props) => {
  switch (props.tipo) {
    case "Texto Corto":
      return <TextoCorto {...props} />;

    case "Texto Largo":
      return <TextoLargo {...props} />;

    case "Booleano":
      return <Booleano {...props} />;

    case "Opciones":
      return <Opciones {...props} />;

    case "MultiOpciones":
      return <MultiOpciones {...props} />;

    case "Editor":
      return <Editor {...props} />;

    case "Radio":
      return <Radio {...props} />;

    case "Numero":
      return <Numerico {...props} />;

    case "Email":
      return <Correo {...props} />;

    case "Clave":
      return <Clave {...props} />;

    default:
      return null;
  }
};

function Configuraciones() {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confGeneral, setConfGeneral] = useState({});
  const [defValues, setDefVaules] = useState({});
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const { configuraciones } = useSelector((state) => state.configuraciones);
  const { submitAction } = useSubmit();

  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect(() => {
    Object.keys(configuraciones).map((e) => {
      Object.keys(configuraciones[e].configuraciones).map((c) => {
        const { valorPorDefecto, nombre, valor } =
          configuraciones[e].configuraciones[c];
        setValue(nombre, valor);
        setDefVaules((prevState) => ({
          ...prevState,
          [nombre]: valorPorDefecto,
        }));
      });
    });
  }, [configuraciones]);

  const cargarDatos = () => {
    dispatch(obtenerConfiguraciones());
  };

  const toggleModalVisibility = (value, e) => {
    e?.preventDefault();
    setModalVisibility(value);
  };

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      formData.append(key, object[key]);
    });
    return formData;
  }

  const onSubmit = async (data) => {
    let formD = getFormData(data);

    submitAction(actualizarConfiguraciones, formD, cargarDatos);
  };

  const reestablecerConfig = (submit) => {
    reset(defValues);
    toggleModalVisibility(false);
    submit();
  };

  const restablecerValor = async (e, submit) => {
    e.preventDefault();
    submitAction(configuracionesGeneralesDefault, confGeneral.clave, () => {
      cargarDatos();
      setShowModal(false);
      let formValues = getValues();
      formValues[confGeneral.clave] = confGeneral.defVal;
      reset(formValues);
    });

  };
  const parseElement = (el) => {
    let isValid = true;
    let x = {};
    try {
      x = JSON.parse(el);
    } catch (e) {
      isValid = false;
    }
    return isValid ? x : "";
  };

  const formInputs = Object.keys(configuraciones).map((e) => {
    return (
      <div key={e}>
        <details className="detail-settings">
          <summary>
            <div>
              <h3>{configuraciones[e].seccion}</h3>
            </div>
          </summary>
          {Object.keys(configuraciones[e].configuraciones).map((c) => {
            const { rules, valorPorDefecto, nombre, valor, label } =
              configuraciones[e].configuraciones[c];
            return (
              <div key={configuraciones[e].configuraciones[c].id}>
                <Controller
                  name={nombre}
                  control={control}
                  rules={parseElement(rules)}
                  defaultValue={valor}
                  render={({ field: { value, onChange, ref } }) => (
                    <div>
                      <Field
                        refe={ref}
                        handleDefault={(e) => {
                          e.preventDefault();
                          setConfGeneral({
                            nombre: label,
                            clave: nombre,
                            defVal: valorPorDefecto,
                          });
                          setShowModal(true);
                        }}
                        nombre={nombre}
                        errors={errors[nombre]?.message}
                        value={value}
                        setValue={setValue}
                        onChange={onChange}
                        control={control}
                        {...configuraciones[e].configuraciones[c]}
                      />
                    </div>
                  )}
                />
              </div>
            );
          })}
        </details>
      </div>
    );
  });

  return (
    <div className="main-admin left-align">
      <form className="form-settings">
        <div className="main-admin-bar">
          <div className="header-admin-bar-items">
            <h2>Configuraciones</h2>
          </div>
          <button
            className="search-button"
            onClick={(e) => toggleModalVisibility(true, e)}
          >
            Restablecer configuraciones
          </button>
        </div>
        {formInputs}
        <button className="button-text" onClick={handleSubmit(onSubmit)}>
          Guardar
        </button>

        <Modal
          estado={modalVisibility}
          closeModalHandled={(e) => {
            e.preventDefault();
            toggleModalVisibility(false, e);
          }}
          title="Restablecer configuraciones generales"
          message="¿Seguro que desea restablecer todas las configuraciones?"
          item={null}
          btnGroup={
            <>
              <button
                className="button-text text-red"
                onClick={(e) => {
                  e.preventDefault();
                  reestablecerConfig(handleSubmit(onSubmit));
                }}
              >
                Confirmar
              </button>
              <button
                className="button-text text-grey"
                onClick={(e) => toggleModalVisibility(false, e)}
              >
                Cancelar
              </button>
            </>
          }
        />
        <Modal
          estado={showModal}
          closeModalHandled={(e) => {
            e.preventDefault();
            setShowModal(false);
          }}
          title="Restablecer configuraciones generales"
          message={`¿Seguro que desea restablecer el valor del campo ${confGeneral.nombre} ?`}
          item={null}
          btnGroup={
            <>
              <button
                className="button-text text-red"
                onClick={(e) => restablecerValor(e, handleSubmit(onSubmit))}
              >
                Confirmar
              </button>
              <button
                className="button-text text-grey"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(false);
                }}
              >
                Cancelar
              </button>
            </>
          }
        />
      </form>
    </div>
  );
}

export default Configuraciones;
