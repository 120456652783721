import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import ModalRemove from "./ModalRemove";
import { useDispatch, useSelector } from "react-redux";
import {
  ObtenerTiposFinanciamientos,
  ActualizarTipoFinanciamiento,
  EliminarTipoFinanciamiento,
  InsertarTipoFinanciamiento,
} from "../../redux/tiposFinanciamientosSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import useSubmit from "../../Hooks/useSubmit";
import { OrderTable } from "../../Components/OrderTable";

const columns = [
  { label: "Código", accessor: "tipoFinanciamientoEstudioId", sortable: true },
  { label: "Nombre", accessor: "tipoFinanciamientoEstudioNombre", sortable: true },
  { label: "Acción", accessor: "action", sortable: false },
];

function TiposFinanciamientos(props) {
  const [showModal, setShowModal] = useState(false);
  const [tituloModal, setTituloModal] = useState("Agregar");
  const [removeState, changeRemoveState] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const { width } = WindowDimension();

  const { submitAction } = useSubmit();

  const dispatch = useDispatch();
  let busqueda = useRef();

  const { tiposFinanciamientos } = useSelector(
    (state) => state.tiposFinanciamientos
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const showModalEdit = (item) => {
    reset(item);
    setTituloModal("Editar");
    setShowModal(true);
  };

  const closeModalEdit = () => {
    reset({
      tipoFinanciamientoEstudioId: 0,
      tipoFinanciamientoId: 0,
      tipoFinanciamientoEstudioNombre: "",
    });
    setShowModal(false);
  };

  const showRemoveModalEdit = (item) => {
    setItemSelected(item);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    setItemSelected({});
    changeRemoveState(false);
  };

  const cargarFinanciamientos = () => {
    dispatch(ObtenerTiposFinanciamientos());
  };

  const cargarFinanciamientoPorCaracter = () => {
    dispatch(ObtenerTiposFinanciamientos(busqueda.current.value));
  };

  const eliminar = async () => {
    submitAction(
      EliminarTipoFinanciamiento,
      itemSelected.tipoFinanciamientoEstudioId,
      () => {
        closeRemoveModalEdit();
        cargarFinanciamientos();
      }
    );
  };

  const onSubmit = async (data) => {
    submitAction(
      data.tipoFinanciamientoEstudioId > 0
        ? ActualizarTipoFinanciamiento
        : InsertarTipoFinanciamiento,
      data,
      () => {
        closeModalEdit();
        cargarFinanciamientos();
      }
    );
  };

  useEffect(() => {
    cargarFinanciamientos();
  }, [dispatch]);

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <div className="header-admin-bar-items">
          <button
            className="main-admin-button"
            onClick={() => {
              setShowModal(true);
              setTituloModal("Agregar");
              setValue("tipoFinanciamientoEstudioNombre", "");
              reset();
            }}
          >
            +
          </button>
          <h2>Tipos de financiamientos</h2>
        </div>
        <div className="header-admin-bar-items ml-auto">
          <label hidden>Búsqueda</label>
          <input
            type="search"
            className="form-control"
            onInput={cargarFinanciamientoPorCaracter}
            placeholder="Búsqueda"
            ref={busqueda}
          />
        </div>
      </div>

      <div className="table-container">
        {tiposFinanciamientos.length > 0 ? (
          <OrderTable
          {...{ columns }}
          data={tiposFinanciamientos
            ?.filter((a) => a.tipoFinanciamientoEstudioId != 3)}
          clase="table-adm"
          actions={[
            {
              icon: "",
              clase: "text-primary",
              style: { display: "contents" },
              event: (item) => showModalEdit(item),
            },
            {
              icon: "",
              clase: "delete",
              style: { display: "contents" },
              event: (item) => showRemoveModalEdit(item),
            },
          ]}
        />
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <i className="material-icons important"></i>
            <label>Tipo de financiamiento</label>
            <input
              className="form-control"
              name="tipoFinanciamientoEstudioNombre"
              {...register("tipoFinanciamientoEstudioNombre", {
                required: "Campo requerido",
              })}
            />
            {errors.tipoFinanciamientoEstudioNombre && (
              <p className="error">
                {errors.tipoFinanciamientoEstudioNombre.message}
              </p>
            )}
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={closeRemoveModalEdit}
        title="Eliminar plantillla"
        message="¿Seguro que desea eliminar el siguiente registro?"
        item={itemSelected.tipoFinanciamientoEstudioNombre}
        btnGroup={
          <>
            <button className="button-text text-red" onClick={eliminar}>
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default TiposFinanciamientos;
