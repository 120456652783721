import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerTiposPlantillas,
  obtenerRolesPlantillas,
  actualizarTipoPlantilla,
} from "../../redux/plantillasTiposSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import Multiselect from "./Multiselect";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "tipoPlantillaId", sortable: true },
  { label: "Nombre", accessor: "tipoPlantillaNombre", sortable: true },
  {
    label: "Destinatarios",
    accessor: "roles",
    sortable: true,
    render: (item) => <td>{!item.roles ? "-" : item.roles}</td>,
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function TiposPlantillas(props) {
  const [modalState, changeModalState] = useState(false);
  const [registerSelected = {}, selectReg] = useState();
  const multiSelectRef = useRef(null);
  const { width } = WindowDimension();

  const dispatch = useDispatch();

  const { tiposPlantillas, roles } = useSelector(
    (state) => state.tiposPlantillas
  );

  const { submitAction } = useSubmit();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm();

  const showModalEdit = (reg) => {
    multiSelectRef.current.resetSelectedValues();
    reset(reg);
    selectReg(reg);
    changeModalState(true);
  };

  const closeModalEdit = () => {
    changeModalState(false);
  };

  const cargarPlantillas = () => {
    dispatch(obtenerTiposPlantillas());
  };
  const cargarRoles = () => {
    dispatch(obtenerRolesPlantillas());
  };

  const actualizar = async (data) => {
    submitAction(actualizarTipoPlantilla, data, () => {
      closeModalEdit();
      cargarPlantillas();
    });
  };

  useEffect(() => {
    cargarPlantillas();
    cargarRoles();
  }, [dispatch]);

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <h2>Tipos de plantillas</h2>
      </div>

      <Modal
        estado={modalState}
        cambiarEstado={closeModalEdit}
        titulo={"Actualizar tipo de plantilla"}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button className="button-text" onClick={handleSubmit(actualizar)}>
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div>
            <div className="form-group">
              <label>Nombre</label>
              <Required />
              <input
                className="form-control"
                id="titleEdit"
                type="text"
                {...register("tipoPlantillaNombre", {
                  required: "Campo requerido",
                })}
                name="tipoPlantillaNombre"
              ></input>
              {errors.tipoPlantillaNombre && (
                <p className="error">{errors.tipoPlantillaNombre.message}</p>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Destinatario</label>
            <Required />
            <Multiselect
              innerRef={multiSelectRef}
              options={roles}
              selectedValues={registerSelected.destinatarios}
              name="destinatarios"
              setValueArray="destinatarios"
              displayValue="rolNombre"
              control={control}
              setValue={setValue}
              placeholder="Seleccione los destinatarios"
              rules={{ required: "Campo requerido" }}
            />
            {errors.destinatarios && (
              <p className="error">{errors.destinatarios.message}</p>
            )}
          </div>
        </form>
      </Modal>

      <div className="table-container">
        {tiposPlantillas.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={tiposPlantillas}
              clase="table-adm"
              actions={[
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showModalEdit(item),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>
    </main>
  );
}

export default TiposPlantillas;
