import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import ModalRemove from "./ModalRemove";
import Multiselect from "./Multiselect";
import { useDispatch, useSelector } from "react-redux";
import {
  ObtenerTodos,
  Actualizar,
  Eliminar,
  Insertar,
  ImportarDocumentos,
} from "../../redux/perfilesDocumentosTiposSlice";
import { obtenerExtensionesArchivos } from "../../redux/genericosSlice";
import { toast } from "react-toastify";
import WindowDimension from "../../Hooks/WindowDimension";
import OperationStatus from "../../config/OperationStatus";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "documentoId", sortable: true },
  { label: "Documento", accessor: "documentoNombre", sortable: true },
  { label: "Descripción", accessor: "descripcion", sortable: true },
  {
    label: "Nombre de la descarga",
    accessor: "nombreDescarga",
    sortable: true,
  },
  {
    label: "Tipos de archivos",
    accessor: "tipoArchivos",
    sortable: true,
    render: (item) => (
      <td>
        <ul>
          {item.tipoArchivos.map((ar, i) => (
            <li key={i}>{ar.tipoArchivoNombre}</li>
          ))}
        </ul>
      </td>
    ),
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function PerfilesDocumentosTipos(props) {
  const [showModal, setShowModal] = useState(false);
  const [tituloModal, setTituloModal] = useState("Agregar");
  const [removeState, changeRemoveState] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const { width } = WindowDimension();

  const dispatch = useDispatch();
  let busqueda = useRef();
  const multiSelectRef = useRef(null);

  const { perfilesDocumentosTipos } = useSelector(
    (state) => state.perfilesDocumentosTipos
  );
  const { archivosTipos } = useSelector((state) => state.genericos);

  const { submitAction } = useSubmit();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm();

  const showModalEdit = (item) => {
    setItemSelected(item);
    reset(item);
    setTituloModal("Editar");
    setShowModal(true);
  };

  const closeModalEdit = () => {
    setItemSelected([]);
    multiSelectRef.current.resetSelectedValues();
    reset({ id: 0, documentoNombre: "" });
    setShowModal(false);
  };

  const showRemoveModalEdit = (item) => {
    setItemSelected(item);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    setItemSelected({});
    changeRemoveState(false);
  };

  const cargarTiposArchivos = () => {
    dispatch(obtenerExtensionesArchivos());
  };

  const cargarDatos = () => {
    dispatch(ObtenerTodos());
  };

  const cargarDatosPorCaracter = () => {
    dispatch(ObtenerTodos(busqueda.current.value));
  };

  const eliminar = async () => {
    submitAction(Eliminar, itemSelected?.documentoId, () => {
      closeRemoveModalEdit();
      cargarDatos();
    });
  };

  const onSubmit = async (data) => {
    submitAction(data.documentoId > 0 ? Actualizar : Insertar, data, () => {
      closeModalEdit();
      cargarDatos();
    });
  };

  const importar = async () => {
    let { payload } = await dispatch(ImportarDocumentos());
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      cargarDatos();
      toast.success(payload.message);
    } else {
      toast.error(payload.message);
    }
  };

  useEffect(() => {
    cargarDatos();
    cargarTiposArchivos();
  }, [dispatch]);

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <div className="header-admin-bar-items">
          <h2>Tipos de documentos</h2>
        </div>

        <div className="header-admin-bar-items ml-auto">
          <label hidden>Búsqueda</label>
          <input
            type="search"
            className="form-control"
            placeholder="Búsqueda"
            onInput={cargarDatosPorCaracter}
            ref={busqueda}
          />
          <button className="search-button" onClick={importar}>
            <i className="material-icons"></i>
          </button>
        </div>
      </div>

      <div className="table-container">
        {perfilesDocumentosTipos.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={perfilesDocumentosTipos}
              clase="table-adm"
              actions={[
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showModalEdit(item),
                },
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showRemoveModalEdit(item),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <label>Documento</label>
            <Required />
            <input
              className="form-control"
              name="documentoNombre"
              {...register("documentoNombre", { required: "Campo requerido" })}
            />
            {errors.documentoNombre && (
              <p className="error">{errors.documentoNombre.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>Nombre de descarga</label>
            <Required />
            <input
              className="form-control"
              name="nombreDescarga"
              {...register("nombreDescarga", { required: "Campo requerido" })}
            />
            {errors.nombreDescarga && (
              <p className="error">{errors.nombreDescarga.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>Descripción</label>
            <Required />
            <input
              className="form-control"
              name="descripcion"
              {...register("descripcion", { required: "Campo requerido" })}
            />
            {errors.descripcion && (
              <p className="error">{errors.descripcion.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>Tipos de documentos</label>
            <Required />
            <Multiselect
              innerRef={multiSelectRef}
              options={archivosTipos}
              selectedValues={itemSelected?.tipoArchivos}
              name="tipoArchivos"
              setValueArray="tipoArchivos"
              displayValue="tipoArchivoNombre"
              control={control}
              setValue={setValue}
              placeholder="Seleccione los tipos de archivos"
              rules={{ required: "Campo requerido" }}
            />

            {errors.tipoArchivos && (
              <p className="error">{errors.tipoArchivos.message}</p>
            )}
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={closeRemoveModalEdit}
        title="Eliminar plantillla"
        message="¿Seguro que desea eliminar el siguiente registro?"
        item={itemSelected?.documentoNombre}
        btnGroup={
          <>
            <button className="button-text text-red" onClick={eliminar}>
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default PerfilesDocumentosTipos;
