import {
  registrarPersona,
  autenticarUsuario,
  autenticarUsuarioAdm,
  autenticarGoogleAdm,
  autenticarFakeLogin,
} from "../redux/usuariosSlice";

const login = async (data, dispatch) => {
  let { payload } = await dispatch(autenticarUsuario(data));
  return payload;
};
const loginAdm = async (data, dispatch) => {
  let { payload } = await dispatch(autenticarUsuarioAdm(data));
  return payload;
};
const fakeLogin = async (data, dispatch) => {
  let { payload } = await dispatch(autenticarFakeLogin(data));
  return payload;
};
const loginGoogleAdm = async (data, dispatch) => {
  let { payload } = await dispatch(autenticarGoogleAdm(data));
  return payload;
};
const register = async (data, dispatch) => {
  let { payload } = await dispatch(registrarPersona(data));
  return payload;
};
const logout = (navigate) => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("filtro");

  navigate("/login");
};

export default {
  login,
  register,
  logout,
  loginAdm,
  loginGoogleAdm,
  fakeLogin,
};
