import ModalRemove from "./ModalRemove";
import { useState, useEffect, useRef } from "react";
import {
  obtenerCarrerasAdm,
  cambiarEstadoCarrera,
  ImportarCarreras,
  ActualizarCarrera,
} from "../../redux/carrerasSlice";
import {
  ObtenerTiposPeriodos,
  ObtenerTiposSesiones,
} from "../../redux/periodosSlice";
import {
  obtenerAreasCarreras,
  obtenerNivelCarreras,
} from "../../redux/genericosSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import OperationStatus from "../../config/OperationStatus";
import WindowDimension from "../../Hooks/WindowDimension";
import { useForm } from "react-hook-form";
import Modal from "./Modal";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "codigo", sortable: true },
  { label: "Carrera", accessor: "carreraNombre", sortable: true },
  { label: "Tipo de periodo", accessor: "tipoNombre", sortable: true },
  { label: "Sesión", accessor: "sesionNombre", sortable: true },
  { label: "Área", accessor: "area", sortable: true },
  { label: "Nivel", accessor: "nivel", sortable: true },
  { label: "Modalidad", accessor: "modalidad", sortable: true },
  {
    label: "Estado",
    accessor: "estatus",
    sortable: true,
    render: (item) => <td style={{ color: item.color }}>{item.estatus}</td>,
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function ListaCarreras(props) {
  const [removeState, changeRemoveState] = useState(false);
  const [nivelId, setNivel] = useState(0);
  const [areaId, setArea] = useState(0);
  const [registerSelected = [], selectReg] = useState();
  const [showModal, setShowModal] = useState(false);
  const { width } = WindowDimension();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  let busqueda = useRef();

  const { carrerasAdm } = useSelector((state) => state.carreras);
  const { tiposPeriodos, tiposSesiones } = useSelector(
    (state) => state.periodos
  );
  const { areasCarreras, nivelesCarreras } = useSelector(
    (state) => state.genericos
  );
  const { submitAction} = useSubmit();

  const cargarCarreras = () => {
    let object = { areaId: 0, nivelId: 0, nombre: null };
    dispatch(obtenerCarrerasAdm(object));
    dispatch(ObtenerTiposSesiones());
    dispatch(ObtenerTiposPeriodos());
  };

  const cargarCarrerasPorArea = (e) => {
    setArea(e.target.value);
    let object = { areaId: e.target.value, nivelId: nivelId, nombre: null };
    dispatch(obtenerCarrerasAdm(object));
  };

  const cargarCarrerasPorNivel = (e) => {
    setNivel(e.target.value);
    let object = { areaId: areaId, nivelId: e.target.value, nombre: null };
    dispatch(obtenerCarrerasAdm(object));
  };

  const cargarCarrerasBusqueda = (e) => {
    let object = {
      areaId: areaId,
      nivelId: nivelId,
      nombre: busqueda.current.value,
    };
    dispatch(obtenerCarrerasAdm(object));
  };

  const activacion = async (estado, estatus) => {
    submitAction(
      cambiarEstadoCarrera,
      { id: registerSelected.id, estadoId: estado },
      () => {
        cargarCarreras();
        changeRemoveState(false);
      }
    );
  };

  useEffect(() => {
    cargarCarreras();
    dispatch(obtenerAreasCarreras());
    dispatch(obtenerNivelCarreras());
  }, [dispatch]);

  const importar = async () => {
    let { payload } = await dispatch(ImportarCarreras());
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      cargarCarreras();
      toast.success(payload.message);
    } else {
      toast.error(payload.message);
    }
  };

  const showModalEdit = (item) => {
    reset(item);
    setShowModal(true);
  };
  const closeModalEdit = () => {
    setShowModal(false);
  };

  const onSubmit = async (data) => {
    let model = {
      id: data.id,
      tipoPeriodoId: parseInt(data.tipoPeriodoId),
      tipoSesion: parseInt(data.tipoSesion),
    };

    submitAction(ActualizarCarrera, model, () => {
      cargarCarreras();
      closeModalEdit();
    });
  };

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <h2>Carreras a ofertar</h2>

        <div className="header-admin-bar-items ml-auto">
          <label hidden>área</label>
          <select onChange={cargarCarrerasPorArea}>
            <option value="0">Todas las áreas</option>
            {areasCarreras.map((item, i) => {
              return (
                <option key={i} value={item.areaId}>
                  {item.areaNombre}
                </option>
              );
            })}
          </select>

          <label hidden>nivel</label>
          <select onChange={cargarCarrerasPorNivel}>
            <option value="0">Todos los niveles</option>
            {nivelesCarreras.map((item, i) => {
              return (
                <option key={i} value={item.nivelId}>
                  {item.nivelAcademicoNombre}
                </option>
              );
            })}
          </select>

          <label hidden>Búsqueda</label>
          <input
            type="search"
            placeholder="Búsqueda"
            onChange={cargarCarrerasBusqueda}
            ref={busqueda}
            className="form-control"
          />
          <button className="search-button" onClick={importar}>
            <i className="material-icons"></i>
          </button>
        </div>
      </div>

      <ModalRemove
        estado={removeState}
        closeModalHandled={changeRemoveState}
        title={
          registerSelected.estadoId === 4
            ? "Desactivar oferta de carrera"
            : "Activar oferta de carrera"
        }
        message={
          registerSelected.estadoId === 4
            ? "¿Desea desactivar la siguiente oferta?"
            : "¿Desea activar la siguiente oferta?"
        }
        item={registerSelected.carreraNombre}
        icon={
          registerSelected.estadoId === 5 ? (
            <i className="material-icons info"></i>
          ) : null
        }
        color={registerSelected.estadoId === 5 ? "activate-item" : null}
        btnGroup={
          <>
            <button
              className="button-text text-red"
              onClick={() =>
                activacion(registerSelected.estadoId === 4 ? 5 : 4)
              }
            >
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                changeRemoveState(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />

      <div className="table-container">
        {carrerasAdm.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={carrerasAdm}
              clase="table-adm"
              renderActionItems={(item) => {
                return (
                  <td key={Math.random()}>
                    <button
                      className="modBtn text-primary "
                      onClick={() => {
                        showModalEdit(item);
                      }}
                    >
                      <i className="material-icons"></i>
                    </button>

                    {item.estadoId === 4 ? (
                      <button   className="modBtn text-primary ">
                        <i
                          className="material-icons statusRed"
                          onClick={() => {
                            selectReg(item);
                            changeRemoveState(true);
                          }}
                        >
                          
                        </i>
                      </button>
                    ) : (
                      <button   className="modBtn text-primary ">
                        <i
                          className="material-icons statusGreen"
                          onClick={() => {
                            selectReg(item);
                            changeRemoveState(true);
                          }}
                        >
                          
                        </i>
                      </button>
                    )}
                  </td>
                );
              }}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={"Editar"}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <label>Tipo de periodo</label>
            <Required />
            <select
              className="form-control"
              {...register("tipoPeriodoId", { required: "Campo requerido" })}
            >
              <option value={undefined}>Seleccione</option>
              {tiposPeriodos?.map((a, i) => {
                return (
                  <option key={i} value={a.periodoTipoId}>
                    {a.periodoTipoNombre}
                  </option>
                );
              })}
            </select>
            {errors.tipoPeriodoId && (
              <p className="error">{errors.tipoPeriodoId.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>Tipo de sesión</label>
            <Required />
            <select
              className="form-control"
              {...register("tipoSesion", { required: "Campo requerido" })}
              name="tipoSesion"
            >
              <option value={undefined}>Seleccione</option>
              {tiposSesiones?.map((a, i) => {
                return (
                  <option key={i} value={a.sesionId}>
                    {a.sesionNombre}
                  </option>
                );
              })}
            </select>
            {errors.tipoSesion && (
              <p className="error">{errors.tipoSesion.message}</p>
            )}
          </div>
        </form>
      </Modal>
    </main>
  );
}

export default ListaCarreras;
