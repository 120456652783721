import WebApi from '../Api/WebApi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const obtenerCarrerasAdm = createAsyncThunk(
  'carreras/obtenerCarrerasAdm',
  async (item, { rejectWithValue }) => {
    try {
      if (item.nombre == null) {
        const res = await WebApi.api().get(
          `admin/carreras?areaId=${item.areaId}&nivelId=${item.nivelId}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(
          `admin/carreras?areaId=${item.areaId}&nivelId=${item.nivelId}&nombre=${item.nombre}`
        );
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const cambiarEstadoCarrera = createAsyncThunk(
  'carreras/cambiarEstado',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/carreras/CambiarEstado/' + item.id + '?EstadoId=' + item.estadoId
      );
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const ImportarCarreras = createAsyncThunk(
  'carreras/Importar',
  async (i, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/Carreras/ucenfotec');
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ActualizarCarrera = createAsyncThunk(
  'carreras/ActualizarCarrera',
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put('admin/Carreras/' + data.id, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const obtenerNivelesAcademicosAdm = createAsyncThunk(
  'carreras/obtenerNivelesAcademicosAdm',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`admin/CarrerasNivelAcademico`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const cambiarOrdenNivelAcademico = createAsyncThunk(
  'carreras/cambiarOrdenNivelAcademico',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put('admin/CarrerasNivelAcademico', item);
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const cambiarEstadoNivelAcademico = createAsyncThunk(
  'carreras/cambiarEstadoNivelAcademico',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/Niveles/CambiarEstado/' + item.id + '?EstadoId=' + item.estadoId
      );
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

const carrerasSlice = createSlice({
  name: 'carrerasAdm',
  initialState: {
    carrerasAdm: [],
    nivelesAcademicos: [],
    status: '',
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [obtenerCarrerasAdm.pending]: (state, action) => {
      state.loading = true;
    },
    [obtenerCarrerasAdm.fulfilled]: (state, action) => {
      state.loading = false;
      state.carrerasAdm = action.payload;
    },
    [obtenerNivelesAcademicosAdm.fulfilled]: (state, action) => {
      state.nivelesAcademicos = action.payload;
    },
    [obtenerCarrerasAdm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default carrerasSlice.reducer;
