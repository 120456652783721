import React from "react";

export default function FileContainer(props) {
  return (
    <div className=" file-container">
      <>
        <a
          role="button"
          onClick={() => props.decargarArchivo()}
          disabled={props.loading}
        >
          <i className="material-icons"></i>
        </a>
        <a
          role="button"
          onClick={() => props.verArchivo()}
          disabled={props.loading}
        >
          <i className="material-icons"></i>
        </a>
      </>
    </div>
  );
}
