import React from "react";
import Select from "react-select";

function ReactSelectGroup({ height = 34, lista = [], handleChange, value }) {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#fcfafa",
      // Overwrittes the different states of border
      borderColor: "#c1c1c1",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      borderRadius: 10,
      fontFamily: "circularBook",
      fontSize: "14px",
      height: height,
      minHeight: height,
      minWidth: 200,
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "circularBook",
      zIndex: 9999,
    }),
  };

  return (
    <Select
      styles={customStyles}
      classNamePrefix="addl-class"
      placeholder="Seleccione"
      options={lista}
      defaultValue={value}
      onChange={(val) => handleChange(val.value)}
    />
  );
}

export default ReactSelectGroup;
