import React, { useEffect, useRef, useState } from "react";
import {
  obtenerSolicitudesPorEstados,
  obtenerCarrerasMasEstudiantes,
  obtenerEstadisticasRazones,
  obtenerSolicitudesPorMes,
  obtenerCarrerasMayorInteres,
  obtenerSolicitudesPorNiveles,
  obtenerNivelCarreras,
  obtenerSolicitudesUsuario,
  obtenerSolicitudesCarrerasPorNivel,
} from "../../redux/genericosSlice";
import { useDispatch, useSelector } from "react-redux";
import Charts from "./Charts";
import infoIMG from "../../SVG/InformacionpersonalCenfotec.svg";
import GoogleChartContainer from "./GoogleChartContainer";

let colors = [
  "#B3E1F9",
  "#5795e0",
  "#00479B",
  "#16B2BF",
  "#68A1FF",
  "#A0C0CE",
  "#4FC3F7",
  "#16B2BF",
  "006064",
];

const estados = {
  18: "Preaprobadas",
  9: "Enviadas",
  10: "Aprobadas",
  13: "Rechazadas",
  0: "Total",
};

function Dashboard({ imgNoResult, msgNoResult }) {
  const dispatch = useDispatch();
  const rangoFechas = useRef();
  const [fechaInicio, setFechaInicio] = useState();
  const [fechaFin, setFechaFin] = useState();
  const [datosSolicitudesAtendidas, setDatosSolicitudesAtendidas] = useState(2);
  const [datosNivelCarrera, setDatosNivelCarrera] = useState(1);

  const {
    solicitudesPorEstados,
    solicitudesPorCarrera,
    solicitudesPorMeses,
    solicitudesMayoresRazones,
    solicitudesPorNiveles,
    nivelesCarreras,
    solicitudesCarrerasPorNivel,
    solicitudesUsuarioActual,
  } = useSelector((state) => state.genericos);

  useEffect(() => {
    dispatch(obtenerCarrerasMasEstudiantes({ fechaInicio, fechaFin }));
    dispatch(obtenerSolicitudesPorEstados({ fechaInicio, fechaFin }));
    dispatch(obtenerSolicitudesPorNiveles({ fechaInicio, fechaFin }));
    dispatch(obtenerSolicitudesPorMes({ fechaInicio, fechaFin }));
    dispatch(obtenerCarrerasMayorInteres({ fechaInicio, fechaFin }));
    dispatch(obtenerEstadisticasRazones({ fechaInicio, fechaFin }));
    dispatch(obtenerNivelCarreras({ fechaInicio, fechaFin }));
  }, [dispatch, fechaInicio, fechaFin]);

  const filtrarCarrerasPorNiveles = (e) => {
    const nivel = e.target.value;
    if (nivel != 1) {
      dispatch(
        obtenerSolicitudesCarrerasPorNivel({ nivel, fechaInicio, fechaFin })
      );
      setDatosNivelCarrera(2);
    } else setDatosNivelCarrera(1);
  };

  const getFirstDate = (dias) => {
    const hoy = new Date();
    let calculado = new Date(),
      dateResul = hoy.getDate() - dias;
    calculado.setDate(dateResul);
    return getDateR(calculado);
  };

  const getDateR = (fecha) => {
    const formato = "yyyy-mm-dd";
    const map = {
      dd: fecha.getDate(),
      mm: fecha.getMonth() + 1,
      yyyy: fecha.getFullYear(),
    };
    return formato.replace(/dd|mm|yyyy/gi, (matched) => map[matched]);
  };

  const mostrarFiltroPorFechas = (e) => {
    if (e.target.value == 1) {
      setFechaInicio("");
      setFechaFin("");
    } else if (e.target.value == 5) {
      rangoFechas.current.classList.add("visible");
    } else {
      setFechaFin(getDateR(new Date()));
      setFechaInicio(getFirstDate(e.target.value));
      rangoFechas.current.classList.remove("visible");
    }
  };

  const filtrarEstadisticasPorEstados = (e) => {
    if (e.target.value == 1) {
      dispatch(obtenerSolicitudesPorEstados());
      setDatosSolicitudesAtendidas(2);
    } else {
      dispatch(obtenerSolicitudesUsuario());
      setDatosSolicitudesAtendidas(1);
    }
  };

  return (
    <GoogleChartContainer>
      {(google) => (
        <main className="main-admin">
          <div id="dashboard">
            <div className="form-group" id="date-range-container">
              <label>Rango de fechas:</label>
              <select
                className="dashboard-control mr-1"
                onChange={(e) => mostrarFiltroPorFechas(e)}
              >
                <option value={1}>desde siempre</option>
                <option value={7}>últimos 7 días</option>
                <option value={30}>últimos 30 dias</option>
                <option value={365}>último año</option>
                <option value={5}>especificar rango</option>
              </select>

              <div ref={rangoFechas}>
                <span>Entre</span>
                <input
                  onChange={(e) => setFechaInicio(e.target.value)}
                  type="date"
                  defaultValue={fechaInicio ? fechaInicio : getFirstDate(7)}
                  className="form-control"
                ></input>
                <span>y</span>
                <input
                  onChange={(e) => setFechaFin(e.target.value)}
                  type="date"
                  defaultValue={fechaFin ? fechaFin : getDateR(new Date())}
                  className="form-control"
                ></input>
              </div>
            </div>

            <div className="grid-container col-2 col-lg-2 col-sm-12">
              <div className="grid-item">
                <section className="section">
                  <h3>Administración CENFOTEC</h3>
                  <div className="d-flex">
                    <img
                      className="dboard-img"
                      src={infoIMG}
                      alt="Ilustración de gestión"
                    ></img>

                    <p className="welcome-dashboard-text">
                      Gestione de manera segura las solicitudes y actividades
                      correspondientes
                    </p>
                  </div>
                </section>
              </div>
              <div className="grid-item">
                <section>
                  <div>
                    <div className="d-flex">
                      <h3 className="mr-2">SOLICITUDES</h3>
                      <select
                        className="dashboard-control"
                        onChange={(e) => filtrarEstadisticasPorEstados(e)}
                      >
                        <option value={1}>Solicitudes a nivel general</option>
                        <option value={2}>Solicitudes que has atendido</option>
                      </select>
                    </div>
                    <div>
                      <ul
                        className="d-flex bottom-align p-0"
                        id="count-solicitudes-container"
                      >
                        {datosSolicitudesAtendidas == 2 ? (
                          solicitudesPorEstados.length > 0 ? (
                            solicitudesPorEstados.map((estado, i) => {
                              return (
                                <li
                                  className="short-text mb-9"
                                  style={{
                                    borderColor:
                                      estado.estadoId == 8
                                        ? "#c9ba74"
                                        : estado.estadoId == 10
                                        ? "#7cd77c"
                                        : estado.estadoId == 13
                                        ? "#b57676"
                                        : "var(--color-accent)",
                                  }}
                                  key={i}
                                >
                                  <i className="material-icons-outlined"></i>
                                  <div>
                                    <div>
                                      <span>{estados[estado.estadoId]}</span>
                                    </div>
                                    <div className="number-card">
                                      <span className="big-number">
                                        {estado.solicitudes}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <div className="no-results-container">
                              <img
                                src={imgNoResult}
                                alt="Imagen sin resultados"
                              />
                              <h3>{msgNoResult}</h3>
                            </div>
                          )
                        ) : solicitudesUsuarioActual.length > 0 ? (
                          solicitudesUsuarioActual.map((estado, i) => {
                            return (
                              <li
                                className="short-text mb-9"
                                style={{
                                  borderColor:
                                    estado.estadoId == 8
                                      ? "#c9ba74"
                                      : estado.estadoId == 10
                                      ? "#7cd77c"
                                      : estado.estadoId == 13
                                      ? "#b57676"
                                      : "var(--color-accent)",
                                }}
                                key={i}
                              >
                                <i className="material-icons-outlined"></i>
                                <div>
                                  <div>
                                    <span>{estados[estado.estadoId]}</span>
                                  </div>
                                  <div className="number-card">
                                    <span className="big-number">
                                      {estado.solicitudes}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <div className="no-results-container">
                            <img
                              src={imgNoResult}
                              alt="Imagen sin resultados"
                            />
                            <h3>{msgNoResult}</h3>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </section>
              </div>

              <div className="grid-item grid-item-large">
                <section className="section-card graphic-card">
                  <select
                    className="dashboard-control"
                    onChange={(e) => filtrarCarrerasPorNiveles(e)}
                    defaultValue={datosNivelCarrera}
                    placeholder="Filtrar carreras por nivel owow"
                  >
                    <option value={1} hidden>
                      Filtrar carreras por nivel
                    </option>
                    <option value={1}>Todos los niveles</option>
                    {nivelesCarreras?.map((nivel) => (
                      <option key={nivel.nivelId} value={nivel.nivelId}>
                        {nivel.nivelAcademicoNombre}
                      </option>
                    ))}
                  </select>

                  {datosNivelCarrera == 1 ? (
                    solicitudesPorNiveles?.length > 0 ? (
                      <Charts
                        google={google}
                        title="Solicitudes por niveles acádemicos"
                        array={[
                          [
                            "Nivel",
                            "Cantidad de solicitudes",
                            { role: "style" },
                          ],
                          ...solicitudesPorNiveles.map((r, i) => [
                            r.nivelCarreraNombre,
                            r.solicitudes,
                            colors[i],
                          ]),
                        ]}
                        chartType="columnBar"
                      />
                    ) : (
                      <div className="no-results-container">
                        <img src={imgNoResult} alt="Imagen sin resultados" />
                        <h3>{msgNoResult}</h3>
                      </div>
                    )
                  ) : solicitudesCarrerasPorNivel?.length > 0 ? (
                    <Charts
                      google={google}
                      title="Solicitudes por niveles acádemicos"
                      array={[
                        ["Nivel", "Cantidad de solicitudes", { role: "style" }],
                        ...solicitudesCarrerasPorNivel.map((r, i) => [
                          r.carreraNombre,
                          r.solicitudes,
                          colors[i],
                        ]),
                      ]}
                      chartType="columnBar"
                    />
                  ) : (
                    <div className="no-results-container">
                      <img src={imgNoResult} alt="Imagen sin resultados" />
                      <h3>{msgNoResult}</h3>
                    </div>
                  )}
                </section>
              </div>

              {solicitudesPorCarrera?.length > 0 ? (
                <div className="grid-item">
                  <section className="section-card graphic-card">
                    <Charts
                      google={google}
                      title="Carreras de mayor interés"
                      array={[
                        [
                          "Carrera",
                          "Cantidad de solicitudes",
                          { role: "style" },
                        ],
                        ...solicitudesPorCarrera.map((r, i) => [
                          r.carreraNombre,
                          r.cantidadDeEstudiantes,
                          colors[i],
                        ]),
                      ]}
                      chartType="bar"
                    />
                  </section>
                </div>
              ) : null}

              {solicitudesMayoresRazones?.length > 0 ? (
                <div className="grid-item">
                  <section className="section-card graphic-card">
                    <Charts
                      google={google}
                      title="Razones principales por las que se elige CENFOTEC..."
                      array={[
                        ["Razon", "Veces que se presenta", { role: "style" }],
                        ...solicitudesMayoresRazones.map((r, i) => [
                          r.razonNombre,
                          r.cantidadDeEstudiantes,
                          colors[i],
                        ]),
                      ]}
                      chartType="pie"
                    />
                  </section>
                </div>
              ) : null}

              {solicitudesPorMeses?.length > 0 ? (
                <div className="grid-item">
                  <section className="section-card graphic-card">
                    <Charts
                      google={google}
                      title="Solicitudes por meses del año..."
                      array={[
                        ["Mes", "Solicitudes", { role: "style" }],
                        ...solicitudesPorMeses.map((r, i) => [
                          r.mes,
                          r.cantidadDeSolicitudes,
                          colors[i],
                        ]),
                      ]}
                      chartType="columBar"
                    />
                  </section>
                </div>
              ) : null}
            </div>
          </div>
        </main>
      )}
    </GoogleChartContainer>
  );
}

export default Dashboard;
