import WebApi from '../Api/WebApi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const obtenerUsuariosGeneral = createAsyncThunk(
  'usuarios/obtenerUsuarios',
  async (i, { rejectWithValue }) => {
    try {
      if (typeof i === 'undefined') {
        const res = await WebApi.api().get(`admin/usuariosAdm`);
        return res.data.result;
      } else {
        const res = await WebApi.api().get(`admin/usuariosAdm?nombre=${i}`);
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const registrarUsuarioAdm = createAsyncThunk(
  'usuarios/registrarUsuario',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/usuariosAdm', item);
      return res.data;
    } catch (err) {
      return {
        success: false,
        message: 'Error al registrar usuario',
        show: true,
      };
    }
  }
);

export const actualizarUsuarioAdm = createAsyncThunk(
  'usuarios/actualizarUsuario',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/usuariosAdm/' + item.usuarioId,
        item
      );
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const activacionUsuarioAdm = createAsyncThunk(
  'usuario/actualizarUsuario',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/usuariosAdm/CambiarEstado/' +
          item.usuarioId +
          '?estadoId=' +
          item.estadoId
      );
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const obtenerUsuario = createAsyncThunk(
  'usuarios/obtenerUsuario',
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('usuarios/' + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const actualizarCorreo = createAsyncThunk(
  'usuarios/ActualizarCorreo',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put('Usuarios/ActualizarCorreo', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const actualizarTelefono = createAsyncThunk(
  'usuarios/ActualizarTelefono',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put('Usuarios/ActualizarTelefono', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerUsuarioActual = createAsyncThunk(
  'usuarios/obtenerUsuarioActual',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('Usuarios/ObtenerUsuarioActual');
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerUsuarioActualAdm = createAsyncThunk(
  'usuarios/obtenerUsuarioActualAdm',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        'admin/UsuariosAdm/ObtenerUsuarioActual'
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerNotificacionesUsuario = createAsyncThunk(
  'usuarios/obtenerNotificacionesUsuario',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        'Usuarios/ObtenerNotificacionesUsuario'
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const actualizarUsuario = createAsyncThunk(
  'usuarios/actualizarUsuario',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put('Usuarios/EditarPersona', item);
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);
export const actualizarContrasenia = createAsyncThunk(
  'usuarios/actualizarContrasenia',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'Usuarios/CambiarUsuarioContrasena',
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const asignarCodigoCorreo = createAsyncThunk(
  'usuarios/asignarCodigoCorreo',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('Usuarios/AsignarCodigoCorreo');
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const asignarCodigoTelefono = createAsyncThunk(
  'usuarios/asignarCodigoTelefono',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('Usuarios/AsignarCodigoTelefono');
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const verificarCorreo = createAsyncThunk(
  'usuarios/verificarCorreo',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('Usuarios/VerificacionCorreo');
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const verificarTelefono = createAsyncThunk(
  'usuarios/verificarTelefono',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('Usuarios/VerificacionTelefono');
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const validarCorreo = createAsyncThunk(
  'usuarios/validarCorreo',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        'Usuarios/ValidarCorreo?codigo=' + item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const validarTelefono = createAsyncThunk(
  'usuarios/validarTelefono',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        'Usuarios/ValidarTelefono?codigo=' + item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const autenticarUsuario = createAsyncThunk(
  'usuarios/autenticarUsuario',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('Usuarios/Autenticar', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const autenticarUsuarioAdm = createAsyncThunk(
  'usuarios/autenticarUsuarioAdm',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/UsuariosAdm/Autenticar', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const autenticarFakeLogin = createAsyncThunk(
  'usuarios/autenticarFakeLogin',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        'admin/UsuariosAdm/AutenticarFake',
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const autenticarGoogleAdm = createAsyncThunk(
  'usuarios/autenticarUsuarioAdm',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        'admin/UsuariosAdm/Google/Autenticar?credential=' + item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  'usuarios/refreshToken',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('Usuarios/RefreshToken', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const refreshTokenAdm = createAsyncThunk(
  'usuarios/refreshToken',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        'admin/UsuariosAdm/SetRefreshToken?newRefreshToken=' + item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const ObtenerPaisesPrefijos = createAsyncThunk(
  'Usuarios/ObtenerPaisesPrefijos',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('/Usuarios/ObtenerPaisesPrefijos');
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const ObtenerDatosRegistro = createAsyncThunk(
  'Usuarios/ObtenerDatosRegistro',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('/Usuarios/ObtenerDatosRegistro');
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const VerificarPersonaPadron = createAsyncThunk(
  'Usuarios/VerificarPersonaPadron',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        '/Usuarios/PersonaPadron?dni=' + item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const registrarPersona = createAsyncThunk(
  'Usuarios/RegistrarPersona',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('/Usuarios/RegistrarPersona', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const reiniciarContrasena = createAsyncThunk(
  'Usuarios/reiniciarContrasena',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        '/Usuarios/ReiniciarContrasena',
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const reiniciarContrasenaSolicitud = createAsyncThunk(
  'Usuarios/reiniciarContrasenaSolicitud',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        '/Usuarios/SolicitudReiniciarContrasena?correo=' + item.correo
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const usuariosSlice = createSlice({
  name: 'usuarios',
  initialState: {
    usuarios: [],
    notificaciones: [],
    prefijosLista: [],
    registroModel: {},
    usuario: {},
    result: { success: false, message: '', show: false },
  },
  extraReducers: {
    [obtenerUsuario.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [obtenerUsuariosGeneral.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [actualizarUsuarioAdm.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [activacionUsuarioAdm.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [obtenerUsuariosGeneral.fulfilled]: (state, action) => {
      state.usuarios = action.payload;
    },
    [actualizarUsuario.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerUsuarioActual.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [obtenerUsuarioActualAdm.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },
    [obtenerNotificacionesUsuario.fulfilled]: (state, action) => {
      state.notificaciones = action.payload;
    },
    [ObtenerPaisesPrefijos.fulfilled]: (state, action) => {
      state.prefijosLista = action.payload;
    },
    [ObtenerDatosRegistro.fulfilled]: (state, action) => {
      state.registroModel = action.payload;
    },
  },
});

export default usuariosSlice.reducer;
