import React, { useEffect, useState } from 'react'
import Summernotereact from "./SummernoteReact";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { obtenerTerminos, actualizarTerminos } from "../../redux/solicitudesAdmSlice";
import OperationStatus from '../../config/OperationStatus'
import { toast } from "react-toastify";


function TerminosYCondiciones() {
    const dispatch = useDispatch();
    const { terminos } = useSelector((state) => state.solicitudesAdm);
    const [isEditing, setIsEditing] = useState(false)
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        control,
        reset,
    } = useForm({ defaultValues: { valor: "" } });


    useEffect(() => {
        dispatch(obtenerTerminos());
    }, [])
    useEffect(() => {

        if (Object.keys(terminos).length !== 0) {
            reset(terminos)
            setIsEditing(true);
        }
    }, [terminos])

    const actualizar = async (data) => {

        let obj={id:data.configuracionId,valor:data.valor}

        let { payload } = await dispatch(actualizarTerminos(obj));
        if (payload.operationStatus === OperationStatus.SUCCESS) {
            toast.success(payload.message);
        } else {
            toast.error(payload.message);
        }
    };
    return (
        <>
            <div className="main-admin">
                <div className="main-admin-bar">
                    <div className="header-admin-bar-items">

                        <h2>Términos y condiciones </h2>
                    </div>
                </div>
                <div className="main-body">
                    <form onSubmit={handleSubmit(actualizar)}>

                        <Summernotereact
                            control={control}
                            height={500}
                            name="valor"
                            rules={{ required: "Campo requerido" }}
                            isEditing={isEditing}
                        />
                        {errors.valor && (
                            <p className="error">{errors.valor.message}</p>
                        )}

                        <div className="main-body-action">
                            <button type="submit" className="button-text" >
                                Guardar
                                </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TerminosYCondiciones