import WebApi from "../Api/WebApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//Metodos asincronos

export const obtenerCarrerasMasEstudiantes = createAsyncThunk(
  "genericos/EstadisticasTopCarreras",
  async (l, { rejectWithValue }) => {
    try {
      if (
        typeof l.fechaFin !== "undefined" &&
        typeof l.fechaInicio !== "undefined"
      ) {
        const res = await WebApi.api().get(
          `admin/EstadisticasTopCarreras?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(`admin/EstadisticasTopCarreras`);
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerCarrerasMayorInteres = createAsyncThunk(
  "genericos/EstadisticasCarrerasInteres",
  async (l, { rejectWithValue }) => {
    try {
      if (
        typeof l.fechaFin !== "undefined" &&
        typeof l.fechaInicio !== "undefined"
      ) {
        const res = await WebApi.api().get(
          `admin/EstadisticasCarrerasInteres?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(`admin/EstadisticasCarrerasInteres`);
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerSolicitudesPorMes = createAsyncThunk(
  "genericos/EstadisticasCantidadSolicitudesMeses",
  async (l, { rejectWithValue }) => {
    try {
      if (
        typeof l.fechaFin !== "undefined" &&
        typeof l.fechaInicio !== "undefined"
      ) {
        const res = await WebApi.api().get(
          `admin/EstadisticasCantidadSolicitudesMeses?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(
          `admin/EstadisticasCantidadSolicitudesMeses`
        );
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerSolicitudesPorEstados = createAsyncThunk(
  "genericos/obtenerSolicitudesPorEstados",
  async (l, { rejectWithValue }) => {
    try {
      if (
        typeof l.fechaFin !== "undefined" &&
        typeof l.fechaInicio !== "undefined"
      ) {
        const res = await WebApi.api().get(
          `admin/EstadisticasCantidadSolicitudesEstados?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(
          `admin/EstadisticasCantidadSolicitudesEstados`
        );
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerSolicitudesUsuario = createAsyncThunk(
  "genericos/obtenerSolicitudesPorUsuario",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/SolicitudesAdmAtendidoPor");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerSolicitudesPorNiveles = createAsyncThunk(
  "genericos/EstadisticasCantidadSolicitudesNivelCarrera",
  async (l, { rejectWithValue }) => {
    try {
      if (
        typeof l.fechaFin !== "undefined" &&
        typeof l.fechaInicio !== "undefined"
      ) {
        const res = await WebApi.api().get(
          `admin/EstadisticasCantidadSolicitudesNivelCarrera?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(
          `admin/EstadisticasCantidadSolicitudesNivelCarrera`
        );
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerSolicitudesCarrerasPorNivel = createAsyncThunk(
  "genericos/solicitudesCarrerasPorNivel",
  async (l, { rejectWithValue }) => {
    try {
      if (
        typeof l.fechaFin !== "undefined" &&
        typeof l.fechaInicio !== "undefined" &&
        typeof l.nivel !== "undefined"
      ) {
        const res = await WebApi.api().get(
          `admin/SolicitudesAdmPorCarrera?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}&nivelId=${l.nivel}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(
          `admin/SolicitudesAdmPorCarrera?nivelId=${l.nivel}`
        );
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerEstadisticasRazones = createAsyncThunk(
  "genericos/EstadisticasRazones",
  async (l, { rejectWithValue }) => {
    try {
      if (
        typeof l.fechaFin !== "undefined" &&
        typeof l.fechaInicio !== "undefined"
      ) {
        const res = await WebApi.api().get(
          `admin/EstadisticasRazones?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(`admin/EstadisticasRazones`);
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerExtensionesArchivos = createAsyncThunk(
  "genericos/obtenerExtensionesArchivos",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/archivosTipos");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerPaises = createAsyncThunk(
  "genericos/obtenerPaises",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/paises");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerEstadosCiviles = createAsyncThunk(
  "genericos/obtenerEstadosCiviles",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("estadosCiviles");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerEstados = createAsyncThunk(
  "genericos/obtenerEstados",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("estados");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerProvincias = createAsyncThunk(
  "genericos/obtenerProvincias",
  async (paisId, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("provincias/?paisId=" + paisId);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerCantones = createAsyncThunk(
  "genericos/obtenerCantones",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("cantones/?provinciaId=" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerDistritos = createAsyncThunk(
  "genericos/obtenerDistritos",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("distritos/?cantonId=" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerTiposPlantillas = createAsyncThunk(
  "genericos/obtenerTiposPlantillas",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/PlantillasTipo");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerDocumentos = createAsyncThunk(
  "genericos/obtenerDocumentos",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/documentos");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerDocumentosPorPerfil = createAsyncThunk(
  "genericos/obtenerDocumentosPorPerfil",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/documentos/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerTiposFinanciamientos = createAsyncThunk(
  "genericos/obtenerTiposFinanciamientos",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("tipoFinanciamientos");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerTiposOrganizaciones = createAsyncThunk(
  "genericos/obtenerTiposOrganizaciones",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("tipoOrganizaciones");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerNiveles = createAsyncThunk(
  "genericos/obtenerNiveles",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("estudiosNiveles");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerNivelesIdiomas = createAsyncThunk(
  "genericos/obtenerNivelesIdiomas",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("nivelesIdiomas");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerCarreras = createAsyncThunk(
  "genericos/obtenerCarreras",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/carreras");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerAreasCarreras = createAsyncThunk(
  "genericos/obtenerAreasCarreras",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/areas");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerNivelCarreras = createAsyncThunk(
  "genericos/obtenerNivelCarreras",
  async (l, { rejectWithValue }) => {
    try {
      // if (
      //   typeof l.fechaFin !== "undefined" &&
      //   typeof l.fechaInicio !== "undefined"
      // ) {
      //   const res = await WebApi.api().get(
      //     `admin/niveles?fechaInicio=${l.fechaInicio}&fechaFin=${l.fechaFin}`
      //   );
      //   return res.data.result;
      // } else {
        const res = await WebApi.api().get(`admin/niveles`);
        return res.data.result;
      // }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerRoles = createAsyncThunk(
  "genericos/obtenerRoles",
  async (i, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`admin/roles`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerPeriodos = createAsyncThunk(
  "genericos/obtenerPeriodos",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("periodos");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerAsesores = createAsyncThunk(
  "genericos/obtenerAsesores",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("asesores");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerRazones = createAsyncThunk(
  "genericos/obtenerRazones",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("razones");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerFormas = createAsyncThunk(
  "genericos/obtenerFormas",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("formas");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerTiposIdentificaciones = createAsyncThunk(
  "genericos/obtenerTiposIdentificaciones",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("tiposIdentificaciones");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerGeneros = createAsyncThunk(
  "genericos/obtenerGeneros",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("generos");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerEstadosSolicitud = createAsyncThunk(
  "solicitudes/obtenerEstadosSolicitud",
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/estados");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const genericosSlice = createSlice({
  name: "genericos",
  initialState: {
    paises: [],
    provincias: [],
    cantones: [],
    estadosCiviles: [],
    estadosSolicitud: [],
    estados: [],
    niveles: [],
    tiposOrganizaciones: [],
    distritos: [],
    tiposPlantillas: [],
    documentos: [],
    archivosTipos: [],
    areas: [],
    roles: [],
    tiposFinanciamientos: [],
    tiposIdentificaciones: [],
    carreras: [],
    areasCarreras: [],
    nivelesCarreras: [],
    nivelesIdiomas: [],
    periodos: [],
    asesores: [],
    razones: [],
    formas: [],
    generos: [],
    solicitudesPorNiveles: [],
    solicitudesPorEstados: [],
    solicitudesUsuarioActual: [],
    solicitudesPorCarrera: [],
    estudiantesPorCarrera: [],
    solicitudesCarrerasPorNivel: [],
    solicitudesPorMeses: [],
    solicitudesMayoresRazones: [],
    status: "",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [obtenerEstadosSolicitud.fulfilled]: (state, action) => {
      state.estadosSolicitud = action.payload;
    },
    [obtenerPaises.pending]: (state, action) => {
      state.loading = true;
    },
    [obtenerPaises.fulfilled]: (state, action) => {
      state.paises = action.payload;
    },
    [obtenerEstadosCiviles.fulfilled]: (state, action) => {
      state.estadosCiviles = action.payload;
    },
    [obtenerEstados.fulfilled]: (state, action) => {
      state.estados = action.payload;
    },
    [obtenerProvincias.fulfilled]: (state, action) => {
      state.provincias = action.payload;
    },
    [obtenerDistritos.fulfilled]: (state, action) => {
      state.distritos = action.payload;
    },
    [obtenerCantones.fulfilled]: (state, action) => {
      state.cantones = action.payload;
    },
    [obtenerExtensionesArchivos.fulfilled]: (state, action) => {
      state.archivosTipos = action.payload;
    },
    [obtenerTiposFinanciamientos.fulfilled]: (state, action) => {
      state.tiposFinanciamientos = action.payload;
    },
    [obtenerTiposOrganizaciones.fulfilled]: (state, action) => {
      state.tiposOrganizaciones = action.payload;
    },
    [obtenerNiveles.fulfilled]: (state, action) => {
      state.nivelesCarreras = action.payload;
    },
    [obtenerNivelesIdiomas.fulfilled]: (state, action) => {
      state.nivelesIdiomas = action.payload;
    },
    [obtenerCarreras.fulfilled]: (state, action) => {
      state.carreras = action.payload;
    },
    [obtenerAreasCarreras.fulfilled]: (state, action) => {
      state.areasCarreras = action.payload;
    },
    [obtenerNivelCarreras.fulfilled]: (state, action) => {
      state.nivelesCarreras = action.payload;
    },
    [obtenerPeriodos.fulfilled]: (state, action) => {
      state.periodos = action.payload;
    },
    [obtenerDocumentos.fulfilled]: (state, action) => {
      state.documentos = action.payload;
    },
    [obtenerDocumentosPorPerfil.fulfilled]: (state, action) => {
      state.documentos = action.payload;
    },
    [obtenerTiposPlantillas.fulfilled]: (state, action) => {
      state.tiposPlantillas = action.payload;
    },
    [obtenerAsesores.fulfilled]: (state, action) => {
      state.asesores = action.payload;
    },
    [obtenerRazones.fulfilled]: (state, action) => {
      state.razones = action.payload;
    },
    [obtenerFormas.fulfilled]: (state, action) => {
      state.formas = action.payload;
    },
    [obtenerTiposIdentificaciones.fulfilled]: (state, action) => {
      state.tiposIdentificaciones = action.payload;
    },
    [obtenerGeneros.fulfilled]: (state, action) => {
      state.generos = action.payload;
    },
    [obtenerRoles.fulfilled]: (state, action) => {
      state.roles = action.payload;
    },
    [obtenerCarrerasMasEstudiantes.fulfilled]: (state, action) => {
      state.estudiantesPorCarrera = action.payload;
    },
    [obtenerCarrerasMayorInteres.fulfilled]: (state, action) => {
      state.solicitudesPorCarrera = action.payload;
    },
    [obtenerSolicitudesUsuario.fulfilled]: (state, action) => {
      state.solicitudesUsuarioActual = action.payload;
    },
    [obtenerSolicitudesPorEstados.fulfilled]: (state, action) => {
      state.solicitudesPorEstados = action.payload;
    },
    [obtenerSolicitudesPorMes.fulfilled]: (state, action) => {
      state.solicitudesPorMeses = action.payload;
    },
    [obtenerSolicitudesPorNiveles.fulfilled]: (state, action) => {
      state.solicitudesPorNiveles = action.payload;
    },
    [obtenerSolicitudesCarrerasPorNivel.fulfilled]: (state, action) => {
      state.solicitudesCarrerasPorNivel = action.payload;
    },
    [obtenerEstadisticasRazones.fulfilled]: (state, action) => {
      state.solicitudesMayoresRazones = action.payload;
    },
  },
});

export default genericosSlice.reducer;
