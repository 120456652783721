import React, { useEffect, useState, useRef } from 'react';
import EditarPerfil from '../../SVG/Editarperfil.svg';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import '../css/editInfo.css';
import {
  actualizarUsuario,
  obtenerUsuarioActual,
  verificarCorreo,
  verificarTelefono,
  validarCorreo,
  validarTelefono,
  ObtenerPaisesPrefijos,
} from '../../redux/usuariosSlice';
import OperationStatus from '../../config/OperationStatus';
import Modal from '../../Adm/Components/Modal';
import WindowDimension from '../../Hooks/WindowDimension';
import ReactSelect from '../../Components/ReactSelect';
export default function EditInfo(props) {
  const { usuario, prefijosLista } = useSelector((state) => state.usuarios);

  const { height, width } = WindowDimension();

  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [tieneImagen, setTieneImagen] = useState(false);

  const [showModalCorreo, setShowModalCorreo] = useState(false);
  const [showModalTelefono, setShowModalTelefono] = useState(false);
  const fileInput = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const consultadoEnPadron = watch('consultadoEnPadron');
  const telefonoValidado = watch('telefonoValidado');
  const correoValidado = watch('correoValidado');

  const {
    register: registerModal,
    formState: { errors: errorsModal },
    handleSubmit: handleModalSubmit,
    control: controlModel,
    reset: resetModal,
  } = useForm();
  const {
    register: registerTel,
    formState: { errors: errorsTel },
    handleSubmit: handleTelSubmit,
    control: controlTel,
    reset: resetTel,
  } = useForm();

  useEffect(() => {
    dispatch(obtenerUsuarioActual());
    dispatch(ObtenerPaisesPrefijos());
  }, [dispatch]);

  useEffect(() => {
    reset(usuario);
    if (usuario.foto) {
      setTieneImagen(true);

      setPreview(`data:image/jpg;base64, ${usuario.foto}`);
    }
  }, [usuario]);

  const [lista, setLista] = useState([]);

  useEffect(() => {
    let a = prefijosLista?.map((item, i) => {
      return { label: item.codigoArea + ' ' + item.paisNombre, value: item.id };
    });
    setLista(a);
  }, [prefijosLista]);

  const onChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      setTieneImagen(true);
    } else {
      setImage(null);
      tieneImagen(false);
    }
  };
  const onSubmit = async (data) => {
    var fData = new FormData();
    fData.append('Dni', data.dni);
    fData.append('Nombres', data.nombres);
    fData.append('alias', data.alias);
    fData.append('PrimerApellido', data.primerApellido);
    fData.append('SegundoApellido', data.segundoApellido);
    fData.append('CorreoElectronico', data.correoElectronico);
    fData.append('Fotografia', image);
    fData.append('TelefonoPrefijoId', data.telefonoPrefijoId);
    fData.append('TelefonoSufijo', data.telefonoSufijo);

    if (usuario.fotografiaId !== null)
      fData.append('FotografiaId', usuario.fotografiaId);

    let { payload } = await dispatch(actualizarUsuario(fData));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      dispatch(obtenerUsuarioActual());
    } else {
      toast.error(payload.message);
    }
  };

  const onSubmitCorreo = async (data) => {
    let { payload } = await dispatch(validarCorreo(data.codigo));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      dispatch(obtenerUsuarioActual());
      toast.success(payload.message);
      closeModal();
    } else {
      toast.error(payload.message);
    }
  };
  const onSubmitTelefono = async (data) => {
    let { payload } = await dispatch(validarTelefono(data.codigo));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      dispatch(obtenerUsuarioActual());
      toast.success(payload.message);
      closeModal();
    } else {
      toast.error(payload.message);
    }
  };

  const correoValidar = async (e) => {
    e.preventDefault();

    let { payload } = await dispatch(verificarCorreo());

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      setShowModalCorreo(true);
    } else {
      toast.error(payload.message);
    }
  };
  const telefonoValidar = async (e) => {
    e.preventDefault();

    let { payload } = await dispatch(verificarTelefono());

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      setShowModalTelefono(true);
    } else {
      toast.error(payload.message);
    }
  };
  const closeModal = (e) => {
    setShowModalCorreo(false);
    setShowModalTelefono(false);
    resetModal({ codigo: '' });
    resetTel({ codigo: '' });
  };

  return (
    <>
      <div className="rootEdit">
        <div className="principal">
          <div className="container-root">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid-container container col-2 col-md">
                <div>
                  <div className="grid-container col-2 col-lg-2 col-sm-12">
                    <div className="grid-item">
                      <div className="form-group">
                        <label>Fotografía (Tipo pasaporte)</label>
                        <div className="div-relative">
                          {tieneImagen ? (
                            <>
                              <img
                                className="img-perfil imagen-cuadrada"
                                alt="Imagen de perfil"
                                src={preview}
                              />
                              <button
                                className="btn-quit-image"
                                type="button"
                                onClick={() => {
                                  setImage(null);
                                  setTieneImagen(false);
                                }}
                              >
                                <i className="material-icons"></i>
                              </button>
                            </>
                          ) : (
                            <>
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onInput={onChange}
                                ref={(ref) => {
                                  fileInput.current = ref;
                                }}
                                name="archivo"
                              />
                              <button
                                className="btn-load-image"
                                type="button"
                                onClick={() => {
                                  fileInput.current.click();
                                }}
                              >
                                Añadir imagen
                              </button>

                              {errors.archivo && (
                                <p className="error">
                                  {errors.archivo.message}
                                </p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid-item">
                      <div className="form-group">
                        <label>No. de identificación</label>
                        <input
                          className="form-control"
                          disabled={consultadoEnPadron}
                          type="text"
                          {...register('dni', {
                            required: 'Campo requerido',
                          })}
                        />
                        {errors.dni && (
                          <p className="error">{errors.dni.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="grid-item">
                      <div className="form-group">
                        <label>Nombre(s)</label>
                        <input
                          disabled={consultadoEnPadron}
                          className="form-control"
                          {...register('nombres', {
                            required: 'Campo requerido',
                          })}
                          type="text"
                        />
                        {errors.nombres && (
                          <p className="error">{errors.nombres.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="grid-item">
                      <div className="form-group">
                        <label>Conocido como</label>
                        <input
                          disabled={consultadoEnPadron}
                          className="form-control"
                          {...register('alias')}
                          type="text"
                        />
                        {errors.alias && (
                          <p className="error">{errors.alias.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="grid-item">
                      <div className="form-group">
                        <label>Primer Apellido</label>
                        <input
                          disabled={consultadoEnPadron}
                          className="form-control"
                          type="text"
                          {...register('primerApellido', {
                            required: 'Campo requerido',
                          })}
                        />
                        {errors.primerApellido && (
                          <p className="error">
                            {errors.primerApellido.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="grid-item">
                      <div className="form-group">
                        <label>Segundo Apellido</label>
                        <input
                          disabled={consultadoEnPadron}
                          className="form-control"
                          type="text"
                          {...register('segundoApellido', {
                            required: 'Campo requerido',
                          })}
                        />
                        {errors.segundoApellido && (
                          <p className="error">
                            {errors.segundoApellido.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="grid-item">
                      <div className="form-group">
                        <label>Correo electrónico</label>
                        <div className="d-flex">
                          <input
                            className="form-control"
                            type="email"
                            {...register('correoElectronico', {
                              required: 'Campo requerido',
                              pattern: {
                                value: /^\S+@\S+$/i,
                                message: 'Escriba un correo valido',
                              },
                            })}
                          />
                          {!correoValidado && (
                            <button
                              className="button-text"
                              onClick={correoValidar}
                            >
                              Validar
                            </button>
                          )}
                        </div>
                        {errors.correoElectronico && (
                          <p className="error">
                            {errors.correoElectronico.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="grid-container col-2 col-lg col-md col-sm-12">
                    <div className="grid-item">
                      <div className="form-group">
                        <label>No. de teléfono</label>
                        <div className="d-flex flex-gap-2">
                          <div className="select-container">
                            <ReactSelect
                              name="telefonoPrefijoId"
                              control={control}
                              lista={lista}
                              rules={{ required: 'Campo requerido' }}
                            />
                          </div>

                          <input
                            className="form-control"
                            type="text"
                            {...register('telefonoSufijo', {
                              required: 'Campo requerido',
                            })}
                          />

                          {!telefonoValidado && (
                            <button
                              className="button-text"
                              onClick={telefonoValidar}
                            >
                              Validar
                            </button>
                          )}
                        </div>
                        {errors.telefonoPrefijoId && (
                          <p className="error">
                            {errors.telefonoPrefijoId.message}
                          </p>
                        )}
                        {errors.telefonoSufijo && (
                          <p className="error">
                            {errors.telefonoSufijo.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-control">
                  <img src={EditarPerfil} alt="Imagen editar perfil" />
                  <div>
                    <button type="submit">Guardar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        estado={showModalCorreo}
        cambiarEstado={() => {
          closeModal();
        }}
        titulo="Verificar correo"
        clase={`${width < 576 ? 'full-width' : 'small'}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleModalSubmit(onSubmitCorreo)}
            >
              Verificar
            </button>
          </>
        }
      >
        <form>
          <span className="body-text mt-2 mb-2">
            Se ha enviado un correo con el código de verificación
          </span>

          <div className="form-group">
            <i className="material-icons important"></i>
            <label>Código de verificación</label>
            <input
              className="form-control"
              {...registerModal('codigo', { required: 'Campo requerido' })}
            />
            {errorsModal.codigo && (
              <p className="error">{errorsModal.codigo.message}</p>
            )}
          </div>
        </form>
      </Modal>
      <Modal
        estado={showModalTelefono}
        cambiarEstado={closeModal}
        titulo="Verificar teléfono"
        clase={`${width < 576 ? 'full-width' : 'small'}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleTelSubmit(onSubmitTelefono)}
            >
              Verificar
            </button>
          </>
        }
      >
        <form>
          <span className="body-text mt-2 mb-2">
            Se ha enviado un sms con el código de verificación
          </span>

          <div className="form-group">
            <i className="material-icons important"></i>
            <label>Código de verificación</label>
            <input
              className="form-control"
              {...registerTel('codigo', { required: 'Campo requerido' })}
            />
            {errorsTel.codigo && (
              <p className="error">{errorsTel.codigo.message}</p>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
}
