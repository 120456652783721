import React from 'react'
import AppBar from '../Components/AppBar';
import '../css/login.css'
import myImage from '../../SVG/LoginCenfotec.svg'
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import autService from '../../services/authService'
import WebApi from '../../Api/WebApi'
import OperationStatus from '../../config/OperationStatus'
import { useState } from 'react';
import LoadingSpiner from '../../Components/LoadingSpiner'


export default function Login() {
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        setLoading(true)
        let res = await autService.login(data, dispatch)
        if (res.operationStatus === OperationStatus.SUCCESS) {

            localStorage.setItem("token", res.result.token);
            localStorage.setItem("refreshToken", JSON.stringify(res.result.refreshToken));

            WebApi.setAuthToken(res.result.token);
            navigate("/");
        } else {
            setLoading(false)
            toast.error(res.message);
        }
    }
    return (
        <div className='rootLogin'>
            <AppBar />

            <div className="loginContainer">
                <div className="grid-container col-2 col-md col-sm-12 ">
                    <div className="grid-item justify-self-center login-item">
                        <h1>CENFOTEC Admisiones</h1>
                        <h2>Inicio de sesión</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label>Correo electrónico</label>
                                <input className="form-control login-input" type="text" placeholder="ejemplo@gmail.com" {...register("correo", { required: "Campo requerido" })} />
                                {errors.correo && <p className="error">{errors.correo.message}</p>}
                            </div>
                            <div className="form-group login-input">
                                <label>Contraseña</label>
                                <input className="form-control" type="password" autoComplete="on" placeholder="Contraseña" {...register("contrasena", { required: "Campo requerido" })} name="contrasena" />
                                {errors.contrasena && <p className="error">{errors.contrasena.message}</p>}
                            </div>


                            <div className='loginFlex'>
                                <input type="submit" id="btnInicioSesion" value="Iniciar sesión" />
                                {loading &&
                                    <div className="ml-1">
                                        <LoadingSpiner
                                            styles={{
                                                "width": "25px",
                                                "height": "25px",
                                                "border": "6px solid #bbbbbb",
                                                'borderTop': "6px solid #035cc9",
                                            }} />
                                    </div>
                                }
                            </div>
                        </form>

                        <div className="links">
                            <h5><Link to="/RecuperarContrasena" >¿Has olvidado la contraseña?</Link></h5>
                            <h5>Soy administrador - <Link to="/LoginAdm">Accede aquí</Link></h5>
                            <h5>¿No tienes cuenta? <Link to="/Registro">Regístrate</Link></h5>
                        </div>
                    </div>
                    <div className="grid-item justify-self-center reverse login-item">
                        <img className="img-login" src={myImage} alt="Imagen de inicio de sesión" />

                    </div>

                </div>
            </div>

        </div>
    )
}
