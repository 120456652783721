import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerSolicitudInfoEstudios,
  agregarSolicitudInfoEstudios,
} from "../../redux/solicitudesSlice";
import { toast } from "react-toastify";
import SelectAsync from "./selectAsync";
import ReactSelect from "../../Components/ReactSelect";
import OperationStatus from "../../config/OperationStatus";
import Required from "../../Components/Required";
import { convertToBoolean } from "../../Utils/utils";

export default function AcademicInfo(props) {
  const [listaPaises, setListaPaises] = useState([]);
  const [listaGrados, setListaGrados] = useState([]);
  const [listaEstados, setListaEstados] = useState([]);
  const [listaIdiomaNiveles, setListaIdiomaNiveles] = useState([]);
  const [marcoComunListado, setMCE] = useState([]);
  const costaRicaId = 192;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "personasIdiomas",
  });
  const {
    fields: nivelesFields,
    update,
    replace,
    append: nivelesAppend,
    remove: nivelesRemove,
  } = useFieldArray({
    control,
    name: "personasEstudios",
  });
  const dispatch = useDispatch();

  const { solicitudInfoEstudios } = useSelector((state) => state.solicitudes);
  const teniaApoyoAcademico = watch("teniaApoyoAcademico");
  const personasEstudiosList = watch("personasEstudios");
  const personaId = watch("personaId");
  const personasEstudios = watch("personasEstudios");

  useEffect(() => {
    dispatch(obtenerSolicitudInfoEstudios(props.solicitudId));
  }, [dispatch]);

  useEffect(() => {
    reset({ ...solicitudInfoEstudios, teniaApoyoAcademico: null });
    if (solicitudInfoEstudios.infoEstudiosModificado) {
      setValue(
        "teniaApoyoAcademico",
        solicitudInfoEstudios.teniaApoyoAcademico
      );
    }
    if (solicitudInfoEstudios) {
      setValue("personasEstudios", solicitudInfoEstudios.personasEstudios);
    }
  }, [solicitudInfoEstudios]);

  useEffect(() => {
    let a = solicitudInfoEstudios?.paises?.map((item, i) => {
      return { label: item.paisNombre, value: item.paisId };
    });
    setListaPaises(a);

    let b = solicitudInfoEstudios?.estados?.map((item, i) => {
      return { label: item.estadoNombre, value: item.estadoId };
    });
    setListaEstados(b);

    let d = solicitudInfoEstudios?.personasIdiomaNiveles?.map((item, i) => {
      return { label: item.nivelIdiomaNombre, value: item.nivelIdiomaId };
    });
    setListaIdiomaNiveles(d);

    let e = solicitudInfoEstudios?.idiomasMarcoComunEuropeo?.map((item, i) => {
      return {
        label: item.marcoComunEuropeoNombre,
        value: item.marcoComunEuropeoId,
      };
    });
    setMCE(e);
    let f = solicitudInfoEstudios?.personasEstudiosGrados?.map((item, i) => {
      return { label: item.gradoNombre, value: item.gradoId };
    });
    setListaGrados(f);
  }, [solicitudInfoEstudios]);

  const appendNivel = (nivelId) => {
    nivelesAppend({
      id: 0,
      personaId: personaId,
      nivelId: nivelId,
      centroEducativo: "",
      centroEducativoId: null,
      estadoId: "",
      paisId: null,
      fechaConclusion: null,
      tituloObtenido: null,
      gradoId: null,
    });
  };

  useEffect(() => {
    if (personasEstudiosList) {
      if (personasEstudiosList.length === 0) {
        let nivelesL = [2];
        nivelesL.forEach((el) => {
          appendNivel(el);
        });
      }
    }
  }, [personasEstudiosList]);

  const onSubmit = async (data) => {
    data.teniaApoyoAcademico = convertToBoolean(data.teniaApoyoAcademico);

    data.personasEstudios.forEach((element) => {
      if (isObject(element.centroEducativoId)) {
        if (typeof element.centroEducativoId.value === "number") {
          element.centroEducativoId = element.centroEducativoId.value;
          element.centroEducativo = null;
        } else {
          element.centroEducativo = element.centroEducativoId.value;
          element.centroEducativoId = null;
        }
      }
    });

    if (data.etapaActual === 5) data.etapaActual += 1;

    data.solicitudId = props.solicitudId;

    let { payload } = await dispatch(agregarSolicitudInfoEstudios(data));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      props.onGoNext();
      props.handleStepChange(data.etapaActual);
    } else {
      toast.error(payload.message);
    }
  };
  const isRequired = (e) => {
    if (teniaApoyoAcademico === "true") return e.trim() != "";
    return true;
  };

  const isObject = (prop) => {
    return typeof prop === "object" && prop !== null;
  };

  const isValid = (e) => {
    return typeof convertToBoolean(e) == "boolean";
  };
  const validateCentro = (index) => (e) => {
    let res = false;
    let a = personasEstudios[index];
    if (a.centroEducativo || a.centroEducativoId) {
      res = true;
    }
    return res;
  };

  const handleTipoOrganizacion = (index, item) => {
    let val = item.value;
    if (typeof item.value === "string") {
      val = null;
    }
    let element = {};
    if (typeof item.value === "number") {
      element.centroEducativoId = item.value;
      element.centroEducativo = null;
    } else {
      element.centroEducativo = item.value;
      element.centroEducativoId = null;
    }
    setValue(
      `personasEstudios.${index}.centroEducativoId`,
      element.centroEducativoId
    );
    setValue(
      `personasEstudios.${index}.centroEducativo`,
      element.centroEducativo
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-solicitud">
      <div className="grid-container">
        <div>
          <div className="title-container">
            <span className="form-title">Estudios</span>
          </div>
          <br />
          <div className="estudios-title mb-2">
            <label className="form-subheader">Estudios secundarios</label>
          </div>

          {nivelesFields.map(
            (item, index) =>
              item.nivelId == 2 && (
                <div
                  className="grid-container col-4 col-lg-4 col-md-4 col-sm-12 border-bottom"
                  key={index}
                >
                  <input type="hidden" value={item.nivelId} />
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Pais</label>
                      <Required />
                      <ReactSelect
                        name={`personasEstudios.${index}.paisId`}
                        control={control}
                        lista={listaPaises}
                        rules={{ required: "Campo requerido" }}
                      />
                      {errors.personasEstudios?.[index]?.paisId && (
                        <p className="error">
                          {errors?.personasEstudios?.[index]?.paisId.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Estado</label>
                      <Required />
                      <ReactSelect
                        name={`personasEstudios.${index}.estadoId`}
                        control={control}
                        lista={listaEstados}
                        rules={{ required: "Campo requerido" }}
                      />
                      {errors.personasEstudios?.[index]?.estadoId && (
                        <p className="error">
                          {errors?.personasEstudios?.[index]?.estadoId.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid-item">
                    <div className="form-group">
                      <label>Centro educativo</label>
                      <Required />
                      <SelectAsync
                        control={control}
                        name={`personasEstudios.${index}.centroEducativoId`}
                        rules={{ validate: validateCentro(index) }}
                        tipo={1}
                        nivelId={item.nivelId}
                        paisId={personasEstudios[index].paisId}
                        valueDefault={personasEstudios[index].centroEducativoId}
                        centroEducativo={
                          personasEstudios[index].centroEducativo
                        }
                      />
                      {errors.personasEstudios?.[index]?.centroEducativoId
                        ?.type === "validate" && (
                        <p className="error">Campo requerido</p>
                      )}
                      {personasEstudiosList[index].paisId == costaRicaId &&
                      personasEstudiosList[index].centroEducativoId == null ? (
                        <a
                          role="button"
                          className="button-text d-block"
                          target="_blank"
                          href="https://cenfotec868.zohodesk.com/portal/es/home"
                        >
                          ¿No aparece su centro? contactenos
                        </a>
                      ) : null}
                    </div>
                  </div>

                  <div className="grid-item">
                    <div className="form-group">
                      <label>Título obtenido</label>
                      <Required />
                      <input
                        className="form-control"
                        type="text"
                        {...register(
                          `personasEstudios.${index}.tituloObtenido`,
                          { required: "Campo requerido" }
                        )}
                      />
                      {errors.personasEstudios?.[index]?.tituloObtenido && (
                        <p className="error">
                          {
                            errors?.personasEstudios?.[index]?.tituloObtenido
                              .message
                          }
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item-col-2">
                    <div className="form-group">
                      <label>Año conclusión</label>
                      <Required />
                      <input
                        className="form-control"
                        type="number"
                        {...register(
                          `personasEstudios.${index}.fechaConclusion`,
                          { required: "Campo requerido" }
                        )}
                      />
                      {errors.personasEstudios?.[index]?.fechaConclusion && (
                        <p className="error">
                          {
                            errors?.personasEstudios?.[index]?.fechaConclusion
                              .message
                          }
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>

        <div>
          <div className="estudios-title mb-2">
            <label className="form-subheader">Estudios universitarios</label>
          </div>
          {nivelesFields.map(
            (item, index) =>
              item.nivelId == 3 && (
                <div
                  className="grid-container col-4 col-lg-4 col-md-4 col-sm-12 border-bottom"
                  key={index}
                >
                  <input type="hidden" value={item.nivelId} />
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Pais</label>
                      <Required />
                      <ReactSelect
                        name={`personasEstudios.${index}.paisId`}
                        control={control}
                        lista={listaPaises}
                        rules={{ required: "Campo requerido" }}
                      />
                      {errors.personasEstudios?.[index]?.paisId && (
                        <p className="error">
                          {errors?.personasEstudios?.[index]?.paisId.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Estado</label>
                      <Required />
                      <ReactSelect
                        name={`personasEstudios.${index}.estadoId`}
                        control={control}
                        lista={listaEstados}
                        rules={{ required: "Campo requerido" }}
                      />

                      {errors.personasEstudios?.[index]?.estadoId && (
                        <p className="error">
                          {errors?.personasEstudios?.[index]?.estadoId.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid-item">
                    <div className="form-group">
                      <label>Centro educativo</label>
                      <Required />
                      <input
                        className="form-control"
                        type="text"
                        {...register(
                          `personasEstudios.${index}.centroEducativo`,
                          { required: "Campo requerido" }
                        )}
                      />
                      {errors.personasEstudios?.[index]?.centroEducativo && (
                        <p className="error">
                          {
                            errors?.personasEstudios?.[index]?.centroEducativo
                              .message
                          }
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Título obtenido</label>
                      <Required />
                      <input
                        className="form-control"
                        type="text"
                        {...register(
                          `personasEstudios.${index}.tituloObtenido`,
                          { required: "Campo requerido" }
                        )}
                      />
                      {errors.personasEstudios?.[index]?.tituloObtenido && (
                        <p className="error">
                          {
                            errors?.personasEstudios?.[index]?.tituloObtenido
                              .message
                          }
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid-item">
                    <div className="form-group">
                      <label>Año conclusión</label>
                      <Required />
                      <input
                        className="form-control"
                        type="number"
                        {...register(
                          `personasEstudios.${index}.fechaConclusion`,
                          { required: "Campo requerido" }
                        )}
                      />
                      {errors.personasEstudios?.[index]?.fechaConclusion && (
                        <p className="error">
                          {
                            errors?.personasEstudios?.[index]?.fechaConclusion
                              .message
                          }
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item-col-2">
                    <div className="form-group">
                      <label>Grado obtenido</label>
                      <Required />
                      <ReactSelect
                        name={`personasEstudios.${index}.gradoId`}
                        control={control}
                        lista={listaGrados}
                        rules={{ required: "Campo requerido" }}
                      />
                      {errors.personasEstudios?.[index]?.gradoId && (
                        <p className="error">
                          {errors?.personasEstudios?.[index]?.gradoId.message}
                        </p>
                      )}
                    </div>
                    <div className="icon-input">
                      <button
                        className="button-text"
                        onClick={(e) => {
                          e.preventDefault();
                          nivelesRemove(index);
                        }}
                      >
                        <i className="material-icons"></i>
                      </button>
                    </div>
                  </div>
                </div>
              )
          )}
          <div className="estudios-title">
            <button
              className="button-text d-flex"
              onClick={(e) => {
                e.preventDefault();
                appendNivel(3);
              }}
            >
              <label className="form-subheader">
                {nivelesFields.filter((a) => a.nivelId == 3).length > 0
                  ? "Agregar otro"
                  : "Agregar estudio universitario"}{" "}
              </label>
              <i className="material-icons"></i>
            </button>
          </div>
        </div>

        <br />
        <div className="grid-container">
          <div className="grid-item">
            <div className="form-group">
              <label>
                ¿Contaba con algún apoyo acádemico durante sus estudios previos?
              </label>
              <Required />
              <div className="check-container">
                <div className="form-control-check inline">
                  <input
                    type="radio"
                    value={true}
                    checked={convertToBoolean(teniaApoyoAcademico) == true}
                    {...register("teniaApoyoAcademico", { validate: isValid })}
                  />
                  <label>Si</label>
                </div>
                <div className="form-control-check inline">
                  <input
                    type="radio"
                    value={false}
                    checked={convertToBoolean(teniaApoyoAcademico) == false}
                    {...register("teniaApoyoAcademico", { validate: isValid })}
                  />
                  <label>No</label>
                </div>
                {convertToBoolean(teniaApoyoAcademico) && (
                  <>
                    <input
                      className="form-control"
                      id="inputAlergia"
                      placeholder="Especifique (Si)"
                      type="text"
                      {...register("apoyoEstudiosNombre", {
                        validate: isRequired,
                      })}
                      disabled={convertToBoolean(teniaApoyoAcademico) !== true}
                    />
                    {errors?.apoyoEstudio?.type === "validate" && (
                      <p className="error">Campo requerido</p>
                    )}
                  </>
                )}
                {errors?.teniaApoyoAcademico?.type === "validate" && (
                  <p className="error">Campo requerido</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="estudios-title">
          <button
            className="button-text d-flex"
            onClick={(e) => {
              e.preventDefault();
              append({
                idiomaNombre: "",
                nivelLecturaId: null,
                nivelEscrituraId: null,
                nivelHablaId: null,
                idiomaMarcoComunEuropeoId: null,
              });
            }}
          >
            <label className="form-subheader">Manejo de idiomas</label>

            <i className="material-icons"></i>
          </button>
        </div>

        {fields.map((item, index) => (
          <div
            className="grid-container col-5 col-sm-12 col-md-2 col-lg-2 "
            key={"el_" + index}
          >
            <div className="grid-item">
              <div className="form-group">
                <label>Idioma</label>
                <Required />
                <input
                  className="form-control"
                  type="text"
                  {...register(`personasIdiomas.${index}.idiomaNombre`, {
                    required: "Campo requerido",
                  })}
                />
                {errors.personasIdiomas?.[index]?.idiomaNombre && (
                  <p className="error">
                    {errors?.personasIdiomas?.[index]?.idiomaNombre.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid-item">
              <div className="form-group">
                <label>Lectura</label>
                <Required />
                <ReactSelect
                  name={`personasIdiomas.${index}.nivelLecturaId`}
                  control={control}
                  lista={listaIdiomaNiveles}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.personasIdiomas?.[index]?.nivelLecturaId && (
                  <p className="error">
                    {errors?.personasIdiomas?.[index]?.nivelLecturaId.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Escritura</label>
                <Required />
                <ReactSelect
                  name={`personasIdiomas.${index}.nivelEscrituraId`}
                  control={control}
                  lista={listaIdiomaNiveles}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.personasIdiomas?.[index]?.nivelEscrituraId && (
                  <p className="error">
                    {errors?.personasIdiomas?.[index]?.nivelEscrituraId.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Habla</label>
                <Required />
                <ReactSelect
                  name={`personasIdiomas.${index}.nivelHablaId`}
                  control={control}
                  lista={listaIdiomaNiveles}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.personasIdiomas?.[index]?.nivelHablaId && (
                  <p className="error">
                    {errors?.personasIdiomas?.[index]?.nivelHablaId.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid-item-col-2">
              <div className="form-group">
                <label>MCER</label>
                <Required />
                <ReactSelect
                  name={`personasIdiomas.${index}.idiomaMarcoComunEuropeoId`}
                  control={control}
                  lista={marcoComunListado}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.personasIdiomas?.[index]?.idiomaMarcoComunEuropeoId && (
                  <p className="error">
                    {
                      errors?.personasIdiomas?.[index]
                        ?.idiomaMarcoComunEuropeoId.message
                    }
                  </p>
                )}
              </div>

              <div className="icon-input">
                <button
                  className="button-text"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  <i className="material-icons"></i>
                </button>
              </div>
            </div>
          </div>
        ))}

        <div className="button-control">
          <div>
            <button onClick={props.onGoBack}>Atrás</button>
            <button type="submit">Continuar</button>
          </div>
        </div>
      </div>
    </form>
  );
}
