import React, { useRef } from 'react'

export default function UploadBtn(props) {

    const hiddenFileInput = useRef(null);
    const handleClick = event => {
        event.preventDefault()
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };
    return (
        <div className="upload-btn-wrapper div-relative">

            <button 
                onClick={handleClick} className="btn">{props.title}</button>
            <input onChange={handleChange} ref={hiddenFileInput} accept={props.accepted}
                style={{ display: 'none' }} type="file" name="myfile" />
        </div >
    )
}
