import { useState } from "react";
import { useDispatch } from "react-redux";
import { validarSicobatecManual } from "../../redux/solicitudesAdmSlice";
import OperationStatus from "../../config/OperationStatus";
import { toast } from "react-toastify";

export default function useValidarSicobatec(solicitudId) {
  const [validadoManualmente, setValidadoManualmente] = useState(false);
  const [noAplicaSicobatec, setNoAplicaSicobatec] = useState(false);
  const [verificarDespuesSicobatec, setVerificarDespuesSicobatec] =
    useState(false);
  const dispatch = useDispatch();

  const validarSicobatecManualmente = async (e, callback) => {
    e.preventDefault();
    let obj = {
      ValidadoManualmenteEnSicobatec: validadoManualmente,
      verificarDespuesSicobatec: verificarDespuesSicobatec,
      noAplicaSicobatec: noAplicaSicobatec,
      SolicitudId: solicitudId,
    };

    let { payload } = await dispatch(validarSicobatecManual(obj));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      callback();
      toast.success(payload.message);
    } else {
      toast.error(payload.message);
    }
  };

  return {
    validadoManualmente,
    setValidadoManualmente,
    noAplicaSicobatec,
    setNoAplicaSicobatec,
    verificarDespuesSicobatec,
    setVerificarDespuesSicobatec,
    validarSicobatecManualmente
  };
}
