import "../Styles/sidebar.css";
import logoImg from "../../SVG/LogoCenfotec.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import authService from "../../services/authService";
import RolesConfig from "../../config/Roles";

// Desplegar menu de configuracion y de usuario
const optionMenuT = (e) => {
  const optionMenu = document.querySelector(".optionMenu");
  const toggleBtn = document.getElementById("showConfigIcon");
  optionMenu.classList.toggle("unhidden");
  toggleBtn.classList.toggle("rotate");
};

const optionReportesT = (e) => {
  const optionMenu = document.querySelector(".optionReportes");
  const toggleBtn = document.getElementById("showReportesIcon");
  optionMenu.classList.toggle("unhidden");
  toggleBtn.classList.toggle("rotate");
};

const optionCamposT = (e) => {
  const optionMenu = document.querySelector(".optionCampos");
  const toggleBtn = document.getElementById("showCampoIcon");
  optionMenu.classList.toggle("unhidden");
  toggleBtn.classList.toggle("rotate");
};

const hideSidebar = () => {
  const sidebar = document.querySelector(".sideBar");
  sidebar.classList.toggle("sideBarVisible");

  const closeBtn = document.querySelectorAll(".toggleBtnAdm");
  closeBtn.forEach((el) => {
    el.classList.toggle("invisibleBtn");
  });
};

function SideBar(props) {
  const navigate = useNavigate();
  const { hasPermission } = props;
  const Rol = RolesConfig;

  const menuPrincipal = [
    {
      optionId: "1",
      option: "Inicio",
      path: "/Admin",
      icon: "",
    },
    {
      optionId: "2",
      option: "Administración de solicitudes",
      path: "solicitudes",
      icon: "",
    },
    {
      optionId: "3",
      option: "Documentos",
      path: "listaArchivos",
      icon: "",
    },
    {
      optionId: "4",
      option: "Asignación de becas y patrocinios",
      path: "listaBecasPatrocinios",
      icon: "",
    },
  ];
  const optionArray = [
    { optionId: "1", option: "Carreras a ofertar", path: "carreras" },
    { optionId: "2", option: "Centros educativos", path: "centros" },
    {
      optionId: "3",
      option: "Configuraciones generales",
      path: "configGeneral",
    },
    { optionId: "4", option: "Definición de perfiles", path: "perfiles" },
    { optionId: "5", option: "Niveles academicos", path: "niveles" },
    { optionId: "6", option: "Perfil parámetros", path: "perfilesParametros" },
    { optionId: "7", option: "Plantillas", path: "plantillas" },
    { optionId: "8", option: "Tipos plantillas", path: "plantillasTipos" },
    { optionId: "9", option: "Usuarios", path: "usuarios" },
  ];

  const rutasReportes = [
    {
      optionId: "15",
      option: "Estudiantes matriculados",
      path: "estudiantesMatriculados",
    },
    {
      optionId: "16",
      option: "Estudiantes con asistencia",
      path: "estudiantesAsistencia",
    },
  ];
  const campos = [
    { optionId: "10", option: "Formas de conocernos", path: "formas" },
    { optionId: "11", option: "Períodos", path: "periodos" },
    { optionId: "12", option: "Tipos periodos", path: "tiposPeriodos" },
    { optionId: "13", option: "Razones", path: "razones" },
    {
      optionId: "14",
      option: "Tipos de financiamientos",
      path: "tiposFinanciamientos",
    },
    {
      optionId: "15",
      option: "Tipos de organizaciones",
      path: "tiposOrganizaciones",
    },
    { optionId: "16", option: "Lugares", path: "lugares" },
    {
      optionId: "17",
      option: "Tipos de Documentos",
      path: "perfilesDocumentosTipos",
    },
  ];

  return (
    <div className="sideBar">
      <div className="image-container">
        <img src={logoImg} className="logo" alt="Logo CENFOTEC"></img>
      </div>

      {menuPrincipal.map((op) => (
        <Link
          to={op.path}
          onClick={() => {
            hideSidebar();
          }}
        >
          <i className="material-icons">{op.icon}</i>
          <span>{op.option}</span>
        </Link>
      ))}

      <button className="btnConfig" onClick={optionReportesT}>
        <i className="material-icons"></i>Reportes
        <i className="material-icons showOptions" id="showReportesIcon">
          
        </i>
      </button>
      <div className="optionReportes">
        {rutasReportes.map((item, i) => {
          return (
            <Link
              key={i}
              to={item.path}
              onClick={() => {
                hideSidebar();
              }}
            >
              {item.option}
            </Link>
          );
        })}
      </div>
      {hasPermission && (
        <>
          <button className="btnConfig" onClick={optionMenuT}>
            <i className="material-icons"></i>Configuración
            <i className="material-icons showOptions" id="showConfigIcon">
              
            </i>
          </button>
          <div className="optionMenu">
            {optionArray.map((item, i) => {
              return (
                <Link
                  key={i}
                  to={item.path}
                  onClick={() => {
                    hideSidebar();
                  }}
                >
                  {item.option}
                </Link>
              );
            })}
            <button className="btnCampos" onClick={optionCamposT}>
              Campos{" "}
              <i className="material-icons showOptions" id="showCampoIcon">
                
              </i>
            </button>
            <div className="optionCampos">
              {campos.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={item.path}
                    onClick={() => {
                      hideSidebar();
                    }}
                  >
                    {item.option}
                  </Link>
                );
              })}
            </div>
          </div>
        </>
      )}

      <button
        id="btn-cerrar-sesion"
        className="invisibleCerrarSesion"
        onClick={() => {
          authService.logout(navigate);
        }}
      >
        Cerrar sesión<i className="material-icons"></i>
      </button>
    </div>
  );
}

export default SideBar;
