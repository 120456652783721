import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useDispatch } from "react-redux";
import { obtenerPersonasCentrosEducativos } from "../../redux/solicitudesSlice";

export default function SelectAsync({
  rules = {},
  control,
  name,
  paisId,
  valueDefault,
  centroEducativo,
  disabled = false,
  height = 34,
  tipo = 1,
  nivelId,
}) {
  const [defValue, setDefValue] = useState({});
  const dispatch = useDispatch();
  const costaRicaId = 192;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#fcfafa",
      borderColor: "#c1c1c1",
      boxShadow: state.isFocused ? null : null,
      borderRadius: 10,
      fontFamily: "circularBook",
      fontSize: "14px",
      height: height,
      minHeight: height,
    }),

    menu: (base) => ({
      ...base,
      fontFamily: "circularBook",
    }),
  };

  useEffect(() => {}, [defValue]);
  useEffect(() => {
    let el = {};

    if (typeof valueDefault === "object" && valueDefault != null) {
      el = valueDefault;
    } else if (!isNaN(parseInt(valueDefault)) && centroEducativo == null) {
      el = { label: valueDefault, value: valueDefault };
    } else {
      el = { label: centroEducativo, value: centroEducativo };
    }

    setDefValue(el);
    defaultOptions(el);
  }, [valueDefault, centroEducativo]);

  //valor por defecto
  const defaultOptions = (el) => {
    if (!(typeof el.value === "string")) {
      let data;
      let val = parseInt(el.value);
      if (isNaN(val)) {
        val = 0;
      }
      data = {
        paisId: paisId,
        tipoId: tipo,
        centroEducativo: "",
        centroEducativoId: val,
      };

      if (!paisId) return;

      dispatch(obtenerPersonasCentrosEducativos(data))
        .unwrap()
        .then((d) => {
          if (d) {
            if (!isNaN(el.value)) {
              let element = d?.find((a) => a.centroEducativoId == el.value);

              if (element) {
                setDefValue({
                  label: element.centroEducativoNombre,
                  value: element.centroEducativoId,
                });
              }
            }
          }
        });
    } else {
      if (paisId != costaRicaId || nivelId != 2) {
        setDefValue(el);
      }
    }
  };

  const loadOptions = async (inputValue, callback) => {
    let data;
    if (typeof valueDefault === "object" && valueDefault != null) {
      let val = parseInt(valueDefault.value);
      if (isNaN(val)) {
        val = 0;
      }
      data = {
        paisId: paisId,
        tipoId: tipo,
        centroEducativo: inputValue,
        centroEducativoId: val,
      };
    } else if (valueDefault) {
      data = {
        paisId: paisId,
        tipoId: tipo,
        centroEducativo: inputValue,
        centroEducativoId: valueDefault,
      };
    } else {
      data = {
        paisId: paisId,
        tipoId: tipo,
        centroEducativo: inputValue,
        centroEducativoId: 0,
      };
    }

    let { payload } = await dispatch(obtenerPersonasCentrosEducativos(data));
    if (payload) {
      let res = payload?.map((i) => ({
        label: i.centroEducativoNombre,
        value: i.centroEducativoId,
      }));
      callback(res);
    } else {
      if (paisId != costaRicaId || nivelId != 2) {
        callback([
          {
            label: inputValue,
            value: inputValue,
          },
        ]);
      }
    }
  };

  const {
    field: { onChange, ref },
  } = useController({
    name,
    control,
    rules: rules,
  });

  return (
    <AsyncSelect
      isDisabled={disabled}
      styles={customStyles}
      loadOptions={loadOptions}
      noOptionsMessage={() => "Sin resultados"}
      placeholder={"Buscar"}
      onChange={(e) => {
        setDefValue(e);
        onChange(e);
      }}
      value={defValue}
      name={name}
      ref={ref}
    />
  );
}
