import WebApi from '../Api/WebApi'
import { createSlice,createAsyncThunk  } from '@reduxjs/toolkit'

export const obtenerPlantillas = createAsyncThunk(
    "plantillas/obtenerPlantillas",
    async ( l,{ rejectWithValue }) => {
      try {
        const res = await WebApi.api().get('admin/plantillas')
        return res.data.result;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const obtenerPlantillasPorTipo = createAsyncThunk(
    "plantillas/obtenerPlantillasPorTipo",
    async ( id,{ rejectWithValue }) => {
      try {
        const res = await WebApi.api().get('admin/plantillas/?tipoId=' + id);
        return res.data.result;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const almacenarPlantilla = createAsyncThunk(
    "plantillas/CrearNuevaPlantilla",
    async (item, { rejectWithValue }) => {
      try {
        const res= await WebApi.api().post('admin/plantillas',item)
        return res.data;
      } catch (err) {
        return { success: false, message: err, show: true };
      }
    }
  );

  export const eliminarPlantilla = createAsyncThunk(
    "plantillas/RemoverPlantilla",
    async ( id,{ rejectWithValue }) => {
      try {
        const res = await WebApi.api().delete('admin/plantillas/' + id);
        return res.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export const actualizarPlantilla = createAsyncThunk(
    "plantillas/ModificarPlantilla",
    async (item, { rejectWithValue }) => {
      try {
        const res = await WebApi.api().put('admin/plantillas/' + item.plantillaId, item)
        return  res.data;
      } catch (err) {
        return { success: false, message: err, show: true };
      }
    }
  );


const plantillasSlice=createSlice({
    name:'plantillas',
    initialState:{
        plantillas:[],
        status:'',
        error:null,
        loading:false
    },
    reducers:{
    },
    extraReducers:{
        [obtenerPlantillas.pending]:(state,action)=>{
            state.loading=true;
        },
        [obtenerPlantillas.fulfilled]:(state,action)=>{
            state.loading=false;
            state.plantillas=action.payload;
        },
        [obtenerPlantillas.rejected]:(state,action)=>{
            state.loading=false;
            state.error=action.payload;
        },
        [obtenerPlantillasPorTipo.fulfilled]:(state,action)=>{
          state.loading=false;
          state.plantillas=action.payload;
      },
        
    }
})

export default plantillasSlice.reducer;

