import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  obtenerEstudiantesConAsistencia,
  exportarEstudiantesConAsistencia,
} from "../../../redux/solicitudesAdmSlice";
import { OrderTable } from "../../../Components/OrderTable";
import { ObtenerTodos } from "../../../redux/periodosSlice";

export default function EstudiantesConAsistencia(props) {
  const dispatch = useDispatch();
  const [periodoId, setPeriodoId] = useState(0);
  const [anio, setAnio] = useState(0);
  const [periodos, setPeriodos] = useState([]);
  const [EstudiantesConAsistencia, setEstudiantesConAsistencia] = useState([]);
  const columns = [
    { label: "EstudianteId", accessor: "estudianteId", sortable: true },
    { label: "Estudiante", accessor: "estudiante", sortable: true },
    { label: "Apoyo", accessor: "apoyoEstudiosNombre", sortable: true },
    { label: "Condición médica", accessor: "condicionMedica", sortable: true },
    { label: "Carrera", accessor: "carrera", sortable: true },
    { label: "Periodo", accessor: "periodo", sortable: true },
  ];

  const cambiarAnio = (e) => {
    let object = { anio: e.target.value, periodoNombre: null };
    cargarPeriodosPorAnio(object);
    setAnio(e.target.value);
  };

  const cargarPeriodosPorAnio = (object) => {
    dispatch(ObtenerTodos(object)).then((d) => {
      if (d?.payload?.length > 0) setPeriodos(d.payload);
    });
  };

  const cambiarPeriodo = (e) => {
    setPeriodoId(e.target.value);
  };

  const exportarSolicitud = async () => {
    await dispatch(exportarEstudiantesConAsistencia());
  };

  useEffect(() => {
    // if (periodoId && anio)
      dispatch(obtenerEstudiantesConAsistencia({ periodoId, anio })).then(
        (d) => {
          setEstudiantesConAsistencia(d.payload);
        }
      );
  }, [periodoId, anio]);

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <h2>Estudiantes con asistencia</h2>
        <div className="select-filter-section-ml-auto"></div>
        <div className="header-admin-bar-items ml-auto">
          <label>Año</label>
          <select onChange={cambiarAnio}>
            <option value={0}>Mostrar todos</option>
            {new Array(40).fill(0).map((b, i) => (
              <option key={i} value={new Date().getFullYear() - i}>
                {new Date().getFullYear() - i}
              </option>
            ))}
          </select>
          <label>Periodo</label>

          <select onChange={cambiarPeriodo}>
            <option value={0}>Mostrar todos</option>

            {periodos?.map((item, i) => (
              <option key={i} value={item.periodoId}>
                {item.periodoNombre}
              </option>
            ))}
          </select>
        </div>

        <button
          className="search-button"
          title="Exportar a excel"
          onClick={exportarSolicitud}
        >
          <i className="material-icons-outlined"></i>
        </button>
      </div>

      <div className="table-container">
        {EstudiantesConAsistencia?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={EstudiantesConAsistencia}
              clase="table-adm"
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>
    </main>
  );
}
