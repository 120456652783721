import Modal from "./Modal";
import { useForm, useFieldArray } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import ModalRemove from "./ModalRemove";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerPerfiles,
  eliminarPerfil,
  actualizarPerfil,
  almacenarPerfil,
} from "../../redux/perfilSlice";
import { obtenerDocumentos } from "../../redux/genericosSlice";
import { toast } from "react-toastify";
import WindowDimension from "../../Hooks/WindowDimension";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "perfilId", sortable: true },
  { label: "Perfil", accessor: "perfilNombre", sortable: true },
  {
    label: "Documentos requeridos",
    accessor: "nombreCompleto",
    sortable: false,
    render: (item) => (
      <td>
        <ul>
          {item.documentos.map((p, i) => (
            <li key={i}>{p.documentoNombre}</li>
          ))}
        </ul>
      </td>
    ),
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function ListaDocumentos(props) {
  const [modalState, changeModalState] = useState(false);
  const [removeState, changeRemoveState] = useState(false);
  const [registerSelected = [], selectReg] = useState();
  const [grupos = [], setGrupos] = useState();
  const [tituloModal, setTituloModal] = useState("Agregar perfil");

  const { width } = WindowDimension();
  const dispatch = useDispatch();
  let busqueda = useRef();

  const { perfiles } = useSelector((state) => state.perfiles);
  const { documentos } = useSelector((state) => state.genericos);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      requiereVerificacionSicobatec: false,
      perfilNombre: "",
      perfilId: 0,
      documentos: [{ documentoId: undefined, esObligatorio: false }],
    },
  });

  const { submitAction } = useSubmit();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "documentos",
  });

  const showModalEdit = (reg) => {
    reset(reg);
    setTituloModal("Editar perfil");

    changeModalState(true);
    selectReg(reg);
  };

  const closeModalEdit = () => {
    reset({ perfilId: 0, perfilNombre: "", documentos: [] });
    changeModalState(false);
  };

  const showRemoveModalEdit = (reg) => {
    selectReg(reg);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    selectReg();
    changeRemoveState(false);
  };

  const cargarPerfiles = () => {
    dispatch(obtenerPerfiles());
  };

  const cargarPerfilesPorCaracter = () => {
    dispatch(obtenerPerfiles(busqueda.current.value));
  };

  const eliminar = async () => {
    submitAction(eliminarPerfil, registerSelected.perfilId, () => {
      closeRemoveModalEdit();
      cargarPerfiles();
    });
  };

  const actualizar = async (data) => {
    data.documentos.forEach((el) => {
      if (!el.grupo) {
        el.grupo = undefined;
      }
    });

    if (data.documentos.length === 0) {
      toast.error("Debe seleccionar algún documento");
      return false;
    }

    submitAction(
      data.perfilId > 0 ? actualizarPerfil : almacenarPerfil,
      data,
      () => {
        closeModalEdit();
        cargarPerfiles();
      }
    );
  };

  useEffect(() => {
    cargarPerfiles();
    dispatch(obtenerDocumentos());

    let array = [];
    for (let index = 1; index <= 10; index++) {
      array.push(index);
    }
    setGrupos(array);
  }, [dispatch]);

  useEffect(() => {
    if (perfiles) {
      setValue(
        "requiereVerificacionSicobatec",
        perfiles.requiereVerificacionSicobatec
      );
      setValue("documentos", perfiles.documentos);
      setValue("perfilId", perfiles.perfilId);
      setValue("perfilNombre", perfiles.perfilNombre);
    }
  }, [perfiles]);

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <button
          className="main-admin-button"
          onClick={() => {
            changeModalState(true);
            setTituloModal("Nuevo Perfil");
            reset();
          }}
        >
          +
        </button>
        <h2>Definición de perfiles</h2>
        <div className="header-admin-bar-items ml-auto">
          <input
            type="search"
            className="form-control"
            placeholder="Búsqueda"
            onInput={cargarPerfilesPorCaracter}
            ref={busqueda}
          />
        </div>
      </div>

      <div className="table-container">
        {perfiles.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={perfiles}
              clase="table-adm"
              actions={[
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showModalEdit(item),
                },
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showRemoveModalEdit(item),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={modalState}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "medium"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(actualizar)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="grid-container col-2">
            <div className="form-group mr-2">
              <label>Nombre del perfil</label>
              <Required />
              <input
                className="form-control"
                type="text"
                {...register("perfilNombre", { required: "Campo requerido" })}
              ></input>
              {errors.perfilNombre && (
                <p className="error">{errors.perfilNombre.message}</p>
              )}
            </div>
            <div className="align-self-center">
              <div className="d-flex">
                <label>¿Requiere verificación en Sicobatec?</label>
                <input
                  {...register("requiereVerificacionSicobatec")}
                  type="checkbox"
                  className="form-control-check d-flex"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Documentos:</label>
            <Required />

            <ul>
              {fields.map((item, index) => {
                return (
                  <li key={"el_" + index}>
                    <div className="form-group flex-form gap-20">
                      <select
                        className="form-control"
                        name={`documentos.${index}.documentoId`}
                        control={control}
                        {...register(`documentos.${index}.documentoId`, {
                          required: "Campo requerido",
                        })}
                      >
                        <option value="">Seleccione</option>
                        {documentos?.map((d, i) => (
                          <option key={d.documentoId} value={d.documentoId}>
                            {d.documentoNombre}
                          </option>
                        ))}
                      </select>
                      <div className="d-flex">
                        <label>¿Es obligatorio?</label>
                        <input
                          name={`documentos.${index}.esObligatorio`}
                          type="checkbox"
                          className="form-control-check d-flex"
                          {...register(`documentos.${index}.esObligatorio`, {
                            required: false,
                          })}
                        />
                      </div>

                      <select
                        className="form-control"
                        control={control}
                        {...register(`documentos.${index}.grupo`)}
                      >
                        <option value="">Sin grupo</option>
                        {grupos?.map((d, i) => (
                          <option key={d} value={d}>
                            Grupo {d}
                          </option>
                        ))}
                      </select>

                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="modBtn"
                      >
                        <i className="material-icons"></i>
                      </button>
                    </div>
                    {errors.documentos?.[index]?.documentoId && (
                      <p className="error">
                        {errors.documentos?.[index]?.documentoId.message}
                      </p>
                    )}
                  </li>
                );
              })}
            </ul>
            <div>
              <button
                type="button"
                className="button-text"
                onClick={() => {
                  append({
                    documentoId: "",
                    esObligatorio: false,
                  });
                }}
              >
                Agregar documento
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={changeRemoveState}
        title="Eliminar perfil"
        message="¿Seguro que desea eliminar el siguiente perfil?"
        item={registerSelected.perfil}
        icon={null}
        btnGroup={
          <>
            <button className="button-text text-red" onClick={eliminar}>
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default ListaDocumentos;
