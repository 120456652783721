import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerCentrosEducativos,
  actualizarCentrosEducativos,
  agregarCentrosEducativos,
  obtenerInfoGeografica,
  eliminarCentrosEducativos,
} from "../../redux/centrosEducativosSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import LoadingSpiner from "../../Components/LoadingSpiner";
import ReactSelect from "../../Components/ReactSelect";
import ModalRemove from "./ModalRemove";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "centroEducativoId", sortable: true },
  { label: "Nombre", accessor: "centroEducativoNombre", sortable: true },
  { label: "País", accessor: "paisNombre", sortable: true },
  { label: "Provincia", accessor: "provinciaNombre", sortable: true },
  { label: "Cantón", accessor: "cantonNombre", sortable: true },
  { label: "Distrito", accessor: "distritoNombre", sortable: true },
  {
    label: "Tipo de organización",
    accessor: "tipoOrganizacionNombre",
    sortable: true,
  },
  { label: "Dirección", accessor: "direccion", sortable: true },
  { label: "Acción", accessor: "action", sortable: false },
];

const paisId = 192;

function CentrosEducativos(props) {
  const { width } = WindowDimension();
  const [showModal, setShowModal] = useState(false);
  const [listaProvincia, setListaProvincia] = useState([]);
  const [listaDistrito, setListaDistrito] = useState([]);
  const [listaCantones, setListaCantones] = useState([]);
  const [listaTiposOrganizaciones, setListaTiposOrganizaciones] = useState([]);
  const [listaTipos, setListaTipos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [cantones, setCantones] = useState([]);
  const [tituloModal, setTituloModal] = useState("");
  const [cantonId, setCantonId] = useState(0);
  const [provinciaId, setProvinciaId] = useState(0);
  const [removeState, changeRemoveState] = useState(false);
  const [itemSelected, setItemSelected] = useState({});

  const { submitAction } = useSubmit();

  const dispatch = useDispatch();
  const { centrosEducativos, loading, model } = useSelector(
    (state) => state.centrosEducativos
  );
  let busqueda = useRef();

  useEffect(() => {
    cargarDatos();
    dispatch(obtenerInfoGeografica());
  }, [dispatch]);

  useEffect(() => {
    let b = model?.provincias?.map((item, i) => {
      return { label: item.provinciaNombre, value: item.provinciaId };
    });
    setListaProvincia(b);

    let c = model?.cantones?.map((item, i) => {
      return { label: item.cantonNombre, value: item.cantonId };
    });
    setListaCantones(c);
    let d = model?.distritos?.map((item, i) => {
      return { label: item.distritoNombre, value: item.distritoId };
    });
    setListaDistrito(d);
    let e = model?.tiposOrganizaciones?.map((item, i) => {
      return {
        label: item.tipoOrganizacionNombre,
        value: item.tipoOrganizacionId,
      };
    });
    setListaTiposOrganizaciones(e);
    let f = model?.centroEducativoTipos?.map((item, i) => {
      return { label: item.tipoNombre, value: item.tipoId };
    });
    setListaTipos(f);

    setProvincias(model.provincias);
    setCantones(model.cantones);
  }, [model]);

  const cargarDatos = () => {
    let obj = {
      q: "",
      paisId: paisId,
      provinciaId: provinciaId,
      cantonId: cantonId,
      centroEducativoId: 0,
    };
    dispatch(obtenerCentrosEducativos(obj));
  };

  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      centroEducativoId: 0,
      centroEducativoNombre: "",
      paisId: undefined,
      provinciaId: undefined,
      cantonId: undefined,
      distritoId: undefined,
      tipoOrganizacionId: undefined,
      poblado: "",
      zona: "",
      telefono: "",
      direccion: "",
      circuito: "",
    },
  });

  const mProvinciaId = watch("provinciaId");
  const mCantonId = watch("cantonId");

  useEffect(() => {
    let c = model?.cantones
      ?.filter((a) => a.provinciaId == mProvinciaId)
      ?.map((item, i) => {
        return { label: item.cantonNombre, value: item.cantonId };
      });
    setListaCantones(c);
  }, [mProvinciaId]);
  useEffect(() => {
    let c = model?.distritos
      ?.filter((a) => a.cantonId == mCantonId)
      ?.map((item, i) => {
        return { label: item.distritoNombre, value: item.distritoId };
      });
    setListaDistrito(c);
  }, [mCantonId]);

  const cargarCantonesPorProvincia = (e) => {
    let id = e.target.value;
    setProvinciaId(id);
    let cantones = model?.cantones.filter((a) => a.provinciaId == id);
    setCantones(cantones);
    let obj = {
      q: busqueda.current.value,
      paisId: paisId,
      provinciaId: id,
      cantonId: cantonId,
      centroEducativoId: 0,
    };
    dispatch(obtenerCentrosEducativos(obj));
  };
  const cargarCentrosEducativos = (e) => {
    let id = e.target.value;
    setCantonId(id);
    let obj = {
      q: busqueda.current.value,
      paisId: paisId,
      provinciaId: provinciaId,
      cantonId: id,
      centroEducativoId: 0,
    };
    dispatch(obtenerCentrosEducativos(obj));
  };
  const cargarCarrerasBusqueda = () => {
    let obj = {
      q: busqueda.current.value,
      paisId: paisId,
      provinciaId: provinciaId,
      cantonId: cantonId,
      centroEducativoId: 0,
    };
    dispatch(obtenerCentrosEducativos(obj));
  };
  const showModalEdit = (item) => {
    reset(item);
    setShowModal(true);
  };
  const closeModalEdit = () => {
    reset({
      centroEducativoId: 0,
      centroEducativoNombre: "",
      paisId: undefined,
      provinciaId: undefined,
      cantonId: undefined,
      distritoId: undefined,
      tipoOrganizacionId: undefined,
      poblado: "",
      zona: "",
      telefono: "",
      direccion: "",
      circuito: "",
    });
    setShowModal(false);
  };
  const showRemoveModalEdit = (item) => {
    setItemSelected(item);
    changeRemoveState(true);
  };
  const closeRemoveModalEdit = () => {
    setItemSelected({});
    changeRemoveState(false);
  };

  const onSubmit = async (data) => {
    data.paisId = paisId;

    submitAction(
      data.centroEducativoId > 0
        ? actualizarCentrosEducativos
        : agregarCentrosEducativos,
      data.centroEducativoId > 0
        ? { item: data, id: data.centroEducativoId }
        : data,
      () => {
        cargarDatos();
        closeModalEdit();
      }
    );
  };
  const eliminar = async () => {
    submitAction(
      eliminarCentrosEducativos,
      itemSelected.centroEducativoId,
      () => {
        closeRemoveModalEdit();
        cargarDatos();
      }
    );
  };

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <button
          className="main-admin-button"
          onClick={() => {
            setShowModal(true);
            setTituloModal("Agregar");
            reset();
          }}
        >
          +
        </button>

        <h2>Centros educativos</h2>
        <div className="header-admin-bar-items ml-auto">
          <label hidden>provincias</label>
          <select onChange={cargarCantonesPorProvincia}>
            <option value="0">Todas las provincias</option>
            {provincias?.map((item, i) => {
              return (
                <option key={i} value={item.provinciaId}>
                  {item.provinciaNombre}
                </option>
              );
            })}
          </select>
          <label hidden>Cantones</label>
          <select onChange={cargarCentrosEducativos}>
            <option value="0">Todos los cantones</option>
            {cantones?.map((item, i) => {
              return (
                <option key={i} value={item.cantonId}>
                  {item.cantonNombre}
                </option>
              );
            })}
          </select>

          <label hidden>Búsqueda</label>
          <input
            className="centro-search"
            type="search"
            placeholder="Búsqueda"
            onInput={cargarCarrerasBusqueda}
            ref={busqueda}
          />
        </div>
      </div>

      <div className="table-container">
        {loading ? (
          <div className="align-spinner">
            <LoadingSpiner
              styles={{
                width: "45px",
                height: "45px",
                border: "6px solid #bbbbbb",
                borderTop: "6px solid #035cc9",
              }}
            />
          </div>
        ) : centrosEducativos?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={centrosEducativos}
              clase="table-adm"
              actions={[
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showModalEdit(item),
                },
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showRemoveModalEdit(item),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "medium"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="grid-container col-4 col-lg-2 col-sm-12">
            <div className="grid-item">
              <div className="form-group">
                <label>Nombre</label>
                <Required />
                <input
                  {...register("centroEducativoNombre", {
                    required: "Campo requerido",
                  })}
                  className="form-control"
                  type="text"
                />

                {errors.paisId && (
                  <p className="error">{errors.paisId.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Provincia</label>
                <Required />
                <ReactSelect
                  name="provinciaId"
                  control={control}
                  lista={listaProvincia}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.provinciaId && (
                  <p className="error">{errors.provinciaId.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Cantón</label>
                <Required />
                <ReactSelect
                  name="cantonId"
                  control={control}
                  lista={listaCantones}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.cantonId && (
                  <p className="error">{errors.cantonId.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Distrito</label>
                <Required />
                <ReactSelect
                  name="distritoId"
                  control={control}
                  lista={listaDistrito}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.distritoId && (
                  <p className="error">{errors.distritoId.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Tipo organización</label>
                <Required />
                <ReactSelect
                  name="tipoOrganizacionId"
                  control={control}
                  lista={listaTiposOrganizaciones}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.tipoOrganizacionId && (
                  <p className="error">{errors.tipoOrganizacionId.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Nivel</label>
                <Required />
                <ReactSelect
                  name="tipoId"
                  control={control}
                  lista={listaTipos}
                  rules={{ required: "Campo requerido" }}
                />
                {errors.tipoId && (
                  <p className="error">{errors.tipoId.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Poblado</label>
                <Required />
                <input
                  {...register("poblado", { required: "Campo requerido" })}
                  className="form-control"
                  type="text"
                />
                {errors.poblado && (
                  <p className="error">{errors.poblado.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Zona</label>
                <Required />
                <input
                  {...register("zona", { required: "Campo requerido" })}
                  className="form-control"
                  type="text"
                />
                {errors.zona && <p className="error">{errors.zona.message}</p>}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Teléfono</label>
                <Required />
                <input
                  {...register("telefono", { required: "Campo requerido" })}
                  className="form-control"
                  type="text"
                />
                {errors.telefono && (
                  <p className="error">{errors.telefono.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Dirección</label>
                <Required />
                <input
                  {...register("direccion", { required: "Campo requerido" })}
                  className="form-control"
                  type="text"
                />
                {errors.direccion && (
                  <p className="error">{errors.direccion.message}</p>
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="form-group">
                <label>Circuito</label>
                <Required />
                <input
                  {...register("circuito", { required: "Campo requerido" })}
                  className="form-control"
                  type="text"
                />
                {errors.circuito && (
                  <p className="error">{errors.circuito.message}</p>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={closeRemoveModalEdit}
        title="Eliminar centro educativo"
        message="¿Seguro que desea eliminar el siguiente registro?"
        item={itemSelected.centroEducativoNombre}
        btnGroup={
          <>
            <button className="button-text text-red" onClick={eliminar}>
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default CentrosEducativos;
