import React from 'react'

function Required() {
  return (
    <abbr title="Campo obligatorio" className="campo-obligatorio">
    *
  </abbr>
  )
}

export default Required