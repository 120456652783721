import WebApi from '../Api/WebApi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const obtenerTiposPlantillas = createAsyncThunk(
  'plantillas/obtenerTiposPlantillas',
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('admin/PlantillasTipos');
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerRolesPlantillas = createAsyncThunk(
  'plantillas/obtenerRolesPlantillas',
  async (l, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('admin/plantillas/roles');
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const actualizarTipoPlantilla = createAsyncThunk(
  'plantillas/actualizarTipoPlantilla',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/PlantillasTipos/' + item.tipoPlantillaId,
        item
      );
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

const plantillasSlice = createSlice({
  name: 'tiposPlantillas',
  initialState: {
    tiposPlantillas: [],
    roles: [],
    status: '',
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [obtenerTiposPlantillas.pending]: (state, action) => {
      state.loading = true;
    },
    [obtenerTiposPlantillas.fulfilled]: (state, action) => {
      state.loading = false;
      state.tiposPlantillas = action.payload;
    },
    [obtenerRolesPlantillas.fulfilled]: (state, action) => {
      state.roles = action.payload;
    },
    [obtenerTiposPlantillas.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default plantillasSlice.reducer;
