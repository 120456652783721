const TableBody = ({ tableData, columns, actions = [], renderActionItems }) => {
  return (
    <tbody>
      {tableData?.map((data, index) => {
        return (
          <tr key={Math.random()}>
            {columns.map((col, i) => {
              const { accessor, render } = col;
              const tData = data[accessor] ? data[accessor] : "";
              return render !== undefined ? (
                render(data)
              ) : accessor !== "action" ? (
                <td key={`${accessor} ${Math.random()}`}>{tData}</td>
              ) : renderActionItems ? (
                renderActionItems(data)
              ) : (
                actions?.length > 0 && (
                  <td key={i + Math.random()}>
                    {actions.map((el, j) => {
                      return (
                        <button
                          key={j}
                          style={el.style}
                          className={`modBtn ${el.clase}`}
                          onClick={() => {
                            el.event(data);
                          }}
                        >
                          <i
                            className={`material-icons ${el.clase && el.clase}`}
                          >
                            {el.icon}
                          </i>
                          {el.text}
                        </button>
                      );
                    })}
                  </td>
                )
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
