
import "../Adm/Styles/modal.css";
import infoIMG from '../SVG/succesblue.svg'

function SuccessModal(props) {

    return (
        <div className={props.estado ? "overlay active" : "overlay"}>
            <div className={`modalContainer ${props.clase != undefined ? props.clase : 'small'}`} >
                <div className="modal-body">
                    <div className="relative-container">
                        <div className="modal-imagecontainer bounce-5">
                            <img src={infoIMG} alt="Success" />
                        </div>
                    </div>

                    <div>
                        <span className="body-title">
                            {props.titulo}
                        </span>
                        <span className="body-text">
                            {props.texto}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SuccessModal;

