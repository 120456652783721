import React, { useEffect, useState } from "react";
import "../css/registro.css";
import AppBar from "../Components/AppBar";
import ImagenReg from "../../SVG/RegistroCenfotec.svg";
import LoadingSpiner from "../../Components/LoadingSpiner";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  ObtenerDatosRegistro,
  VerificarPersonaPadron,
  verificarCorreo,
  verificarTelefono,
} from "../../redux/usuariosSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import autService from "../../services/authService";
import ReactSelect from "../../Components/ReactSelect";
import OperationStatus from "../../config/OperationStatus";
import WebApi from "../../Api/WebApi";
import Required from "../../Components/Required";

export default function Registro() {
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dni: "",
      tipoDniId: "",
      nombres: "",
      alias: "",
      primerApellido: "",
      segundoApellido: "",
      contrasena: "",
      confirmarContrasenia: "",
      telefonoPrefijoId: 195,
      telefonoSufijo: "",
      correoElectronico: "",
      fechaNacimiento: "",
      consultadoEnPadron: false,
      paisProcedenciaId: null,
    },
  });
  const { registroModel } = useSelector((state) => state.usuarios);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tipoDniId = watch("tipoDniId", "");
  const dni = watch("dni", "");
  const fechaNacimiento = watch("fechaNacimiento", "");
  const contrasena = watch("contrasena");
  const [lista, setLista] = useState([]);
  const [paisProcedencia, setListaPaisProcedencia] = useState([]);

  useEffect(() => {
    dispatch(ObtenerDatosRegistro());
  }, [dispatch]);


  useEffect(() => {
    setValue("dni",dni.replace(/ /g,''));
  }, [dni]);

  useEffect(() => {
    if (tipoDniId == 1 || tipoDniId == "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [tipoDniId]);

  useEffect(() => {
    if (registroModel.paisesPrefijos) {
      let a = registroModel?.paisesPrefijos?.map((item, i) => {
        return {
          label: item.codigoArea + " " + item.paisNombre,
          value: item.id,
        };
      });
      setLista(a);
      let b = registroModel?.paises?.map((item, i) => {
        return { label: item.paisNombre, value: item.paisId };
      });
      setListaPaisProcedencia(b);
    }
  }, [registroModel.paisesPrefijos]);

  const onSubmit = async (data) => {
    let o = {
      dni: data.dni,
      tipoDniId: tipoDniId,
      nombres: data.nombres,
      alias: data.alias,
      primerApellido: data.primerApellido,
      segundoApellido: data.segundoApellido,
      telefonoSufijo: data.telefonoSufijo,
      telefonoPrefijoId: data.telefonoPrefijoId,
      correoElectronico: data.correoElectronico,
      contrasena: data.contrasena,
      fechaNacimiento: data.fechaNacimiento,
      generoId: data.generoId,
      fotografiaId: null,
      consultadoEnPadron: data.consultadoEnPadron,
      paisProcedenciaId: data.paisProcedenciaId,
    };

    let result = await autService.register(o, dispatch);
    if (result.operationStatus === OperationStatus.SUCCESS) {
      let res = await autService.login(
        { correo: data.correoElectronico, contrasena: data.contrasena },
        dispatch
      );

      localStorage.setItem("token", res.result.token);
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(res.result.refreshToken)
      );

      WebApi.setAuthToken(res.result.token);

      dispatch(verificarCorreo());
      dispatch(verificarTelefono());

      toast.success(result.message);
      navigate("/Verificar");
    } else {
      toast.error(result.message);
    }
  };

  const verificarPersona = async (a) => {
    a.preventDefault();
    var hasValue = await trigger(["dni", "tipoDniId"]);

    if (!hasValue) {
      return;
    }
    setLoading(true);
    let { payload } = await dispatch(VerificarPersonaPadron(dni));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      let p = payload.result;
      setValue("nombres", p.nombres);
      setValue("alias", p.alias);
      setValue("dni", p.cedula);
      setValue("primerApellido", p.primerApellido);
      setValue("segundoApellido", p.segundoApellido);
      setValue("fechaNacimiento", p.fechaNacimiento);
      setValue("consultadoEnPadron", true);
      setValue("paisProcedenciaId", 192);

      setLoading(false);
      setIsDisabled(true);
    } else {
      setLoading(false);
      toast.error(payload.message);
      setIsDisabled(false);
    }
  };
  const passwordVerify = (e) => {
    return contrasena === e;
  };

  return (
    <div className="rootRegistro">
      <AppBar />
      <div className="contentRegistro">
        <div className="grid-container col-2 col-md col-sm-12 ">
          <div className="grid-item justify-self-center">
            <h1>CENFOTEC Admisiones</h1>
            <img
              className="imgRegistro"
              src={ImagenReg}
              alt="Imagen de Registro"
            />
          </div>

          <div className="grid-item form-container justify-self-center">
            <h2>Registro</h2>
            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex flex-gap-20">
                <div className="form-group">
                  <label>Tipo de documento de identificación</label>
                  <Required />

                  <select
                    className="form-control"
                    {...register("tipoDniId", { required: "Campo requerido" })}
                  >
                    <option value="">Seleccione</option>
                    {registroModel?.tiposDni?.map((item, i) => {
                      return (
                        <option key={i} value={item.tipoDniId}>
                          {item.tipoDniNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.tipoDniId && (
                    <p className="error">{errors.tipoDniId.message}</p>
                  )}
                </div>

                <div className="form-group">
                  <label>No. de documento</label>
                  <Required />

                  <div className="registerFlex">
                    <div>
                      <input
                        id="inputCed"
                        {...register("dni", { required: "Campo requerido" })}
                        type="text"
                      ></input>
                    </div>
                    {tipoDniId == 1 && (
                      <>
                        <button id="btnConfirm" onClick={verificarPersona}>
                          Confirmar
                        </button>
                        {loading && (
                          <div className="ml-1">
                            <LoadingSpiner
                              styles={{
                                width: "25px",
                                height: "25px",
                                border: "6px solid #bbbbbb",
                                borderTop: "6px solid #035cc9",
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {errors.dni && <p className="error">{errors.dni.message}</p>}
                </div>
              </div>

              <div className="d-flex flex-gap-20">
                <div className="form-group">
                  <label>Nombre(s)</label>
                  <Required />
                  <input
                    className="form-control"
                    type="text"
                    disabled={isDisabled}
                    {...register("nombres", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.nombres && (
                    <p className="error">{errors.nombres.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Conocido como</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={isDisabled}
                    {...register("alias")}
                  />
                  {errors.alias && (
                    <p className="error">{errors.alias.message}</p>
                  )}
                </div>
              </div>
              <div className="d-flex flex-gap-20">
                <div className="form-group">
                  <label>Primer apellido</label>
                  <Required />
                  <input
                    className="form-control"
                    type="text"
                    disabled={isDisabled}
                    {...register("primerApellido", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.primerApellido && (
                    <p className="error">{errors.primerApellido.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Segundo apellido</label>
                  <Required />
                  <input
                    className="form-control"
                    type="text"
                    disabled={isDisabled}
                    {...register("segundoApellido", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.segundoApellido && (
                    <p className="error">{errors.segundoApellido.message}</p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Pais procedencia</label>
                <Required />
                <ReactSelect
                  name="paisProcedenciaId"
                  control={control}
                  lista={paisProcedencia}
                  rules={{ required: "Campo requerido" }}
                  disabled={isDisabled}
                />
                {errors.paisProcedenciaId && (
                  <p className="error">{errors.paisProcedenciaId.message}</p>
                )}
              </div>
              <div className="d-flex flex-gap-20">
                <div className="form-group">
                  <label>Fecha de nacimiento</label>
                  <Required />
                  <input
                    className="form-control"
                    type="date"
                    disabled={isDisabled && fechaNacimiento != null}
                    {...register("fechaNacimiento", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.fechaNacimiento && (
                    <p className="error">{errors.fechaNacimiento.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Género</label>
                  <Required />

                  <select
                    className="form-control"
                    {...register("generoId", { required: "Campo requerido" })}
                  >
                    <option value="">Seleccione</option>
                    {registroModel?.generos?.map((item, i) => {
                      return (
                        <option key={i} value={item.generoId}>
                          {item.generoNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.generoId && (
                    <p className="error">{errors.generoId.message}</p>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label>Correo electrónico</label>
                <Required />

                <input
                  className="form-control"
                  autoComplete="email"
                  type="email"
                  {...register("correoElectronico", {
                    required: "Campo requerido",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Escriba un correoElectronico valido",
                    },
                  })}
                />
                {errors.correoElectronico && (
                  <p className="error">{errors.correoElectronico.message}</p>
                )}
              </div>

              <div className="d-flex flex-gap-20">
                <div className="form-group">
                  <label>Contraseña</label>
                  <Required />
                  <input
                    className="form-control"
                    autoComplete="new-password"
                    type="password"
                    {...register("contrasena", { required: "Campo requerido" })}
                  />
                  {errors.contrasena && (
                    <p className="error">{errors.contrasena.message}</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Confirmar contraseña</label>
                  <Required />

                  <input
                    className="form-control"
                    type="password"
                    autoComplete="new-password"
                    {...register("confirmarContrasenia", {
                      required: "Campo requerido",
                      validate: passwordVerify,
                    })}
                  />
                  {errors.confirmarContrasenia && (
                    <p className="error">
                      {errors.confirmarContrasenia.message}
                    </p>
                  )}
                  {errors?.confirmarContrasenia?.type === "validate" && (
                    <p className="error">Las contraseñas no coinciden</p>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label>Teléfono móvil</label>
                <Required />

                <div className="d-flex flex-gap-20">
                  <div className="select-container">
                    <ReactSelect
                      name="telefonoPrefijoId"
                      control={control}
                      lista={lista}
                      rules={{ required: "Campo requerido" }}
                    />
                  </div>
                  <input
                    id="inputTel"
                    className="form-control"
                    type="text"
                    {...register("telefonoSufijo", {
                      required: "Campo requerido",
                    })}
                  />
                </div>

                {errors.telefonoPrefijoId && (
                  <p className="error">{errors.telefonoPrefijoId.message}</p>
                )}
                {errors.telefonoSufijo && (
                  <p className="error">{errors.telefonoSufijo.message}</p>
                )}
              </div>
              <input type="submit" id="btnRegistro" value="Registrarse" />
            </form>
            <h5>
              ¿Ya tienes una cuenta? <Link to="/Login">Ingresa</Link>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
