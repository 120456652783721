import { useState, useEffect } from "react";

const GoogleChartContainer = ({ children }) => {
  const [google, setGoogle] = useState(null);

  useEffect(() => {
    const head = document.head;
    let script = document.createElement("script");
    script.id = "googleChartScript";
    script.src = "https://www.gstatic.com/charts/loader.js";
    script.onload = () => {
      window.google.charts.load("current", { packages: ["corechart"] });
      window.google.charts.setOnLoadCallback(() => setGoogle(window.google));
    };
    head.appendChild(script);
    return () => {
      let script = document.getElementById("googleChartScript");
      if (script) {
        script.remove();
      }
    };
  }, []);

  return children(google)
};

export default GoogleChartContainer;
