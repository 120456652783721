import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalNotificaciones from "./ModalNotificaciones";
import NavLinks from './NavLinks'
import authService from '../../services/authService'
import { useDispatch, useSelector } from "react-redux";
//import DefaultImg from '../../SVG/default.svg'
import DefaultImg from '../../assets/NoPhoto.png'

import {  obtenerNotificacionesUsuario } from '../../redux/usuariosSlice'


export default function UserInfo({ usuario }) {
  const user = usuario;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {  notificaciones } = useSelector((state) => state.usuarios);
  const [notifState, changeNotifState] = useState(false);
  const [image, setImage] = useState("");


    useEffect(() => {
      dispatch(obtenerNotificacionesUsuario());
    }, [dispatch])

  useEffect(() => {
    if (user.usuarioId) {
      if (user.foto) {
        setImage(`data:image/jpg;base64, ${user.foto}`);
      } else {
        setImage(DefaultImg);
      }
    }


  }, [user])

  const clickedButton = () => {
    const profile = document.getElementsByClassName('menuPerfil')[0];
    const toggle = document.querySelector('.toggleBtn');
    const closeBar = document.querySelectorAll('.toggleBtn');

    closeBar.forEach(el => {
      el.classList.toggle('invisibleBtn');
    });

    profile.classList.toggle('Active');
    toggle.classList.toggle('toggleBlue');
  }

  return (
    <>

      <div className="menuPerfil">
        <div>
          <ModalNotificaciones
            estado={notifState}
            cambiarEstado={changeNotifState}
            notificaciones={notificaciones}
          />
          <button
            className="btnNotificacion"
            onClick={() => { changeNotifState(true); }}
          >
            <i className="material-icons"></i>
          </button>
          <button
            className="btnNotificacion"
            onClick={() => { clickedButton(); navigate("/configuracion") }}
          >
            <i className="material-icons"></i>
          </button>
          <button
            className="btnNotificacion"
            onClick={() => { clickedButton(); authService.logout(navigate) }}
          >
            <i className="material-icons"></i>
          </button>
        </div>
        <div>
          <img
            id="profileImg"
            className="imagen-cuadrada"
            src={image}
            alt="Foto de perfil"
          />
          <h2>{user.nombres} {user.primerApellido} {user.segundoApellido}</h2>
          <div className="profileInfo">
            <hr />
            <div>
              <label>              <i className="material-icons"></i>

                {user.dni}</label>
            </div>
            <div>

              <label>
                <i className="material-icons"></i>{user.correoElectronico}</label>
            </div>
            <div>
              <label><i className="material-icons"></i>{user.telefono}</label>
            </div>
          </div>
          <button id="btnEdit" onClick={() => {
            clickedButton();
            navigate('userInfo');
          }}>
            Editar Información
          </button>

          <div className="sidebar-ul">
            <NavLinks />
          </div>
        </div>
      </div>
    </>
  );
}
