
import "../Styles/modal.css";

function Modal(props) {

  return (
    <div className={props.estado ? "overlay active" : "overlay"}>
      <div className={`modalContainer ${props.clase != undefined ? props.clase : 'small'}`} >
        <div className="modalHeader">
          <span className="header-title">{props.titulo}</span>
          <button
            onClick={props.cambiarEstado}
            className="btnCloseModal"
          >
            <i className='material-icons'></i>
          </button>
        </div>
        <div className={`modal-body ${props.minHeight != undefined ? 'min-height' : ''}`} >
          {props.children}
        </div>
        <div className="modal-actions">
          {props.buttonGroup}
        </div>
      </div>
    </div>
  );
}

export default Modal;

