import React, { useState, useEffect } from "react";
import infoIMG from "../../SVG/InformacionFinancieraCenfotec.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerSolicitudInfoFinanciera,
  agregarSolicitudInfoFinanciera,
  obtenerProvinciasByPaisId,
  obtenerCantonesByProvinciaId,
  obtenerDistritosByProvinciaId,
} from "../../redux/solicitudesSlice";
import { toast } from "react-toastify";
import OperationStatus from "../../config/OperationStatus";
import ReactSelect from "../../Components/ReactSelect";
import Required from "../../Components/Required";
import { convertToBoolean } from "../../Utils/utils";

export default function FinancialInfo(props) {
  const [listaPaises, setListaPaises] = useState([]);
  const [listaProvincias, setListProvincias] = useState([]);
  const [listaCantones, setListCantones] = useState([]);
  const [listaDistritos, setListDistritos] = useState([]);
  const [listaFinanciamientos, setListaFinanciamientos] = useState([]);
  const costaRicaId = 192;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { solicitudModel, provincias, cantones, distritos } = useSelector(
    (state) => state.solicitudes
  );

  const dispatch = useDispatch();

  const financiamientoId = watch("financiamientoId");
  const paisId = watch("trabajoPaisId");
  const provinciaId = watch("trabajoProvinciaId");
  const cantonId = watch("trabajoCantonId");
  const distritoId = watch("trabajoDistritoId");
  const tieneTrabajo = watch("tieneTrabajo");
  const tieneComputadora = watch("tieneComputadora");
  const esPortatil = watch("esPortatil");
  const trabajaEnSectorInformatica = watch("trabajaEnSectorInformatica");
  useEffect(() => {
    dispatch(obtenerSolicitudInfoFinanciera(props.solicitudId));
  }, []);

  useEffect(() => {
    if (solicitudModel) {
      setValue("empresaTrabajoNombre", solicitudModel.empresaTrabajoNombre);
      setValue("etapaActual", solicitudModel.etapaActual);
      setValue("financiamientoId", solicitudModel.financiamientoId);
      setValue("financiamientoNombre", solicitudModel.financiamientoNombre);
      setValue("solicitudId", solicitudModel.solicitudId);
      if (solicitudModel.infoFinancieraModificado) {
        setValue("tieneComputadora", solicitudModel.tieneComputadora);
        setValue("esPortatil", solicitudModel.esPortatil);
        setValue("tieneTrabajo", solicitudModel.tieneTrabajo);
        setValue(
          "trabajaEnSectorInformatica",
          solicitudModel.trabajaEnSectorInformatica
        );
      }
      setValue("trabajoCantonId", solicitudModel.trabajoCantonId);
      setValue("trabajoDistritoId", solicitudModel.trabajoDistritoId);
      setValue("trabajoOcupacion", solicitudModel.trabajoOcupacion);
      setValue("trabajoPaisId", solicitudModel.trabajoPaisId);
      setValue("trabajoDireccion", solicitudModel.trabajoDireccion);
      setValue("trabajoProvinciaId", solicitudModel.trabajoProvinciaId);
    }
  }, [solicitudModel]);

  useEffect(() => {
    if (paisId) {
      if (paisId == costaRicaId) dispatch(obtenerProvinciasByPaisId(paisId));
      else {
        setValue("trabajoProvinciaId", null);
        setValue("trabajoCantonId", null);
        setValue("trabajoDistritoId", null);
      }
    }
  }, [paisId]);
  useEffect(() => {
    if (provinciaId) dispatch(obtenerCantonesByProvinciaId(provinciaId));
  }, [provinciaId]);
  useEffect(() => {
    if (cantonId) dispatch(obtenerDistritosByProvinciaId(cantonId));
  }, [cantonId]);

  useEffect(() => {
    let a = solicitudModel?.paises?.map((item, i) => {
      return { label: item.paisNombre, value: item.paisId };
    });
    setListaPaises(a);

    let b = solicitudModel?.financiamientosTipos?.map((item, i) => {
      return { label: item.financiamientoNombre, value: item.financiamientoId };
    });
    setListaFinanciamientos(b);
  }, [solicitudModel.paises]);

  useEffect(() => {
    let a = provincias?.map((item, i) => {
      return { label: item.provinciaNombre, value: item.provinciaId };
    });
    setListProvincias(a);
  }, [provincias]);

  useEffect(() => {
    let a = cantones?.map((item, i) => {
      return { label: item.cantonNombre, value: item.cantonId };
    });
    setListCantones(a);
  }, [cantones]);

  useEffect(() => {
    let a = distritos?.map((item, i) => {
      return { label: item.distritoNombre, value: item.distritoId };
    });
    setListDistritos(a);
  }, [distritos]);

  useEffect(() => {
    if (tieneTrabajo != undefined || tieneTrabajo != null)
      setValue("tieneTrabajo", convertToBoolean(tieneTrabajo));
  }, [tieneTrabajo]);

  useEffect(() => {
    if (tieneComputadora != undefined || tieneComputadora != null) {
      setValue("tieneComputadora", convertToBoolean(tieneComputadora));
      if (!tieneComputadora) {
        setValue("esPortatil", false);
      }
    }
  }, [tieneComputadora]);

  useEffect(() => {
    if (esPortatil != undefined || esPortatil != null)
      setValue("esPortatil", convertToBoolean(esPortatil));
  }, [esPortatil]);

  useEffect(() => {
    if (
      trabajaEnSectorInformatica != undefined ||
      trabajaEnSectorInformatica != null
    )
      setValue(
        "trabajaEnSectorInformatica",
        convertToBoolean(trabajaEnSectorInformatica)
      );
  }, [trabajaEnSectorInformatica]);
  useEffect(() => {
    if (financiamientoId === 0) setValue("financiamientoId", "");
  }, [financiamientoId]);

  const isRequired = (e) => {
    if (financiamientoId == 3) return e.trim() != "";
    return true;
  };
  const isValid = (e) => {
    return typeof convertToBoolean(e) == "boolean";
  };
  const isValidC = (e) => {
    return cantones.some((a) => a.cantonId == e);
  };
  const isValidD = (e) => {
    return distritos.some((a) => a.distritoId == e);
  };
  const onSubmit = async (data) => {
    data.esPortatil = convertToBoolean(esPortatil);
    data.tieneComputadora = convertToBoolean(tieneComputadora);
    data.tieneTrabajo = convertToBoolean(tieneTrabajo);
    data.trabajaEnSectorInformatica = convertToBoolean(
      trabajaEnSectorInformatica
    );

    if (financiamientoId != 3) data.financiamientoNombre = null;
    if (data.etapaActual === 4) data.etapaActual += 1;

    data.solicitudId = props.solicitudId;
    let { payload } = await dispatch(agregarSolicitudInfoFinanciera(data));
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      props.onGoNext();
      props.handleStepChange(data.etapaActual);
    } else {
      toast.error(payload.message);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-solicitud">
      <div className="form-c">
        <div className="title-container">
          <span className="form-title">Información financiera</span>
        </div>
        <br />
        <div className="grid-container col-2 container">
          <div>
            <div className="grid-container col-2 col-sm-12">
              <div className="grid-item">
                <div className="form-group">
                  <label>¿Cómo financiará sus estudios?</label>
                  <Required />
                  <ReactSelect
                    name="financiamientoId"
                    control={control}
                    lista={listaFinanciamientos}
                    rules={{ required: "Campo requerido" }}
                  />

                  {errors.financiamientoId && (
                    <p className="error">{errors.financiamientoId.message}</p>
                  )}
                </div>
              </div>

              {financiamientoId == 3 && (
                <>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Otro financiamiento</label>
                      <Required />
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Especifique (opción Otro)"
                        disabled={financiamientoId == 3 ? false : true}
                        {...register("financiamientoNombre", {
                          validate: isRequired,
                        })}
                      />
                      {errors?.financiamientoNombre?.type === "validate" && (
                        <p className="error">Campo requerido</p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <label className="form-subheader">Información de trabajo</label>

            <div className="grid-container col-4 col-md-2 col-lg-2  ">
              <div className="grid-item">
                <div className="form-group">
                  <label>¿Trabaja?</label>
                  <Required />
                  <div className="check-container">
                    <div className="form-control-check inline">
                      <input
                        type="radio"
                        value={true}
                        {...register("tieneTrabajo", { validate: isValid })}
                        checked={tieneTrabajo == true}
                      />{" "}
                      <label>Si</label>
                    </div>
                    <div className="form-control-check inline">
                      <input
                        type="radio"
                        value={false}
                        {...register("tieneTrabajo", { validate: isValid })}
                        checked={tieneTrabajo == false}
                      />{" "}
                      <label>No</label>
                    </div>
                  </div>
                  {errors?.tieneTrabajo?.type === "validate" && (
                    <p className="error">Campo requerido</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>¿Tiene computadora?</label>
                  <Required />
                  <div className="check-container">
                    <div className="form-control-check inline">
                      <input
                        type="radio"
                        value={true}
                        checked={tieneComputadora == true}
                        {...register("tieneComputadora", { validate: isValid })}
                      />{" "}
                      <label>Si</label>
                    </div>
                    <div className="form-control-check inline">
                      <input
                        type="radio"
                        value={false}
                        checked={tieneComputadora == false}
                        {...register("tieneComputadora", { validate: isValid })}
                      />{" "}
                      <label>No</label>
                    </div>
                  </div>
                  {errors?.tieneComputadora?.type === "validate" && (
                    <p className="error">Campo requerido</p>
                  )}
                </div>
              </div>
              {convertToBoolean(tieneComputadora) && (
                <div className="grid-item">
                  <div className="form-group">
                    <label>¿Su computadora es portátil?</label>
                    <Required />
                    <div className="check-container">
                      <div className="form-control-check inline">
                        <input
                          type="radio"
                          value={true}
                          checked={esPortatil == true}
                          {...register("esPortatil", { validate: isValid })}
                        />{" "}
                        <label>Si</label>
                      </div>
                      <div className="form-control-check inline">
                        <input
                          type="radio"
                          value={false}
                          checked={esPortatil == false}
                          {...register("esPortatil", { validate: isValid })}
                        />{" "}
                        <label>No</label>
                      </div>
                    </div>
                    {errors?.esPortatil?.type === "validate" && (
                      <p className="error">Campo requerido</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {tieneTrabajo && (
              <>
                <div className="grid-container col-2 col-md-2 col-lg-2 col-sm-12  ">
                  <div className="grid-item">
                    <div className="form-group">
                      <label>¿Trabaja en el sector de la informática?</label>
                      <Required />
                      <div className="check-container">
                        <div className="form-control-check inline">
                          <input
                            type="radio"
                            value={true}
                            checked={trabajaEnSectorInformatica == true}
                            {...register("trabajaEnSectorInformatica", {
                              validate: isValid,
                            })}
                          />{" "}
                          <label>Si</label>
                        </div>
                        <div className="form-control-check inline">
                          <input
                            type="radio"
                            value={false}
                            checked={trabajaEnSectorInformatica == false}
                            {...register("trabajaEnSectorInformatica", {
                              validate: isValid,
                            })}
                          />{" "}
                          <label>No</label>
                        </div>
                      </div>
                      {errors?.trabajaEnSectorInformatica?.type ===
                        "validate" && <p className="error">Campo requerido</p>}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Nombre de la empresa en que trabaja</label>
                      <Required />
                      <input
                        className="form-control"
                        type="text"
                        {...register("empresaTrabajoNombre", {
                          required: "Campo requerido",
                        })}
                      />
                      {errors.empresaTrabajoNombre && (
                        <p className="error">
                          {errors.empresaTrabajoNombre.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Pais</label>
                      <Required />

                      <ReactSelect
                        name="trabajoPaisId"
                        control={control}
                        lista={listaPaises}
                        rules={{ required: "Campo requerido" }}
                      />

                      {errors.trabajoPaisId && (
                        <p className="error">{errors.trabajoPaisId.message}</p>
                      )}
                    </div>
                  </div>
                  {paisId == costaRicaId && (
                    <>
                      <div className="grid-item">
                        <div className="form-group">
                          <label>Provincia</label>
                          <Required />

                          <ReactSelect
                            name="trabajoProvinciaId"
                            control={control}
                            lista={listaProvincias}
                            rules={{ required: "Campo requerido" }}
                          />

                          {errors.trabajoProvinciaId && (
                            <p className="error">
                              {errors.trabajoProvinciaId.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="grid-item">
                        <div className="form-group">
                          <label>Cantón</label>
                          <Required />

                          <ReactSelect
                            name="trabajoCantonId"
                            control={control}
                            lista={listaCantones}
                            rules={{
                              required: "Campo requerido",
                              validate: isValidC,
                            }}
                          />
                          {errors.trabajoCantonId && (
                            <p className="error">
                              {errors.trabajoCantonId.message}
                            </p>
                          )}
                          {errors?.trabajoCantonId?.type === "validate" && (
                            <p className="error">Campo requerido</p>
                          )}
                        </div>
                      </div>
                      <div className="grid-item">
                        <div className="form-group">
                          <label>Distrito</label>
                          <Required />

                          <ReactSelect
                            name="trabajoDistritoId"
                            control={control}
                            lista={listaDistritos}
                            rules={{
                              required: "Campo requerido",
                              validate: isValidD,
                            }}
                          />

                          {errors.trabajoDistritoId && (
                            <p className="error">
                              {errors.trabajoDistritoId.message}
                            </p>
                          )}
                          {errors?.trabajoDistritoId?.type === "validate" && (
                            <p className="error">Campo requerido</p>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="grid-item">
                    <div className="form-group">
                      <label>Dirección exacta de la empresa</label>
                      <Required />
                      <textarea
                        className="form-group"
                        cols="30"
                        rows="3"
                        {...register("trabajoDireccion", {
                          required: "Campo requerido",
                        })}
                      ></textarea>
                      {errors.trabajoDireccion && (
                        <p className="error">
                          {errors.trabajoDireccion.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Ocupación en la empresa</label>
                      <Required />
                      <input
                        className="form-control"
                        type="text"
                        {...register("trabajoOcupacion", {
                          required: "Campo requerido",
                        })}
                      />
                      {errors.trabajoOcupacion && (
                        <p className="error">
                          {errors.trabajoOcupacion.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="button-control">
            <img src={infoIMG} alt="ilustración Información financiera" />
            <div>
              <button onClick={props.onGoBack}>Atrás</button>
              <button type="submit">Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
