import React, { useState} from "react";
import myImage from "../../SVG/LoginAdm.svg";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import autService from "../../services/authService";
import WebApi from "../../Api/WebApi";
import OperationStatus from "../../config/OperationStatus";
import { GoogleLogin } from "@react-oauth/google";

export default function Login() {
  const {
    handleSubmit,
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let res = await autService.loginAdm(data, dispatch);
    if (res.operationStatus === OperationStatus.SUCCESS) {
      localStorage.setItem("token", res.result.token);
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(res.result.refreshToken)
      );

      WebApi.setAuthToken(res.result.token);
      navigate("/Admin");
    } else {
      toast.error(res.message);
    }
  };
  const handleCallbackResponse = async (response) => {
    let res = await autService.loginGoogleAdm(response.credential, dispatch);
    if (res.operationStatus === OperationStatus.SUCCESS) {
      localStorage.setItem("token", res.result.token);
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(res.result.refreshToken)
      );

      WebApi.setAuthToken(res.result.token);
      navigate("/Admin");
    } else {
      toast.error(res.message);
    }
  };
  return (
    <div className="rootLogin">
      <div className="loginContainer">
        <div className="grid-container col-2 col-md col-sm-12 ">
          <div className="grid-item justify-self-center reverse login-item">
            <img
              className="img-login"
              src={myImage}
              alt="Imagen de inicio de sesión"
            />
          </div>
          <div className="grid-item justify-self-center login-item">
            <h1>Administración de admisiones CENFOTEC</h1>
            <br></br>
            <h2>Inicio de sesión</h2>
            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="loginFlex">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleCallbackResponse(credentialResponse);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
