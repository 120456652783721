import React from "react";
import Modal from "./Modal";
import { useForm, useFieldArray } from "react-hook-form";
import { useState, useEffect } from "react";
import ModalRemove from "./ModalRemove";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerPerfilesParametros,
  eliminarParametro,
  actualizarPerfilParametro,
  AgregarPerfilParametro,
} from "../../redux/perfilSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "parametroId", sortable: true },
  { label: "Perfil", accessor: "perfilNombre", sortable: true },
  {
    label: "Nivel académico",
    accessor: "nivelAcademicoNombre",
    sortable: true,
  },
  {
    label: "Estudió en Costa Rica",
    accessor: "estudiaCostaRica",
    sortable: false,
    render: (item) => (
      <td>
        {item.estudiaCostaRica ? (
          <i className="material-icons success"></i>
        ) : (
          <i className="material-icons danger"></i>
        )}
      </td>
    ),
  },
  {
    label: "Vive o trabaja en Costa Rica",
    accessor: "viveTrabajaCostaRica",
    sortable: false,
    render: (item) => (
      <td>
        {item.viveTrabajaCostaRica ? (
          <i className="material-icons success"></i>
        ) : (
          <i className="material-icons danger"></i>
        )}
      </td>
    ),
  },
  {
    label: "Carreras",
    accessor: "carreras",
    sortable: true,
    render: (item) => <td>{item.carreras.length}</td>,
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function PerfilesParametros(props) {
  const [modalState, changeModalState] = useState(false);
  const [removeState, changeRemoveState] = useState(false);
  const [registerSelected = [], selectReg] = useState();
  const [tituloModal, setTituloModal] = useState("Agregar perfil");
  const [carreras, setCarreras] = useState([]);

  const { width } = WindowDimension();
  const dispatch = useDispatch();

  const { submitAction } = useSubmit();

  const { perfilesParametros } = useSelector((state) => state.perfiles);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      parametroId: 0,
      estudiaCostaRica: false,
      viveTrabajaCostaRica: false,
      nivelAcademicoId: undefined,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "carreras",
  });

  const nivelId = watch("nivelAcademicoId");

  useEffect(() => {}, [perfilesParametros]);

  useEffect(() => {
    cargarPerfiles();
  }, [dispatch]);

  const habdledCarreras = (getAll) => {
    if (getAll) {
      setCarreras(perfilesParametros?.carreras);
    } else {
      setCarreras(perfilesParametros?.carreras.filter((x) => x.estadoId == 4));
    }
  };

  const showModalEdit = (reg) => {
    reset(reg);
    setTituloModal("Editar parámetro");
    changeModalState(true);
    habdledCarreras(true);

    selectReg(reg);
  };

  const closeModalEdit = () => {
    reset({
      parametroId: 0,
      estudiaCostaRica: false,
      viveTrabajaCostaRica: false,
      nivelAcademicoId: undefined,
    });
    changeModalState(false);
  };

  const showRemoveModalEdit = (reg) => {
    selectReg(reg);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    selectReg();
    changeRemoveState(false);
  };

  const cargarPerfiles = () => {
    dispatch(obtenerPerfilesParametros());
  };

  const eliminar = async () => {
    submitAction(eliminarParametro, registerSelected.parametroId, () => {
      closeRemoveModalEdit();
      cargarPerfiles();
    });
  };

  const actualizar = async (data) => {
    if (data.carreras) {
      data.carreras = data.carreras.map((a) => parseInt(a.carreraId));
    }

    submitAction(
      data.parametroId > 0 ? actualizarPerfilParametro : AgregarPerfilParametro,
      data,
      () => {
        closeModalEdit();
        cargarPerfiles();
      }
    );
  };

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <button
          className="main-admin-button"
          onClick={() => {
            changeModalState(true);
            setTituloModal("Nuevo parámetro");
            reset();
            habdledCarreras(false);
          }}
        >
          +
        </button>
        <h2>Parámetros de perfiles</h2>
      </div>

      <div className="table-container">
        {perfilesParametros?.perfilesParametros?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={perfilesParametros?.perfilesParametros}
              clase="table-adm"
              actions={[
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showModalEdit(item),
                },
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showRemoveModalEdit(item),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={modalState}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "medium"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(actualizar)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="params-container">
            <div className="params-form-container">
              <div className="form-group">
                <label>Nivel académico</label>
                <Required />
                <select
                  className="form-control"
                  {...register("nivelAcademicoId", {
                    required: "Campo requerido",
                  })}
                >
                  <option value="">Seleccione</option>
                  {perfilesParametros?.nivelesAcademicos?.map((d, i) => (
                    <option key={d.nivelId} value={d.nivelId}>
                      {d.nivelAcademicoNombre}
                    </option>
                  ))}
                </select>
                {errors.nivelAcademicoId && (
                  <p className="error">{errors.nivelAcademicoId.message}</p>
                )}
              </div>

              <div className="d-flex align-self-center ">
                <input
                  {...register("estudiaCostaRica")}
                  type="checkbox"
                  className="form-control-check d-flex"
                />
                <label>Estudia en Costa Rica</label>
              </div>
              <div className="d-flex align-self-center">
                <input
                  {...register("viveTrabajaCostaRica")}
                  type="checkbox"
                  className="form-control-check d-flex"
                />
                <label>Vive o trabaja en Costa Rica</label>
              </div>
            </div>

            <div className="form-group">
              <label>Carreras en las que se aplica:</label>
              <Required />

              <div className="form-group">
                <div>
                  <button
                    type="button"
                    className="button-text"
                    onClick={() => {
                      append({
                        carreraId: undefined,
                      });
                    }}
                  >
                    Agregar carrera
                  </button>
                </div>
              </div>

              <ul>
                {fields.map((item, index) => {
                  return (
                    <li key={"el_" + index}>
                      <div className="form-group flex-form gap-20">
                        <select
                          className="form-control"
                          defaultValue={item.carreraId}
                          {...register(`carreras.${index}.carreraId`, {
                            required: "Campo requerido",
                          })}
                        >
                          <option value="">Seleccione</option>
                          {carreras
                            .filter((x) => x.nivelId == nivelId)
                            ?.map((d, i) => (
                              <option key={d.carreraId} value={d.carreraId}>
                                {d.carreraNombre}
                              </option>
                            ))}
                        </select>

                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="modBtn"
                        >
                          <i className="material-icons"></i>
                        </button>
                      </div>
                      {errors.carreras?.[index]?.carreraId && (
                        <p className="error">
                          {errors.carreras?.[index]?.carreraId.message}
                        </p>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="form-group">
              <label>Perfil a aplicar</label>
              <Required />
              <select
                className="form-control"
                {...register("perfilId", { required: "Campo requerido" })}
              >
                <option value="">Seleccione</option>
                {perfilesParametros?.perfiles?.map((d, i) => (
                  <option key={d.perfilId} value={d.perfilId}>
                    {d.perfilNombre}
                  </option>
                ))}
              </select>
              {errors.perfilId && (
                <p className="error">{errors.perfilId.message}</p>
              )}
            </div>
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={changeRemoveState}
        title="Eliminar parametro"
        message="¿Seguro que desea eliminar el siguiente parametro?"
        item={registerSelected.parametroId}
        icon={null}
        btnGroup={
          <>
            <button className="button-text text-red" onClick={eliminar}>
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default PerfilesParametros;
