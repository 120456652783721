import React from 'react';

export default function TableSolicitudes(props) {
  return (
    <table className="table-inicio">
      <thead>
        <tr>
          <td>Id</td>
          <td>Programa</td>
          <td>Nivel</td>
          <td>Período</td>
          <td>Estatus</td>
          <td>Acción</td>
        </tr>
      </thead>

      <tbody>
        {props.lista?.map((item, i) => {
          return (
            <tr key={i}>
              <td className="mainColumn">{item.numero}</td>
              <td className="mainColumn">{item.carreraNombre}</td>
              <td>{item.nivelAcademicoNombre}</td>
              <td className="colFontBlue">{item.periodo}</td>
              <td
                className={`mainColumn ${
                  item.estadoId === 9 ||
                  item.estadoId === 10 ||
                  item.estadoId === 11 ||
                  item.estadoId === 19
                    ? 'statusGreen'
                    : item.estadoId === 8
                    ? 'statusYellow'
                    : item.estadoId === 7
                    ? 'statusRed'
                    : 'normalStatus'
                }`}
              >
                {item.estadoNombre}
              </td>
              <td>
                <button
                  className="btnNotificacion"
                  onClick={() => {
                    props.handleViewBtn(item.solicitudId, item.numero);
                  }}
                >
                  <i className="material-icons"></i>
                </button>
                {item.estadoId == 7 ||
                item.estadoId == 8 ||
                item.estadoId == 17 ||
                item.estadoId == 20 ? (
                  <>
                    <button
                      className="btnNotificacion"
                      onClick={() => {
                        props.handleEditButton(item.solicitudId);
                      }}
                    >
                      <i className="material-icons"></i>
                    </button>

                    <button
                      className="btnNotificacion"
                      onClick={() => {
                        props.handleRemoveBtn(item.solicitudId);
                      }}
                    >
                      <i className="material-icons"></i>
                    </button>
                  </>
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
