import EditarPerfil from '../../SVG/Editarperfil.svg'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../css/configUser.css";
import { actualizarContrasenia, obtenerUsuarioActual } from "../../redux/usuariosSlice";
import OperationStatus from '../../config/OperationStatus'
import { useNavigate } from "react-router-dom";


export default function ConfigUser() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const nuevaContrasena = watch("nuevaContrasena")

  const onSubmit = async (data) => {
    let { payload } = await dispatch(actualizarContrasenia(data));
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      dispatch(obtenerUsuarioActual());
      navigate("/");
    } else {
      toast.error(payload.message);
    }
  }

  const passwordVerify = (e) => {
    return nuevaContrasena === e
  }

  return (
    <div className="rootConfig">
      <div className='principal'>
        <div className="container-root">
          <h2>Cambio de contraseña</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="form-config">
            <div className="grid-container container col-2">
              <div className="grid-container col-2 col-lg-2 col-sm-12">
                <div className="grid-item">
                  <div className="form-group">
                    <label>Contraseña anterior</label>
                    <input className="form-control" autoComplete="current-password" type="password"
                      {...register("antiguaContrasena", { required: "Campo requerido" })}
                    />
                    {errors.antiguaContrasena && <p className="error">{errors.antiguaContrasena.message}</p>}

                  </div>
                </div>

                <div className="grid-item">
                  <div className="form-group">
                    <label>Nueva Contraseña</label>
                    <input className="form-control" autoComplete="new-password" type="password"
                      {...register("nuevaContrasena", { required: "Campo requerido" })}
                    />
                    {errors.nuevaContrasena && <p className="error">{errors.nuevaContrasena.message}</p>}
                  </div>
                </div>

                <div className="grid-item">
                  <div className="form-group">
                    <label>Confirmar contraseña</label>
                    <input className="form-control" autoComplete="new-password" type="password"
                      {...register("confirmarContrasenia", { required: "Campo requerido", validate: passwordVerify })}
                    />
                    {errors.confirmarContrasenia && <p className="error">{errors.confirmarContrasenia.message}</p>}
                    {errors?.confirmarContrasenia?.type === "validate" && <p className="error">Las contraseñas no coinciden</p>}

                  </div>
                </div>
              </div>

              <div className="button-control">
                <img src={EditarPerfil} alt="Imagen editar perfil" />
                <div>
                  <button type="submit">Guardar</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}