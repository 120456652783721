import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerSolicitudActividades } from '../../redux/solicitudesAdmSlice';
import { useParams, useLocation } from 'react-router-dom';
import LoadingSpiner from '../../Components/LoadingSpiner';

import { OrderTable } from '../../Components/OrderTable';

function SolicitudActividades() {
  const dispatch = useDispatch();
  const params = useParams();
  const { state } = useLocation();

  useEffect(() => {
    dispatch(obtenerSolicitudActividades(params.id));
  }, [dispatch]);

  const { solicitudesActividades, loading } = useSelector(
    (state) => state.solicitudesAdm
  );

  const columns = [
    { label: '#', accessor: 'actividadId', sortable: true },
    { label: 'Realizado por', accessor: 'nombreCompleto', sortable: true },
    { label: 'Rol', accessor: 'rol', sortable: true },
    { label: 'Fecha', accessor: 'fecha', sortable: true },
    { label: 'Descripción', accessor: 'descripcion', sortable: true },
    { label: 'Estado', accessor: 'estadoNombre', sortable: true },
    { label: 'Observaciones', accessor: 'observaciones', sortable: true },
  ];
  return (
    <main className="mainContentDetail">
      <div className="controls-sticky">
        <div className="divAction">
          <h2 id="codigo-solicitud">
            Actividades de la Solicitud no. {state.numero}
          </h2>
        </div>
        <div className="divAction">
          {/* <span className="subtitulo-solicitud">citudDetalle.primerApellido </span> */}
        </div>
      </div>

      <div className="content-a">
        <section>
          <div className="dataContainer">
            <OrderTable
              {...{ columns }}
              data={solicitudesActividades}
              clase="table-archivos"
            />
          </div>
        </section>
      </div>

      <LoadingSpiner
        show={loading}
        fullPage={true}
        styles={{
          top: '45%',
          left: '50%',
          position: 'inherit',
          width: '50px',
          height: '50px',
          borderWidth: '10px',
          borderStyle: 'solid',
          borderColor: 'rgb(27 78 147) rgb(0 0 0) rgb(0 0 0)',
          borderImage: 'initial',
        }}
      />
    </main>
  );
}

export default SolicitudActividades;
