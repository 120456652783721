
import WebApi from '../Api/WebApi'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const obtenerCentrosEducativos = createAsyncThunk(
    "centrosEducativos/obtenerCentrosEducativos",
    async (c, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().get(`admin/CentrosEducativos?centroEducativo=${c.q}&paisId=${c.paisId}&provinciaId=${c.provinciaId}&cantonId=${c.cantonId}&centroEducativoId=${c.centroEducativoId}`)
            return res.data.result;
        } catch (err) {
            return { success: false, message: err, show: true };
        }
    },
);
export const obtenerInfoGeografica = createAsyncThunk(
    "centrosEducativos/obtenerInfoGeografica",
    async (c, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().get(`admin/CentrosEducativosInfoGeografica`)
            return res.data.result;
        } catch (err) {
            return { success: false, message: err, show: true };
        }
    },
);
export const actualizarCentrosEducativos = createAsyncThunk(
    "centrosEducativos/actualizarCentrosEducativos",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().put('admin/CentrosEducativos/' + item.id, item.item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const agregarCentrosEducativos = createAsyncThunk(
    "centrosEducativos/agregarCentrosEducativos",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().post('admin/CentrosEducativos', item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const eliminarCentrosEducativos = createAsyncThunk(
    "centrosEducativos/eliminarCentrosEducativos",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().delete('admin/CentrosEducativos/' + id)
            return res.data;
        } catch (err) {
            return rejectWithValue(err)
        }
    }
);


const centrosEducativosSlice = createSlice({
    name: 'centrosEducativos',
    initialState: {
        centrosEducativos: [],
        model: {},
        loading:false
    },
    reducers: {
    },
    extraReducers: {
        [obtenerCentrosEducativos.pending]: (state, action) => {
            state.loading=true;
        },
        [obtenerCentrosEducativos.fulfilled]: (state, action) => {
            state.loading=false;
            state.centrosEducativos = action.payload;

        },
        [obtenerInfoGeografica.fulfilled]: (state, action) => {
            state.model = action.payload;
        },
    }
})

export default centrosEducativosSlice.reducer;