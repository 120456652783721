import React, { useState, useEffect } from 'react';
import '../Styles/header.css';
import titleImg from '../../SVG/TitleAdm.svg';
import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import authService from '../../services/authService';

function AdminTitle(props) {
  const [titulo, setTitulo] = useState('Administración CENFOTEC');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname == '/Admin') setTitulo('Administración CENFOTEC');
    else if (location.pathname == '/Admin/solicitudes')
      setTitulo('Administración de solicitudes');
    else if (location.pathname == '/Admin/listaArchivos') setTitulo('Archivos');
    else if (location.pathname == '/Admin/configuracionPerfil')
      setTitulo('Ajustes de usuario');
    else setTitulo('Configuración');
  }, [location]);

  const hideSidebar = () => {
    const sidebar = document.querySelector('.sideBar');
    const closeBtn = document.querySelectorAll('.toggleBtnAdm');
    sidebar.classList.toggle('sideBarVisible');

    closeBtn.forEach((el) => {
      el.classList.toggle('invisibleBtn');
    });
  };

  return (
    <>
      <header>
        <div className="image-container">
          <img
            src={titleImg}
            className="headerImg"
            alt="Imagen admnistración "
          />
        </div>
        <div className="align-self-center">
          <h1 className="titleSolicitudes">{titulo}</h1>
        </div>

        <div className="align-self-center ml-auto">
          <div className="userTitle">
            <h4>{props.usuarioNombre}</h4>
            <button
              onClick={() => {
                authService.logout(navigate);
              }}
            >
              <i className="material-icons"></i>
            </button>
          </div>
        </div>
      </header>
      <button className="toggleBtnAdm" onClick={hideSidebar}>
        <i className="material-icons"></i>
      </button>
      <button className="toggleBtnAdm invisibleBtn" onClick={hideSidebar}>
        <i className="material-icons"></i>
      </button>
    </>
  );
}

export default AdminTitle;
