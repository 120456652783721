
import WebApi from '../Api/WebApi'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ObtenerTodos = createAsyncThunk(
    "Lugares/ObtenerTodos",
    async (i, { rejectWithValue }) => {
        try {
            if(typeof i === 'undefined'){
                const res = await WebApi.api().get(`admin/Lugares`)
            return res.data.result;
             }
             else{
                const res = await WebApi.api().get(`admin/Lugares?nombre=${i}`)
                return res.data.result;
             }
        } catch (err) {
            return { success: false, message: err, show: true };
        }
    }
);
export const ObtenerById = createAsyncThunk(
    "Lugares/ObtenerById",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().get('admin/Lugares/' + id)
            return res.data.result;
        } catch (err) {
            return { success: false, message: err, show: true };
        }
    }
);
export const Insertar = createAsyncThunk(
    "Lugares/Insertar",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().post('admin/Lugares/', item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const Actualizar = createAsyncThunk(
    "Lugares/Actualizar",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().put('admin/Lugares/' + item.lugarId, item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const Eliminar = createAsyncThunk(
    "Lugares/Eliminar",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().delete('admin/Lugares/' + id)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);



const lugaresSlice = createSlice({
    name: 'lugares',
    initialState: {
        lugares: [],
        model: {},
    },
    reducers: {
    },
    extraReducers: {
        [ObtenerTodos.fulfilled]: (state, action) => {
            state.lugares = action.payload;
        },
        [ObtenerById.fulfilled]: (state, action) => {
            state.model = action.payload;
        },
    }
})

export default lugaresSlice.reducer;