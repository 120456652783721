import React from "react";

export default function LoadingSpiner({
  styles = {},
  fullPage = false,
  show = true,
  message = null,
}) {
  return (
    <div
      className={`spinner-container ${fullPage ? "fullpage" : ""} ${
        show ? "d-block" : "d-none"
      } `}
    >
      <div className="loading-spinner" style={styles} />
      {message && <p>{message}</p>}
    </div>
  );
}
