
import WebApi from '../Api/WebApi'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ObtenerTiposFinanciamientos = createAsyncThunk(
    "tiposFinanciamientos/ObtenerTiposFinanciamientos",
    async (i, { rejectWithValue }) => {
        try {
            if(typeof i === 'undefined'){
                const res = await WebApi.api().get(`admin/tipoFinanciamientos`)
            return res.data.result;
             }
             else{
                const res = await WebApi.api().get(`admin/tipoFinanciamientos?nombre=${i}`)
                return res.data.result;
             }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const ObtenerTipoFinanciamientoById = createAsyncThunk(
    "tiposFinanciamientos/ObtenerTipoFinanciamientoById",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().get('admin/tipoFinanciamientos/' + id)
            return res.data.result;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const InsertarTipoFinanciamiento = createAsyncThunk(
    "tiposFinanciamientos/InsertarTipoFinanciamiento",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().post('admin/tipoFinanciamientos/', item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const ActualizarTipoFinanciamiento = createAsyncThunk(
    "tiposFinanciamientos/ActualizarTipoFinanciamiento",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().put('admin/tipoFinanciamientos/' + item.tipoFinanciamientoEstudioId, item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const EliminarTipoFinanciamiento = createAsyncThunk(
    "tiposFinanciamientos/EliminarTipoFinanciamiento",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().delete('admin/tipoFinanciamientos/' + id)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);



const tiposFinanciamientosSlice = createSlice({
    name: 'campos',
    initialState: {
        tiposFinanciamientos: [],
        tipoFinanciamiento: {},
    },
    reducers: {
    },
    extraReducers: {
        [ObtenerTiposFinanciamientos.fulfilled]: (state, action) => {
            state.tiposFinanciamientos = action.payload;
        },
        [ObtenerTipoFinanciamientoById.fulfilled]: (state, action) => {
            state.tipoFinanciamiento = action.payload;
        },
    }
})

export default tiposFinanciamientosSlice.reducer;