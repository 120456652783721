import React from 'react'
import ImgError from '../SVG/404Error.svg'

function PageNotFound({message}) {
  return (
    <div className='error-container'>
    <div className='main-admin img-error-container'>
      <img src={ImgError} alt="Imagen Página no encontrada"></img>
    </div>
    <div className='message'>
        <h1>{message}</h1>
    </div>
    </div>
  )
}

export default PageNotFound