import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderTable } from '../../Components/OrderTable';
import { obtenerSolicitudActividades } from '../../redux/solicitudesAdmSlice';
import { useParams, useLocation } from 'react-router-dom';
import LoadingSpiner from '../../Components/LoadingSpiner';

function HistorialActividades() {

  const dispatch = useDispatch();

  const { solicitudesActividades, loading } = useSelector(
    (state) => state.solicitudesAdm
  );
  const params = useParams();
  const { state } = useLocation();
  useEffect(() => {
    dispatch(obtenerSolicitudActividades(params.id));
  }, [dispatch]);

  const columns = [
    { label: '#', accessor: 'actividadId', sortable: true },
    { label: 'Realizado por', accessor: 'nombreCompleto', sortable: true },
    { label: 'Rol', accessor: 'rol', sortable: true },
    { label: 'Fecha', accessor: 'fecha', sortable: true },
    { label: 'Descripción', accessor: 'descripcion', sortable: true },
    { label: 'Estado', accessor: 'estadoNombre', sortable: true },
    { label: 'Observaciones', accessor: 'observaciones', sortable: true },
    // { label: '', accessor: 'action', sortable: false },
  ];
  return (
    <>
      {' '}
      <div className="principal">
        <h2 className={'ml-1'}>
          Actividades de la Solicitud no. {state.numero}
        </h2>
        <div className={`principal-container`}>
          <OrderTable
            {...{ columns }}
            data={solicitudesActividades}
            clase="table-archivos"
          />
        </div>
      </div>
      <LoadingSpiner
        show={loading}
        fullPage={true}
        styles={{
          top: '45%',
          left: '50%',
          position: 'inherit',
          width: '50px',
          height: '50px',
          borderWidth: '10px',
          borderStyle: 'solid',
          borderColor: 'rgb(27 78 147) rgb(0 0 0) rgb(0 0 0)',
          borderImage: 'initial',
        }}
      />
    </>
  );
}

export default HistorialActividades;
