import React from 'react'
import myImage from './SVG/403Error.svg'
import logo from './SVG/LogoCenfotec.svg'
import {useNavigate,useLocation} from 'react-router-dom'
import Roles from './config/Roles'
function Required() {
    const navigate=useNavigate();
    const location = useLocation();
    const redirectByRole=()=>{
        if (location.state.includes(Roles.ESTUDIANTE)) {
            navigate("/");
        }else{
            navigate('/Admin');
        }
    }

    return (
        <div className="page-root">
            <div className="page-container" >
                <div className="page-image">
                    <img className="" src={myImage} />
                </div>
                <div className="page-title-container">
                    <span className="page-title-item" >Acceso Denegado</span>
                    <span>La página a la que trata de acceder tiene acceso restringido</span>
                    <span>Por favor contacte a su administrador</span>
                </div>

                <div>
                    <button className="page-button" onClick={redirectByRole}>Volver a la página anterior</button>
                </div>

            </div>

        </div>
    )
}

export default Required
