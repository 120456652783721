import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useOutletContext,
  useNavigate,
} from 'react-router-dom';

import Adm from './Adm/Pages/InicioAdm';
import Inicio from './Inicio/Pages/Inicio';
import Login from './Inicio/Pages/Login';
import Registro from './Inicio/Pages/Registro';
import Verify from './Inicio/Pages/Verify';
import LoginAdm from './Adm/Pages/Login';
import VistaInicio from './Inicio/Components/VistaInicio';
import EditInfo from './Inicio/Components/EditInfo';
import ConfigUser from './Inicio/Components/ConfigUser';
import Solicitud from './Formulario/Pages/SolicitudInscripcion';
import Required from './Required';
import PrivateRoutes from './Components/PrivateRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerUsuarioActual } from './redux/usuariosSlice';
import RolesConfig from './config/Roles';
import jwt_decode from 'jwt-decode';
import EventBus from './services/EventBus';
import AuthService from './services/authService';
import PageNotFound from './Components/PageNotFound';
import RecuperarContrasenia from './Inicio/Pages/RecuperarContraseña';
import ReiniciarContrasena from './Inicio/Pages/ReiniciarContraseña';
import HistorialActividades from './Inicio/Components/HistorialActividades';

function App() {
  const Rol = RolesConfig;
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById('frameLogo').style.display = 'none';

    EventBus.on('logout', () => {
      AuthService.logout(navigate);
    });
  }, []);

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/Login" element={<Login />} />
        <Route path="/LoginAdm" element={<LoginAdm />} />
        <Route path="/Registro" element={<Registro />} />
        <Route path="/RecuperarContrasena" element={<RecuperarContrasenia />} />
        <Route path="/ReiniciarContrasena" element={<ReiniciarContrasena />} />

        <Route path="/Required" element={<Required />} />
        <Route
          path="*"
          element={
            <PageNotFound message={'Error 404 - Página no encontrada'} />
          }
        />

        {/* Private routes */}
        <Route element={<PrivateRoutes allowedRoles={[Rol.ESTUDIANTE]} />}>
          <Route path="/" element={<Inicio />}>
            <Route index element={<VistaInicio />} />
            <Route path="userInfo" element={<EditInfo />} />
            <Route path="configuracion" element={<ConfigUser />} />
            <Route path="historial/:id" element={<HistorialActividades />} />
            <Route exact path="/Solicitud/:id/*" element={<Solicitud />} />
          </Route>
          <Route path="/Verificar" element={<Verify />} />
        </Route>

        <Route
          element={
            <PrivateRoutes
              allowedRoles={[
                Rol.ADMIN,
                Rol.oficialAdmisiones,
                Rol.REGISTRO,
                Rol.DIRECTOR,
                Rol.REPORTERIA,
                Rol.ADMINISTRADOR_DE_CONFIGURACIONES
              ]}
            />
          }
        >
          <Route path="/Admin/*" element={<Adm />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
