import Title from "../Components/AdminTitle";
import SideBar from "../Components/SideBar";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ListaSolicitudes from "../Components/ListaSolicitudes";
import ListaCarreras from "../Components/ListaCarreras";
import ListaDocumentos from "../Components/ListaDocumentos";
import DetalleSolicitud from "../Components/DetalleSolicitud";
import "../Styles/inicioAdm.css";
import ListaPlantillas from "../Components/ListaPlantillas";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import TiposFinanciamientos from "../Components/TiposFinanciamientos";
import NoResults from "../../SVG/Admnoresults.svg";

import { obtenerUsuarioActualAdm } from "../../redux/usuariosSlice";
import Razones from "../Components/Razones";
import Formas from "../Components/Formas";
import Periodos from "../Components/Periodos";
import TiposOrganizaciones from "../Components/TiposOrganizaciones";
import Lugares from "../Components/Lugares";
import PerfilesDocumentosTipos from "../Components/PerfilesDocumentosTipos";
import Configuraciones from "../Components/Configuraciones";
import Usuarios from "../Components/Usuarios";
import Dashboard from "../Components/Dashboard";
import PageNotFound from "../../Components/PageNotFound";
import TerminosYCondiciones from "../Components/TerminosYCondiciones";
import PerfilesParametros from "../Components/PerfilesParametros";
import NivelesAcademicos from "../Components/NivelesAcademicos";
import CentrosEducativos from "../Components/CentrosEducativos";
import RolesConfig from "../../config/Roles";
import PrivateRoutes from "../../Components/PrivateRoutes";
import SolicitudActividades from "../Components/SolicitudActividades";
import TiposPlantillas from "../Components/TiposPlantillas";
import TiposPeriodos from "../Components/TiposPeriodos";
import ListaArchivos from "../Components/ListaArchivos";
import { extractRoles } from "../../Utils/utils";
import ListaBecasPatrocinios from "../Components/ListaBecasPatrocinios";
import EstudiantesMatriculados from "../Components/Reportes/EstudiantesMatriculados";
import EstudiantesConAsistencia from "../Components/Reportes/EstudiantesConAsistencia";

function InicioAdm() {
  const Rol = RolesConfig;

  const [loading, setLoading] = useState(false);
  const { usuario } = useSelector((state) => state.usuarios);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(obtenerUsuarioActualAdm());
  }, [dispatch]);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setLoading(false);
      }, 800);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div className="containerAdm">
      <Loading
        classParent={loading ? "loadingParent" : "loadingParent loadingHidden"}
        classLoader="loaderGeneral"
      />
      <SideBar hasPermission={extractRoles().some(x=>x==Rol.ADMINISTRADOR_DE_CONFIGURACIONES||x==Rol.ADMIN)} />

      <>
        <Title usuarioNombre={usuario?.nombre} />
        <Routes>
          <Route
            element={
              <PrivateRoutes
                allowedRoles={[Rol.ADMIN, Rol.DIRECTOR, Rol.REGISTRO]}
              />
            }
          >
            <Route
              path="listaArchivos"
              element={
                <ListaArchivos
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
          </Route>
          <Route
            element={
              <PrivateRoutes
                allowedRoles={[Rol.ADMIN, Rol.oficialAdmisiones,Rol.REPORTERIA,Rol.DIRECTOR,Rol.REGISTRO,Rol.ADMINISTRADOR_DE_CONFIGURACIONES]}
              />
            }
          >
  <Route
            exact
            index
            element={
              <Dashboard
                imgNoResult={NoResults}
                msgNoResult={"Sin resultados"}
                usuario={usuario?.nombre}
              />
            }
          />


          </Route>
        
          <Route
            element={
              <PrivateRoutes
                allowedRoles={[Rol.ADMIN, Rol.oficialAdmisiones,Rol.REPORTERIA]}
              />
            }
          >
            <Route
              path="estudiantesMatriculados"
              element={
                <EstudiantesMatriculados
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
          </Route>

          <Route element={<PrivateRoutes allowedRoles={[Rol.ADMIN,Rol.REPORTERIA]} />}>
          <Route
              path="estudiantesAsistencia"
              element={
                <EstudiantesConAsistencia
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
          </Route>
          
          <Route element={<PrivateRoutes allowedRoles={[Rol.ADMIN]} />}>
            <Route
              path="carreras"
              element={
                <ListaCarreras
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
            <Route path="configGeneral" element={<Configuraciones />} />

            <Route
              path="listaBecasPatrocinios"
              element={
                <ListaBecasPatrocinios
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />

         

            <Route
              path="plantillas"
              element={
                <ListaPlantillas
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
            <Route
              path="plantillasTipos"
              element={
                <TiposPlantillas
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />

            <Route
              path="tiposFinanciamientos"
              element={
                <TiposFinanciamientos
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />

            <Route
              path="perfiles"
              element={
                <ListaDocumentos
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />

            <Route
              path="razones"
              element={
                <Razones img={NoResults} noResultMessage={"Sin resultados"} />
              }
            />
            <Route
              path="formas"
              element={
                <Formas img={NoResults} noResultMessage={"Sin resultados"} />
              }
            />
            {/* <Route
              path="periodos"
              element={
                <Periodos img={NoResults} noResultMessage={"Sin resultados"} />
              }
            /> */}
       
            <Route
              path="tiposOrganizaciones"
              element={
                <TiposOrganizaciones
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
            <Route
              path="usuarios"
              element={
                <Usuarios img={NoResults} noResultMessage={"Sin resultados"} />
              }
            />
            <Route
              path="perfilesParametros"
              element={
                <PerfilesParametros
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
            <Route path="terminos" element={<TerminosYCondiciones />} />
            <Route
              path="lugares"
              element={
                <Lugares img={NoResults} noResultMessage={"Sin resultados"} />
              }
            />
            <Route
              path="perfilesDocumentosTipos"
              element={
                <PerfilesDocumentosTipos
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
            <Route
              path="niveles"
              element={
                <NivelesAcademicos
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
            <Route
              path="centros"
              element={
                <CentrosEducativos
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
          </Route>

          <Route element={<PrivateRoutes allowedRoles={[Rol.ADMIN,Rol.ADMINISTRADOR_DE_CONFIGURACIONES]} />}>
          <Route
              path="periodos"
              element={
                <Periodos img={NoResults} noResultMessage={"Sin resultados"} />
              }
            />
                 <Route
              path="tiposPeriodos"
              element={
                <TiposPeriodos
                  img={NoResults}
                  noResultMessage={"Sin resultados"}
                />
              }
            />
          </Route>

 
          <Route element={<PrivateRoutes allowedRoles={[Rol.ADMIN,Rol.DIRECTOR,Rol.REGISTRO,Rol.oficialAdmisiones]} />}>
          <Route
            path="detalleSolicitud/:id"
            element={<DetalleSolicitud usuario={usuario} />}
          />
          <Route
            path="SolicitudActividades/:id"
            element={<SolicitudActividades usuario={usuario} />}
          />

          <Route
            path="solicitudes"
            element={
              <ListaSolicitudes
                img={NoResults}
                noResultMessage={"Sin resultados"}
              />
            }
          />
            </Route>

    

          <Route
            path="*"
            element={<PageNotFound message={"Sección no encontrada"} />}
          />
        </Routes>
      </>
    </div>
  );
}

export default InicioAdm;
