
import React, { useState, useEffect } from 'react'
import AppBar from '../Components/AppBar'
import '../css/verify.css'
import ContactoCenfotec from "../../SVG/ContactoCenfotec.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import LoadingSpiner from '../../Components/LoadingSpiner';
import Modal from '../../Adm/Components/Modal'
import { useForm } from "react-hook-form";
import {
  actualizarCorreo, actualizarTelefono, obtenerUsuarioActual,
  verificarCorreo, verificarTelefono, validarCorreo, validarTelefono, ObtenerDatosRegistro
} from "../../redux/usuariosSlice"
import { useDispatch, useSelector } from "react-redux";
import OperationStatus from '../../config/OperationStatus'
import ReactSelect from '../../Components/ReactSelect'



export default function Verify() {

  const [codigoCorreo, setCodigoCorreo] = useState("");
  const [codigoTelefono, setCodigoTelefono] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTel, setIsLoadingTel] = useState(false);
  const [openModalCorreo, setOpenModalCorreo] = useState(false);
  const [openModalTelefono, setOpenModalTelefono] = useState(false);
  const [lista, setLista] = useState([]);

  const {
    register: registerModal,
    formState: { errors },
    handleSubmit: handleSubmitModal,
    reset,
    control,
    watch,
    setValue,
  } = useForm();
  const telefonoValidado = watch("telefonoValidado");
  const correoValidado = watch("correoValidado");

  const { usuario, registroModel } = useSelector((state) => state.usuarios);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(obtenerUsuarioActual());
    dispatch(ObtenerDatosRegistro());
  }, [dispatch])

  useEffect(() => {
    if (usuario) {
      reset(usuario);
      setValue("correo", usuario.correoElectronico);
      setValue("telefonoPrefijoId", usuario.telefonoPrefijoId);
      setValue("telefonoSufijo", usuario.telefonoSufijo);
    }
  }, [usuario]);

  useEffect(() => {
    if (registroModel.paisesPrefijos) {
      let a = registroModel?.paisesPrefijos?.map((item, i) => {
        return { label: item.codigoArea + ' ' + item.paisNombre, value: item.id }
      })
      setLista(a);
    }
  }, [registroModel.paisesPrefijos])


  const omitirVerificacion = () => {
    navigate("/");
  }
  const finalizarVerificacion = () => {

    if (!correoValidado || !telefonoValidado) {
      toast.error("Faltan campos por verificar")
      return;
    }
    navigate("/");
  }


  const correoVerificar = async () => {
    if (codigoCorreo.trim() == "") {
      toast.error("El código del correo es requerido")
      return;
    }

    setIsLoading(true);
    let { payload } = await dispatch(validarCorreo(codigoCorreo));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      dispatch(obtenerUsuarioActual());
      setIsLoading(false);

    } else {
      toast.error(payload.message);
      setIsLoading(false);
    }
  }


  const telefonoVerificar = async () => {
    if (codigoTelefono.trim() == "") {
      toast.error("El código del teléfono es requerido");
      return;
    }

    let { payload } = await dispatch(validarTelefono(codigoTelefono));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      dispatch(obtenerUsuarioActual());
      setIsLoadingTel(false);

    } else {
      toast.error(payload.message);
      setIsLoadingTel(false);
    }

  }



  const correoActualizar = async (data) => {
    if (data.correo == usuario.correoElectronico) {
      setOpenModalCorreo(false);
      return;
    }


    let { payload } = await dispatch(actualizarCorreo({
      correo: data.correo
    }));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      dispatch(verificarCorreo());
      dispatch(obtenerUsuarioActual());
      setOpenModalCorreo(false);
    } else {
      toast.error(payload.message);
    }
  }
  const telefonoActualizar = async (data) => {


    if (data.telefonoPrefijoId == usuario.telefonoPrefijoId && data.telefonoSufijo == usuario.telefonoSufijo) {
      setOpenModalTelefono(false);
      return;
    }

    let { payload } = await dispatch(actualizarTelefono({
      telefonoPrefijoId: data.telefonoPrefijoId,
      telefonoSufijo: data.telefonoSufijo,

    }));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      dispatch(verificarTelefono());
      dispatch(obtenerUsuarioActual());
      setOpenModalTelefono(false);
    } else {
      toast.error(payload.message);
    }
  }

  return (<div className='rootVerify '>
    <AppBar />

    <div className="contentVerify">

      <div className="grid-container col-2 col-md col-sm-12 ">
        <div>
          <h1>CENFOTEC Admisiones</h1>
          <p>Se ha enviado un código de confirmación a su <br /> correo electrónico y teléfono.</p>
          <p id="segundo">Si usted no ha recibido el código puede modificar su <br/>correo electrónico y/o número telefónico</p>

          {correoValidado ? (
            <>
              <div className="mt-2">
                <div className="item-title">
                  <i className='greenIcon material-icons'></i>
                  <label>Correo Electrónico</label>
                </div>
                <div className="mt-2">
                  <span className="span-message success" >Correo verificado.</span>
                </div>
              </div>
            </>)
            : (
              <>
                <div className="grid-container col-2 col-lg col-sm-12">
                  <div className="grid-item">
                    <div className="item-title">
                      <i className='redIcon material-icons'></i>
                      <label>Correo electrónico</label>
                    </div>
                    <span className="link-text" onClick={() => setOpenModalCorreo(true)}>{usuario.correoElectronico} <i className='material-icons'></i></span>
                  </div>
                  <div className="grid-item mt-2">
                    <div className="inline-control">
                      <input type="text" className="form-control mr-2" placeholder="Ingrese el código" onInput={(e) => { setCodigoCorreo(e.target.value) }} />
                      <button onClick={correoVerificar} disabled={isLoading} className="verify-button"> Verificar</button>
                      {isLoading && <div className="ml-1">
                        <LoadingSpiner
                          styles={{
                            "width": "25px",
                            "height": "25px",
                            "border": "6px solid #bbbbbb",
                            'borderTop': "6px solid #035cc9",
                          }} />
                      </div>}

                    </div>

                  </div>
                </div>
                <div className="mt-2">
                  <span className="span-message" >Verifique que el correo se encuentre en no deseados.</span>
                </div>

              </>)}


          <hr />

          {telefonoValidado ? (
            <>
              <div className="mt-2">
                <div className="item-title">
                  <i className='greenIcon material-icons'></i>
                  <label>Teléfono móvil</label>
                </div>
                <div className="mt-2">
                  <span className="span-message success" >Teléfono verificado.</span>
                </div>
              </div>
            </>)
            : (
              <>
                <div className="grid-container col-2 col-lg col-sm-12">
                  <div className="grid-item">
                    <div className="item-title">
                      <i className='redIcon material-icons'></i>
                      <label>Teléfono móvil</label>
                    </div>
                    <span className="link-text" onClick={() => setOpenModalTelefono(true)} >+{usuario.telefono}<i className='material-icons'></i></span>
                  </div>
                  <div className="grid-item mt-2">
                    <div className="inline-control">
                      <input type="text" className="form-control mr-2" placeholder="Ingrese el código" onInput={(e) => { setCodigoTelefono(e.target.value) }} />
                      <button onClick={telefonoVerificar} disabled={isLoadingTel} className="verify-button"> Verificar</button>
                      {isLoadingTel && <div className="ml-1">
                        <LoadingSpiner
                          styles={{
                            "width": "25px",
                            "height": "25px",
                            "border": "6px solid #bbbbbb",
                            'borderTop': "6px solid #035cc9",
                          }} />
                      </div>}
                    </div>

                  </div>
                </div>
              </>
            )}

          <div className="mt-2">
            <button className='buttonVerify' onClick={finalizarVerificacion}>Finalizar</button>
            {!correoValidado || !telefonoValidado ?
              <button className='buttonVerify buttonOmitir' onClick={omitirVerificacion}>Omitir</button>
              : null
            }
          </div>
        </div>

        <div className="imgContainer">
          <img className='imgVerify'
            src={ContactoCenfotec}
            alt="Imagen de verificación de contacto"
          />
        </div>

      </div>



    </div>

    <Modal
      estado={openModalCorreo}
      cambiarEstado={() => {
        setOpenModalCorreo(false)
      }}
      titulo="Editar correo"
      clase="small"
      buttonGroup={
        <>
          <button type="submit" className="button-text" onClick={handleSubmitModal(correoActualizar)}>
            Actualizar
        </button>
        </>
      }
    >
      <form >
        <div>

          <div className="form-group">
            <label>Correo electrónico</label>
            <input name="correo" className="form-control" defaultValue={usuario.correoElectronico} {...registerModal("correo",
              { required: "Campo requerido", pattern: { value: /^\S+@\S+$/i, message: "Escriba un correo valido" } })} />
            {errors.correo && <p className="error">{errors.correo.message}</p>}
          </div>

        </div>

      </form>
    </Modal>
    <Modal
      estado={openModalTelefono}
      cambiarEstado={() => {
        setOpenModalTelefono(false)
      }}
      titulo="Editar teléfono"
      clase="small"
      buttonGroup={
        <>
          <button type="submit" className="button-text" onClick={handleSubmitModal(telefonoActualizar)}>
            Actualizar
        </button>
        </>
      }
    >
      <form >
        <div>

          <div className="form-group">
            <label>Teléfono móvil</label>
            <div className="d-flex flex-gap-2">
              <div className="select-container">

                <ReactSelect
                  name="telefonoPrefijoId"
                  control={control}
                  lista={lista}
                  rules={{ required: "Campo requerido" }}
                />

              </div>
              <input id="inputTel" className="form-control"
                type="text" {...registerModal("telefonoSufijo", {
                  required: "Campo requerido"
                })} />
            </div>
            {errors.telefonoPrefijoId && <p className="error">{errors.telefonoPrefijoId.message}</p>}
            {errors.telefonoSufijo && <p className="error">{errors.telefonoSufijo.message}</p>}
          </div>

        </div>

      </form>
    </Modal>

  </div>
  )
}