import { configureStore } from '@reduxjs/toolkit';
import SolicitudReducer from './solicitudesSlice';
import SolicitudAdmReducer from './solicitudesAdmSlice';
import GenericosSlice from './genericosSlice';
import plantillasSlice from './plantillasSlice';
import perfilSlice from './perfilSlice';
import usuariosSlice from './usuariosSlice';
import carrerasSlice from './carrerasSlice';
import tiposFinanciamientosSlice from './tiposFinanciamientosSlice';
import razonesSlice from './razonesSlice';
import formasSlice from './formasSlice';
import periodosSlice from './periodosSlice';
import tiposOrganizacionesSlice from './tiposOrganizacionesSlice';
import lugaresSlice from './lugaresSlice';
import perfilesDocumentosTiposSlice from './perfilesDocumentosTiposSlice';
import asesoresSlice from './asesoresSlice';
import configuracionesSlice from './configuracionesSlice';
import centrosEducativosSlice from './centrosEducativosSlice';
import tiposPlantillas from './plantillasTiposSlice';

export default configureStore({
  reducer: {
    solicitudes: SolicitudReducer,
    solicitudesAdm: SolicitudAdmReducer,
    genericos: GenericosSlice,
    plantillas: plantillasSlice,
    perfiles: perfilSlice,
    usuarios: usuariosSlice,
    carreras: carrerasSlice,
    tiposFinanciamientos: tiposFinanciamientosSlice,
    razones: razonesSlice,
    formas: formasSlice,
    periodos: periodosSlice,
    tiposOrganizaciones: tiposOrganizacionesSlice,
    lugares: lugaresSlice,
    perfilesDocumentosTipos: perfilesDocumentosTiposSlice,
    asesores: asesoresSlice,
    configuraciones: configuracionesSlice,
    centrosEducativos: centrosEducativosSlice,
    tiposPlantillas: tiposPlantillas,
  },
});
