import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function FileContainer(props) {
  const { register, watch, setValue, errors } = useFormContext();
  const element = watch(props.name);
  const [titulo, setTitulo] = useState();
  useEffect(() => {
    cargarArchivo();
    if (props.titulo){
      setTitulo(props.titulo)
    }
  }, [element,props.titulo]);

  const cargarArchivo = async(e) => {
    let archivo= e?.target.files[0];
    if (typeof e !== "undefined") {
          setValue(props.name, archivo);
          setTitulo(archivo.name);
      }
    }

    
  return (
    <>
      {element == null || element == undefined ? (
        <>
          <div className="div-relative">
            <input
              className="form-control fileInput"
              id={`${props.name}`}
              accept={props.acepted}
              type="file"
              onInput={(e) => cargarArchivo(e)}
              {...register(props.name, { required: "Campo requerido" })}
            />
            <label htmlFor={`${props.name}`} className="label-ins">
              Examinar...
            </label>
          </div>
          {props.errorModel != undefined ? (
            <p className="error">{props.errorModel.message}</p>
          ) : (
            errors[props.name] && (
              <p className="error">{errors[props.name]?.message}</p>
            )
          )}
        </>
      ) : (
        <>
          <div className="div-relative">
            <div className="file-content">
              <span>{titulo}</span>
                <i className="material-icons"  onClick={() => {
                  setValue(props.name, null);
                }}></i>
            </div>
          </div>
        </>
      )}
    </>
  );
}
