import React from 'react';

function FileInfo({ item, onClick, descargar, verImagen }) {
  const fileIcon = require('../assets/file_icon.png');

  return (
    <div className="file-container">
      <a
        role={'button'}
        onClick={() => descargar(item.archivoId, item.archivoNombre)}
      >
        <img src={fileIcon} width={20} height={20} />
        <span>{item.archivoNombre}</span>
      </a>
      <i
        className="material-icons"
        onClick={() => {
          onClick(item);
        }}
      >
        
      </i>
      <i
        className="material-icons blue"
        onClick={() => {
          verImagen(item.archivoId);
        }}
      >
        
      </i>
    </div>
  );
}

export default FileInfo;
