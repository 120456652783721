import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import Modal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerNivelesAcademicosAdm,
  cambiarOrdenNivelAcademico,
  cambiarEstadoNivelAcademico,
} from "../../redux/carrerasSlice";
import { toast } from "react-toastify";
import WindowDimension from "../../Hooks/WindowDimension";
import OperationStatus from "../../config/OperationStatus";
import ModalRemove from "./ModalRemove";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";

const columns = [
  { label: "Código", accessor: "nivelId", sortable: true },
  { label: "Nombre", accessor: "nivelAcademicoNombre", sortable: true },
  { label: "Orden", accessor: "orden", sortable: true },
  {
    label: "Estado",
    accessor: "estadoNombre",
    sortable: true,
    render: (item) => (
      <td>
        <span className={item.estadoId == 4 ? "success-d" : "danger-d"}>
          {item.estadoNombre}
        </span>
      </td>
    ),
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function NivelesAcademicos(props) {
  const [removeState, changeRemoveState] = useState(false);
  const [registerSelected = [], selectReg] = useState();
  const [showModal, setShowModal] = useState(false);
  const { width } = WindowDimension();

  const dispatch = useDispatch();

  const { nivelesAcademicos } = useSelector((state) => state.carreras);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const showModalEdit = (item) => {
    reset(item);
    setShowModal(true);
  };

  const closeModalEdit = () => {
    setShowModal(false);
  };

  const cargarNiveles = () => {
    dispatch(obtenerNivelesAcademicosAdm());
  };

  const onSubmit = async (data) => {
    let { payload } = await dispatch(cambiarOrdenNivelAcademico(data));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      closeModalEdit();
      cargarNiveles();
      toast.success(payload.message);
    } else {
      toast.error(payload.message);
    }
  };

  const activacion = async (estado, estatus) => {
    let { payload } = await dispatch(
      cambiarEstadoNivelAcademico({
        id: registerSelected.nivelId,
        estadoId: estado,
      })
    );
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      cargarNiveles();
      changeRemoveState(false);
    }
  };

  useEffect(() => {
    cargarNiveles();
  }, [dispatch]);

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <div className="header-admin-bar-items">
          <h2>Niveles académicos</h2>
        </div>
      </div>

      <div className="table-container">
        {nivelesAcademicos.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={nivelesAcademicos}
              clase="table-adm"
              renderActionItems={(item) => {
                return (
                  <td key={Math.random()}>
                    <br />
                    <button
                      className="modBtn text-primary "
                      onClick={() => {
                        showModalEdit(item);
                      }}
                    >
                      <i className="material-icons"></i>
                    </button>

                    {item.estadoId === 4 ? (
                      <button className="modBtn text-primary">
                        <i
                          className="material-icons statusRed"
                          onClick={() => {
                            selectReg(item);
                            changeRemoveState(true);
                          }}
                        >
                          
                        </i>
                      </button>
                    ) : (
                      <button className="modBtn text-primary ">
                        <i
                          className="material-icons statusGreen"
                          onClick={() => {
                            selectReg(item);
                            changeRemoveState(true);
                          }}
                        >
                          
                        </i>
                      </button>
                    )}
                  </td>
                );
              }}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={"Editar"}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <label>Orden</label>
            <Required />
            <input
              className="form-control"
              type="number"
              {...register("orden", { required: "Campo requerido" })}
            />
            {errors.orden && <p className="error">{errors.orden.message}</p>}
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={changeRemoveState}
        title={
          registerSelected.estadoId === 4
            ? "Desactivar nivel académico"
            : "Activar nivel académico"
        }
        message={
          registerSelected.estadoId === 4
            ? "¿Desea desactivar el siguiente nivel académico?"
            : "¿Desea activar el siguiente nivel académico?"
        }
        item={registerSelected.carreraNombre}
        icon={
          registerSelected.estadoId === 5 ? (
            <i className="material-icons info"></i>
          ) : null
        }
        color={registerSelected.estadoId === 5 ? "activate-item" : null}
        btnGroup={
          <>
            <button
              className="button-text text-red"
              onClick={() =>
                activacion(registerSelected.estadoId === 4 ? 5 : 4)
              }
            >
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                changeRemoveState(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default NivelesAcademicos;
