import React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

function FileViewer(props) {
  return (
    <div className={props.estado ? 'overlay active' : 'overlay'}>
      <div className={`full-screen`}>
        <button onClick={props.cambiarEstado} className="btn-close-fullmodal">
          <i className="material-icons"></i>
        </button>
        <DocViewer
          documents={props.docs}
          pluginRenderers={DocViewerRenderers}
        />
      </div>
    </div>
  );
}

export default FileViewer;
