import React from "react";
import "../Styles/detalleSolicitud.css";

function DetailChildSection({ title, isInitialOpened = false, children }) {
  const showInfo = (event) => {
    event.preventDefault();
    if (
      event.target.classList.contains("mainTitleBtn") ||
      event.target.classList.contains("title-btn")
    ) {
      event.target.nextElementSibling.classList.toggle("showing");
    } else {
      event.target.classList.toggle("showing");
    }

    event.target.closest(".divDisplay").classList.toggle("invisibleContent");
  };
  return (
    <div>
      <div
        className={`divDisplay invisibleContent ${
          isInitialOpened ? "" : "invisibleContent"
        }`}
      >
        <a className="title-btn" role="button" onClick={showInfo}>
          <h4>{title}</h4>
        </a>
        <button className="displayBtn" onClick={showInfo}>
          <i className="material-icons up"></i>
          <i className="material-icons down"></i>
        </button>
      </div>
      <div className="dataContainer">{children}</div>
    </div>
  );
}

export default DetailChildSection;
