import React, { useState, useEffect } from "react";
import PersonalInfo from "../Components/PersonalInfo";
import ContactInfo from "../Components/ContactInfo";
import MedicalInfo from "../Components/MedicalInfo";
import FinancialInfo from "../Components/FinancialInfo";
import EstudiosInfo from "../Components/EstudiosInfo";
import Inscripcion from "../Components/Inscripcion";
import Loading from "../Components/Loading";
import "../css/nuevaSolicitud.css";
import { useDispatch, useSelector } from "react-redux";

import { obtenerSolicitudEstado } from "../../redux/solicitudesSlice";

import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import Archivos from "../Components/Archivos";
import { toast } from "react-toastify";

const steps = [
  { id: 1, titulo: "Info personal", icono: "", url: "InfoPersonal" },
  { id: 2, titulo: "Contacto", icono: "", url: "InfoContacto" },
  { id: 3, titulo: "Info médica", icono: "", url: "InfoMedica" },
  { id: 4, titulo: "Info financiera", icono: "", url: "InfoFinanciera" },
  { id: 5, titulo: "Estudios", icono: "", url: "InfoEstudios" },
  { id: 6, titulo: "Inscripción", icono: "", url: "InfoInscripcion" },
  { id: 7, titulo: "Documentos", icono: "", url: "InfoDocumentos" },
];

function SolicitudInscripcion() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { solicitudEstado } = useSelector((state) => state.solicitudes);

  const etapaActual = solicitudEstado.result?.etapa;

  const [stepActual, setStepActual] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    dispatch(obtenerSolicitudEstado(id));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    if (etapaActual) {
      if (!solicitudEstado.result?.autorizado) {
        toast.error(solicitudEstado.message);
        navigate("/");
      }
      if (solicitudEstado.result?.estadoId == 9) {
        navigate("/");
      } else {
        let tab = steps.find((a) => a.id == etapaActual);
        navigate(`/Solicitud/${id}/${tab.url}`);
        setStepActual(etapaActual);
      }
    }
  }, [etapaActual]);

  const handledTabClick = (item) => {
    navigate(`${item.url}`);
  };

  const toggle = (tab) => {
    navigate(`/Solicitud/${id}/${tab}`);
  };

  const verifyURL = (url) => {
    let a = location.pathname;
    var x = a.split("/");
    return x[3] === url;
  };

  const validarEtapaActual = (item) => {
    let res = false;

    //&& solicitudEstado.result?.estadoId !== 8
    if (stepActual >= item.id) {
      res = true;
    }
    return res;
  };

  const actualizarEtapaActual = (etapa) => {
    setStepActual(etapa);
  };

  return (
    <>
      <Loading
        clase={loading ? "loadingContainer" : "loadingContainer loadingHidden"}
      />
      <div className="principal-solicitud">
        <div className="steps">
          {steps.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => handledTabClick(item)}
                className={`${verifyURL(item.url) ? "actualStep" : ""} ${
                  validarEtapaActual(item) ? "isActive" : ""
                }`}
              >
                <i className="material-icons">{item.icono}</i>
                <div>
                  <label>{item.titulo}</label>
                  <label className="stepDesc">
                    Paso {i + 1}/{steps.length}
                  </label>
                </div>
              </div>
            );
          })}
        </div>

        <div className="container-form">
          <Routes>
            <Route
              path={"InfoPersonal"}
              element={
                <PersonalInfo
                  handleStepChange={(item) => actualizarEtapaActual(item)}
                  solicitudId={id}
                  onGoNext={() => {
                    toggle("InfoContacto");
                  }}
                />
              }
            />
            <Route
              exact
              path="InfoContacto"
              element={
                <ContactInfo
                  handleStepChange={(item) => actualizarEtapaActual(item)}
                  solicitudId={id}
                  onGoBack={() => {
                    toggle("InfoPersonal");
                  }}
                  onGoNext={() => {
                    toggle("InfoMedica");
                  }}
                />
              }
            />
            <Route
              exact
              path="InfoMedica"
              element={
                <MedicalInfo
                  handleStepChange={(item) => actualizarEtapaActual(item)}
                  solicitudId={id}
                  onGoBack={() => {
                    toggle("InfoContacto");
                  }}
                  onGoNext={() => {
                    toggle("InfoFinanciera");
                  }}
                />
              }
            />
            <Route
              exact
              path="InfoFinanciera"
              element={
                <FinancialInfo
                  handleStepChange={(item) => actualizarEtapaActual(item)}
                  solicitudId={id}
                  onGoBack={() => {
                    toggle("InfoMedica");
                  }}
                  onGoNext={() => {
                    toggle("InfoEstudios");
                  }}
                />
              }
            />
            <Route
              exact
              path="InfoEstudios"
              element={
                <EstudiosInfo
                  handleStepChange={(item) => actualizarEtapaActual(item)}
                  solicitudId={id}
                  onGoBack={() => {
                    toggle("InfoFinanciera");
                  }}
                  onGoNext={() => {
                    toggle("InfoInscripcion");
                  }}
                />
              }
            />

            <Route
              exact
              path="InfoInscripcion"
              element={
                <Inscripcion
                  handleStepChange={(item) => actualizarEtapaActual(item)}
                  solicitudId={id}
                  onGoBack={() => {
                    toggle("InfoEstudios");
                  }}
                  onGoNext={() => {
                    navigate("InfoDocumentos");
                  }}
                />
              }
            />
            <Route
              exact
              path="InfoDocumentos"
              element={
                <Archivos
                  solicitudId={id}
                  onGoBack={() => {
                    toggle("InfoInscripcion");
                  }}
                  onGoNext={() => {
                    toggle("/");
                  }}
                />
              }
            />
            <Route path="*" element={<Navigate to="InfoPersonal" replace />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default SolicitudInscripcion;
