import React, { useEffect, useState } from 'react';
import '../css/registro.css';
import AppBar from '../Components/AppBar';
import Logo from '../../SVG/Logocircular.svg';
import ProgressBar from '../Components/ProgressBar';
import LoadingSpiner from '../../Components/LoadingSpiner';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import autService from '../../services/authService';
import ReactSelect from '../../Components/ReactSelect';
import OperationStatus from '../../config/OperationStatus';
import WebApi from '../../Api/WebApi';
import { reiniciarContrasena } from '../../redux/usuariosSlice';
import Required from '../../Components/Required';

export default function ReiniciarContrasena() {
  const generos = [];
  const {
    register,
    trigger,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contrasena: '',
      confirmacionContrasena: '',
      token: '',
    },
  });

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const contrasena = watch('contrasena');

  const onSubmit = async (data) => {
    data.token = searchParams.get('q');
    let { payload } = await dispatch(reiniciarContrasena(data));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      setSuccess(true);
      toast.success(payload.message);
    } else {
      toast.error(payload.message);
    }
  };

  const passwordVerify = (e) => {
    return contrasena === e;
  };

  return (
    <div className="reset-container">
      <div className="reset-card">
        <div>
          <div className="mt-4">
            <img
              onClick={() => {
                navigate('/Login');
              }}
              className="reset-image"
              src={Logo}
              alt="Logo"
            />
          </div>

          {!success && <span className="subtitle">Nueva contraseña</span>}
        </div>
        <div className="reset-form-container">
          {!success ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2">
                <span className="spanInfo">
                  Escribe tu correo electrónico para enviarte el enlace de
                  restauración de contraseña
                </span>
              </div>

              <div className="form-group">
                <label>Contraseña</label>
                <Required />
                <input
                  className="form-control"
                  autoComplete="new-password"
                  type="password"
                  {...register('contrasena', { required: 'Campo requerido' })}
                />
                {errors.contrasena && (
                  <p className="error">{errors.contrasena.message}</p>
                )}
              </div>
              <div className="form-group">
                <label>Confirmar contraseña</label>
                <Required />
                <input
                  className="form-control"
                  type="password"
                  autoComplete="new-password"
                  {...register('confirmacionContrasena', {
                    required: 'Campo requerido',
                    validate: passwordVerify,
                  })}
                />
                {errors.confirmacionContrasena && (
                  <p className="error">
                    {errors.confirmacionContrasena.message}
                  </p>
                )}
                {errors?.confirmacionContrasena?.type === 'validate' && (
                  <p className="error">Las contraseñas no coinciden</p>
                )}
              </div>
              <input type="submit" id="btnForm" value="Confirmar" />
            </form>
          ) : (
            <div className="mt-4">
              <span className="subtitle">Contraseña restablecida</span>

              <button
                type="button"
                id="btnForm"
                onClick={() => {
                  navigate('/Login');
                }}
              >
                {' '}
                Volver al inicio
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
