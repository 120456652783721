
export default function NavLinks() {
    return (
        <ul className="nav-ul">
            <li><a href="https://ucenfotec.ac.cr/" target="_blank" >Página principal</a></li>
            <li><a href="https://ucenfotec.ac.cr/carreras/" target="_blank">Oferta académica</a></li>
            <li><a href="https://desk.zoho.com/portal/cenfotec868/es/home" target="_blank">Preguntas frecuentes</a></li>
        </ul>
    )
}
