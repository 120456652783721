import React, { useEffect } from "react";
import Select from "react-select";
import { useController } from "react-hook-form";

export default function ReactSelect({
  rules = {},
  control,
  lista = [],
  name,
  disabled = false,
  height = 34,
}) {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#fcfafa",
      // Overwrittes the different states of border
      borderColor: "#c1c1c1",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      borderRadius: 10,
      fontFamily: "circularBook",
      fontSize: "14px",
      height: height,
      minHeight: height,
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "circularBook",
      zIndex: 9999,
    }),
  };

  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules: rules,
  });

  useEffect(() => {
    const newFirstElement = { label: "Seleccione", value: undefined };
    if (lista.length > 0) {
      if (lista[0].value !== undefined) lista.unshift(newFirstElement);
    }
  }, [lista]);

  return (
    <Select
      isDisabled={disabled}
      styles={customStyles}
      inputRef={ref}
      classNamePrefix="addl-class"
      placeholder="Seleccione"
      options={lista}
      noOptionsMessage={() => "Sin resultados"}
      value={lista.find((c) => c.value === value) || ""}
      onChange={(val) => onChange(val.value)}
    />
  );
}
