import React, { useEffect } from "react";
import { useRef } from "react";
import WindowDimension from "../../Hooks/WindowDimension";

function Charts(props) {
  const { width } = WindowDimension();

  const googleChart = useRef();

  useEffect(() => {
    if (props.google) {
      let colors = [
        "#B3E1F9",
        "#006AEA",
        "#00479B",
        "#16B2BF",
        "#68A1FF",
        "#A0C0CE",
        "#4FC3F7",
        "#16B2BF",
        "006064",
      ];

      var data = props.google.visualization.arrayToDataTable(props.array);

      var options = {
        title: props.title,
        width: "100%",
        height: "300",
        backgroundColor: { fill: "transparent" },
        colors,
        titleTextStyle: {
          color: "#07479B",
          fontName: "circularBlack",
          fontSize: "14",
          bold: true,
        },
      };

      if (props.chartType === "pie") {
        var chart = new props.google.visualization.PieChart(
          googleChart.current
        );
        chart.draw(data, options);
      } else if (props.chartType === "columnBar") {
        var chartColumn = new props.google.visualization.ColumnChart(
          googleChart.current
        );
        chartColumn.draw(data, options);
      } else {
        var chartBar = new props.google.visualization.BarChart(
          googleChart.current
        );
        chartBar.draw(data, options);
      }
    }
  }, [
    props.google,
    props.array,
    props.chartType,
    props.title,
    props.encabezado,
    width,
  ]);

  return <div ref={googleChart}></div>;
}

export default Charts;
