import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpiner from "../../Components/LoadingSpiner";
import Modal from "../../Components/SuccessModal";
import WindowDimension from "../../Hooks/WindowDimension";
import OperationStatus from "../../config/OperationStatus";
import {
  agregarSolicitudInfoDocumento,
  finalizarSolicitud,
  obtenerSolicitudInfoDocumentos,
} from "../../redux/solicitudesSlice";
import Signer from "./Signer";
import UploadBtn from "./UploadBtn";
import Required from "../../Components/Required";

function Archivos(props) {
  const dispatch = useDispatch();
  const { solicitudModel } = useSelector((state) => state.solicitudes);
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const { width } = WindowDimension();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(
    "¡Su solicitud fue completada con éxito!"
  );

  let navigate = useNavigate();

  const esMayor = true;

  useEffect(() => {
    dispatch(obtenerSolicitudInfoDocumentos(props.solicitudId));
  }, []);

  useEffect(() => {
    //TODO: Verificar si viene el nombre del perfil
    if (solicitudModel) {
      reset(solicitudModel);
      setValue("aceptaTerminos", solicitudModel.aceptaTerminos);
      setValue("firmaId", solicitudModel.firmaId);
      setValue("firma", dataURLtoFile(solicitudModel.firma, "firma"));
    }
  }, [solicitudModel]);

  const isValid = () => {
    let docs = solicitudModel.documentos;
    let isValid = docs?.some((a) => a.esObligatorio && !a.entregado);
    return !isValid;
  };

  const handleFileUpload = async (file, item) => {
    if (file) {
      setIsLoading(true);
      var fData = new FormData();
      fData.append("TipoDocumentoId", item.documentoId);
      fData.append("PerfilId", item.perfilId);
      fData.append("SolicitudId", props.solicitudId);
      fData.append("File", file);

      let { payload } = await dispatch(agregarSolicitudInfoDocumento(fData));

      if (payload.operationStatus === OperationStatus.SUCCESS) {
        setIsLoading(false);
        toast.success(payload.message);
        dispatch(obtenerSolicitudInfoDocumentos(props.solicitudId));
      } else {
        setIsLoading(false);
        toast.error(payload.message);
      }
    }
  };
  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (object[key] != null) formData.append(key, object[key]);
    });
    return formData;
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();

    data.firma = dataURLtoFile(data.firma);

    data.solicitudId = props.solicitudId;
    let formData = getFormData(data);

    if (isValid()) {
      let { payload } = await dispatch(finalizarSolicitud(formData));
      if (payload.operationStatus === OperationStatus.SUCCESS) {
        setShowModal(true);
        setTimeout(() => {
          navigate("/");
        }, 4000);
      } else {
        toast.error(payload.message);
      }
    } else {
      toast.error("Faltan archivos por subir");
    }
  };
  const isMobile = () => {
    return width < 768;
  };

  function dataURLtoFile(dataurl, filename) {
    if (typeof dataurl == "string") {
      var arr = dataurl?.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr?.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr?.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return dataurl;
    }
  }

  return (
    <>
      <Modal
        estado={showModal}
        titulo="¡Solicitud enviada!"
        texto={message}
        clase={`${width < 576 ? "full-width" : "small"}`}
      ></Modal>

      <LoadingSpiner
        show={isLoading}
        fullPage={true}
        styles={{
          top: "45%",
          left: "50%",
          position: "inherit",
          width: "50px",
          height: "50px",
          borderWidth: "10px",
          borderStyle: "solid",
          borderColor: "rgb(27 78 147) rgb(0 0 0) rgb(0 0 0)",
          borderImage: "initial",
        }}
      />
      <FormProvider
        watch={watch}
        register={register}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="form-solicitud">
          <div className="grid-container container col-md col-lg">
            <div>
              <div className="d-flex space-between">
                <div className="title-container">
                  <span className="form-title text-center">
                    Documentos requeridos{" "}
                  </span>
                  <span className="sub-title d-block">
                    Perfil: {solicitudModel.perfilNombre}
                  </span>
                </div>
                <span
                  className="sub-title d-block"
                  style={{
                    maxWidth: "400px",
                    color: "rgb(176 115 0)",
                    fontWeight: "600",
                  }}
                >
                  Los tipos de documentos marcados con el mismo color están
                  relacionados. Debe aportar al menos uno de ellos.
                </span>
              </div>
              <br />
              <div className="grid-container fix-width">
                <table className="table-archivos">
                  <thead>
                    <tr>
                      <th>Tipo de documento</th>
                      {!isMobile() && <th>Descripción</th>}
                      <th>Tipos permitidos</th>
                      <th>Entregado</th>
                      <th>Prórroga</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {solicitudModel?.documentos?.length > 0 ? (
                      solicitudModel?.documentos?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span className="d-flex">
                              <i
                                className="material-icons icon-file-group"
                                style={{ color: `${item.color ?? "white"}` }}
                              >
                                
                              </i>

                              {item.tipoDocumento}
                              {item.esObligatorio && (
                                <>
                                  <Required />
                                </>
                              )}
                            </span>
                            {isMobile() && (
                              <span
                                data-tooltip={item.descripcion}
                                data-tooltip-location="right"
                              >
                                <i className="material-icons info-tooltip"></i>
                              </span>
                            )}
                          </td>
                          {!isMobile() && <td>{item.descripcion}</td>}
                          <td>{item.tiposPermitidos}</td>
                          <td>
                            {item.entregado ? (
                              <>
                                <i className="material-icons success"></i>
                              </>
                            ) : (
                              <>
                                <i className="material-icons danger"></i>
                              </>
                            )}
                          </td>
                          <td>
                            {item.prorroga ? <>{item.prorroga}</> : <>-</>}
                          </td>
                          <td>
                            <UploadBtn
                              title={
                                !item.entregado
                                  ? "Subir archivo"
                                  : "Cambiar archivo"
                              }
                              handleFile={(el) => {
                                handleFileUpload(el, item);
                              }}
                              accepted={item.tiposPermitidos}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">Sin documentos requeridos</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="d-flex space-between">
                  <a
                    role="button"
                    className="button-text "
                    target="_blank"
                    href="https://ucenfotec.ac.cr/requisitos-de-admision/"
                  >
                    Ver requisitos de admisión
                  </a>
                </div>
              </div>

              <div className="grid-container ">
                <div className="grid-item">
                  <span className="info-text">
                    Los documentos aquí solicitados deben adjuntarse como
                    requisito para tramitar y aprobar su inscripción. En caso de
                    no contar con alguno de ellos, el sistema le permitirá
                    enviar la solicitud y posteriormente le serán solicitados
                    por el asesor de crecimiento profesional asignado a su caso.
                    Gracias
                  </span>
                </div>
              </div>

              <div className="grid-container col-2 col-lg-2 col-md-2 col-sm-12">
                <div className="grid-item canvas-container">
                  <div className="form-group">
                    <label>
                      {esMayor ? "Firma" : "Firma de representante legal"}
                    </label>
                    <Required />

                    <Signer
                      name="firma"
                      control={control}
                      rules={{ required: "Campo requerido" }}
                      setValue={setValue}
                      valor={solicitudModel.firma}
                    />
                    {errors.firma && (
                      <p className="error">{errors.firma.message}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid-container ">
                <div className="grid-item">
                  <div className="terms">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: solicitudModel.terminosCondiciones,
                      }}
                    ></div>
                    <div>
                      <input
                        type="checkbox"
                        {...register("aceptaTerminos", {
                          required: "Campo requerido",
                        })}
                      />
                      <label>Acepto los términos</label>
                      {errors.aceptaTerminos && (
                        <p className="error">{errors.aceptaTerminos.message}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="button-control">
              <div>
                <button type="submit">Enviar solicitud</button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default Archivos;
