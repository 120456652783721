import React, { useState, useEffect } from "react";
import infoIMG from "../../SVG/InformacionmedicaCenfotec.svg";
import { useForm } from "react-hook-form";
import {
  obtenerSolicitudInfoMedica,
  agregarSolicitudInfoMedica,
} from "../../redux/solicitudesSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import OperationStatus from "../../config/OperationStatus";
import Required from "../../Components/Required";

export default function MedicalInfo(props) {
  const dispatch = useDispatch();
  const { solicitudModel } = useSelector((state) => state.solicitudes);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({});
  const tieneEnfermedad = watch("tieneEnfermedad");

  useEffect(() => {
    dispatch(obtenerSolicitudInfoMedica(props.solicitudId));
  }, []);
  useEffect(() => {
    if (solicitudModel) {
      if (solicitudModel.infoMedicaModificado) {
        setValue("tieneEnfermedad", solicitudModel.tieneEnfermedad);
      }
      setValue("enfermedadNombre", solicitudModel.enfermedadNombre);
      setValue(
        "contactoEmergenciaNombre",
        solicitudModel.contactoEmergenciaNombre
      );
      setValue(
        "contactoEmergenciaTelefono",
        solicitudModel.contactoEmergenciaTelefono
      );
      setValue("medicamentos", solicitudModel.medicamentos);
      setValue("solicitudId", solicitudModel.solicitudId);
      setValue("etapaActual", solicitudModel.etapaActual);
    }
  }, [solicitudModel]);

  useEffect(() => {
    if (tieneEnfermedad != undefined || tieneEnfermedad != null) {
      let value = convertToBoolean(tieneEnfermedad);
      setValue("tieneEnfermedad", value);
    }
  }, [tieneEnfermedad]);

  const onSubmit = async (data) => {
    data.tieneEnfermedad = tieneEnfermedad;
    if (!tieneEnfermedad) {
      data.enfermedadNombre = "";
    }

    if (data.etapaActual === 3) data.etapaActual += 1;

    data.solicitudId = props.solicitudId;

    let { payload } = await dispatch(agregarSolicitudInfoMedica(data));
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      props.onGoNext();
      props.handleStepChange(data.etapaActual);
    } else {
      toast.error(payload.message);
    }
  };
  const isRequired = (e) => {
    if (convertToBoolean(tieneEnfermedad)) return e.trim() != "";
    return true;
  };
  const isValid = (e) => {
    return typeof convertToBoolean(e) == "boolean";
  };

  const convertToBoolean = (myString) => {
    if (myString != undefined) {
      let myBool = myString.toString().toLowerCase() === "true";
      return myBool;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-solicitud">
      <div className="form-c">
        <div className="title-container">
          <span className="form-title">Información médica</span>
        </div>
        <br />
        <div className="grid-container col-2 container">
          <div>
            <div className="grid-container col-2 col-lg-2">
              <div className="grid-item">
                <div className="form-group">
                  <label>¿Padece de alguna enfermedad o alergia?</label>
                  <Required />
                  <div className="check-container">
                    <div className="form-control-check inline">
                      <input
                        type="radio"
                        {...register("tieneEnfermedad", { validate: isValid })}
                        value={true}
                        checked={tieneEnfermedad === true}
                      />
                      <label>Si</label>
                    </div>
                    <div className="form-control-check inline">
                      <input
                        type="radio"
                        {...register("tieneEnfermedad", { validate: isValid })}
                        value={false}
                        checked={tieneEnfermedad === false}
                      />
                      <label>No</label>
                    </div>
                    {tieneEnfermedad && (
                      <input
                        className="form-control"
                        id="inputAlergia"
                        placeholder="Especifique"
                        disabled={tieneEnfermedad != true}
                        type="text"
                        {...register("enfermedadNombre", {
                          validate: isRequired,
                        })}
                      />
                    )}
                  </div>
                  {errors.tieneEnfermedad?.type === "validate" && (
                    <p className="error">Campo requerido</p>
                  )}
                  {errors?.enfermedadNombre?.type === "validate" && (
                    <p className="error">Campo requerido</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>Medicamentos que toma</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("medicamentos")}
                  />
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label>Nombre de contacto de emergencia</label>
                  <Required />
                  <input
                    className="form-control"
                    type="text"
                    {...register("contactoEmergenciaNombre", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.contactoEmergenciaNombre && (
                    <p className="error">
                      {errors.contactoEmergenciaNombre.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>Teléfono de contacto de emergencia</label>
                  <Required />
                  <input
                    className="form-control"
                    type="text"
                    {...register("contactoEmergenciaTelefono", {
                      required: "Campo requerido",
                    })}
                  />
                  {errors.contactoEmergenciaTelefono && (
                    <p className="error">
                      {errors.contactoEmergenciaTelefono.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="button-control">
            <img src={infoIMG} alt="ilustración Información médica" />
            <div>
              <button onClick={props.onGoBack}>Atrás</button>
              <button type="submit">Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
