import WebApi from "../Api/WebApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//Metodos asincronos
export const obtenerSolicitudesGeneral = createAsyncThunk(
  "solicitudes/obtenerSolicitudesGeneral",
  async (item, { rejectWithValue }) => {
    try {
      if (typeof item.nombre === "undefined") {
        const res = await WebApi.api().get(
          `solicitudesAdm?estadoId=${item.estadoId}&carreraId=${item.carreraId}`
        );
        return res.data.result;
      } else {
        const res = await WebApi.api().get(
          `solicitudesAdm?nombre=${item.nombre}`
        );
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const obtenerSolicitudesRedesSocialesTipos = createAsyncThunk(
  "solicitudes/obtenerSolicitudesRedesSociales",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("redesSocialesTipos");
      return res.data.result;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const obtenerSolicitudesRedesSocialesFrecuencias = createAsyncThunk(
  "solicitudes/obtenerSolicitudesRedesSocialesFrecuencias",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("frecuenciasUsos");
      return res.data.result;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const obtenerSolicitudesPasatiempos = createAsyncThunk(
  "solicitudes/obtenerSolicitudesPasatiempos",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("solicitudesPasatiempos");
      return res.data.result;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

//nuevos
export const obtenerSolicitudInfoPersona = createAsyncThunk(
  "solicitudes/obtenerSolicitudInfoPersona",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "Solicitudes/ObtenerInfoPersona/" + id
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const agregarSolicitudInfoPersona = createAsyncThunk(
  "solicitudes/agregarSolicitudInfoPersona",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Solicitudes/CrearInfoPersona", data);
      return res.data;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const obtenerSolicitudInfoContacto = createAsyncThunk(
  "solicitudes/obtenerSolicitudInfoContacto",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "Solicitudes/ObtenerInfoContacto/" + id
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const agregarSolicitudInfoContacto = createAsyncThunk(
  "solicitudes/agregarSolicitudInfoContacto",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "Solicitudes/CrearPersonaContacto",
        data
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const obtenerSolicitudInfoMedica = createAsyncThunk(
  "solicitudes/obtenerSolicitudInfoMedica",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Solicitudes/ObtenerInfoMedica/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const agregarSolicitudInfoMedica = createAsyncThunk(
  "solicitudes/agregarSolicitudInfoMedica",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Solicitudes/CrearInfoMedica", data);
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const obtenerSolicitudInfoFinanciera = createAsyncThunk(
  "solicitudes/obtenerSolicitudInfoFinanciera",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "Solicitudes/ObtenerInfoFinanciera/" + id
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const agregarSolicitudInfoFinanciera = createAsyncThunk(
  "solicitudes/agregarSolicitudInfoFinanciera",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "Solicitudes/CrearInfoFinanciera",
        data
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const obtenerSolicitudInfoEstudios = createAsyncThunk(
  "solicitudes/obtenerSolicitudInfoEstudios",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "Solicitudes/ObtenerInfoEstudios/" + id
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const agregarSolicitudInfoEstudios = createAsyncThunk(
  "solicitudes/agregarSolicitudInfoEstudios",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "Solicitudes/CrearInfoEstudios",
        data
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const obtenerSolicitudInfoInscripcion = createAsyncThunk(
  "solicitudes/obtenerSolicitudInfoInscripcion",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "Solicitudes/ObtenerInfoInscripcion/" + id
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const agregarSolicitudInfoInscripcion = createAsyncThunk(
  "solicitudes/agregarSolicitudInfoInscripcion",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("Solicitudes/CrearInfoInscripcion",data);
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const obtenerSolicitudInfoDocumentos = createAsyncThunk(
  "solicitudes/obtenerSolicitudInfoDocumentos",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        "Solicitudes/ObtenerInfoDocumentos/" + id
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const agregarSolicitudInfoDocumento = createAsyncThunk(
  "solicitudes/agregarSolicitudInfoDocumento",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        "Solicitudes/agregarInfoDocumentos",
        data
      );
      return res.data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const obtenerProvinciasByPaisId = createAsyncThunk(
  "solicitudes/obtenerProvinciasByPaisId",
  async (paisId, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Solicitudes/Provincias/" + paisId);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerCantonesByProvinciaId = createAsyncThunk(
  "solicitudes/obtenerCantonesByProvinciaId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Solicitudes/Cantones/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerDistritosByProvinciaId = createAsyncThunk(
  "solicitudes/obtenerDistritosByProvinciaId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("Solicitudes/Distritos/" + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerPersonasCentrosEducativos = createAsyncThunk(
  "solicitudes/obtenerPersonasCentrosEducativos",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`Solicitudes/ObtenerPersonasCentrosEducativos/?paisId=${data.paisId}&centroEducativo=${data.centroEducativo}&centroEducativoId=${data.centroEducativoId}&tipoId=${data.tipoId}`)
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerSolicitudEstado = createAsyncThunk(
  "solicitudes/obtenerSolicitudEstado",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`Solicitudes/EstadoSolicitud/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const finalizarSolicitud = createAsyncThunk(
  "solicitudes/finalizarSolicitud",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `Solicitudes/FinalizarSolicitud`,data
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerSolicitudesPersona = createAsyncThunk(
  "solicitudes/obtenerSolicitudesPersona",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `Solicitudes/ObtenerSolicitudesEstudiantes`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerEventos = createAsyncThunk(
  "solicitudes/obtenerEventos",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`Solicitudes/ObtenerEventos`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const nuevaSolicitud = createAsyncThunk(
  "solicitudes/nuevaSolicitud",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(`Solicitudes/NuevaSolicitud`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const eliminarSolicitud = createAsyncThunk(
  "solicitudes/eliminarSolicitud",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post(
        `Solicitudes/BorrarSolicitud?id=${id}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const obtenerCarrerasByNivelId = createAsyncThunk(
  "solicitudes/obtenerCarrerasByNivelId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(
        `Solicitudes/ObtenerCarrerasFiltradasNiveles/${id}`
      );
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


const solicitudesSlice = createSlice({
  name: "solicitudes",
  initialState: {
    solicitudes: [],
    solicitudModel: {},
    solicitudInfoPersona: {},
    solicitudInfoContacto: {},
    solicitudInfoEstudios: {},
    solicitudesAdmin: [],
    redesSocialesTipos: [],
    frecuenciasUsos: [],
    solicitudesPasatiempos: [],
    provincias: [],
    cantones: [],
    distritos: [],
    eventos: [],
    centrosEducativos: [],
    solicitudEstado: {},
    result: { success: false, message: "", show: false },
    status: "",
    error: null,
    carreras: [],
  },
  reducers: {},
  extraReducers: {
    [obtenerSolicitudesGeneral.fulfilled]: (state, action) => {
      state.solicitudesAdmin = action.payload;
    },
    [obtenerSolicitudesRedesSocialesTipos.fulfilled]: (state, action) => {
      state.redesSocialesTipos = action.payload;
    },
    [obtenerSolicitudesRedesSocialesFrecuencias.fulfilled]: (state, action) => {
      state.frecuenciasUsos = action.payload;
    },
    [obtenerSolicitudesPasatiempos.fulfilled]: (state, action) => {
      state.solicitudesPasatiempos = action.payload;
    },
    [obtenerSolicitudInfoPersona.fulfilled]: (state, action) => {
      state.solicitudInfoPersona = action.payload;
    },
    [obtenerSolicitudInfoContacto.fulfilled]: (state, action) => {
      state.solicitudInfoContacto = action.payload;
    },
    [agregarSolicitudInfoPersona.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerProvinciasByPaisId.fulfilled]: (state, action) => {
      state.provincias = action.payload;
    },
    [obtenerCantonesByProvinciaId.fulfilled]: (state, action) => {
      state.cantones = action.payload;
    },
    [obtenerDistritosByProvinciaId.fulfilled]: (state, action) => {
      state.distritos = action.payload;
    },
    [agregarSolicitudInfoContacto.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [agregarSolicitudInfoMedica.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerSolicitudInfoMedica.fulfilled]: (state, action) => {
      state.solicitudModel = action.payload;
    },
    [agregarSolicitudInfoFinanciera.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerSolicitudInfoFinanciera.fulfilled]: (state, action) => {
      state.solicitudModel = action.payload;
    },
    [agregarSolicitudInfoEstudios.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerSolicitudInfoEstudios.fulfilled]: (state, action) => {
      state.solicitudInfoEstudios = action.payload;
    },
    [agregarSolicitudInfoInscripcion.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerSolicitudInfoInscripcion.fulfilled]: (state, action) => {
      state.solicitudModel = action.payload;
    },
    [agregarSolicitudInfoDocumento.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerSolicitudInfoDocumentos.fulfilled]: (state, action) => {
      state.solicitudModel = action.payload;
    },
    [obtenerPersonasCentrosEducativos.fulfilled]: (state, action) => {
      state.centrosEducativos = action.payload;
    },
    [obtenerSolicitudEstado.fulfilled]: (state, action) => {
      state.solicitudEstado = action.payload;
    },
    [finalizarSolicitud.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerSolicitudesPersona.fulfilled]: (state, action) => {
      state.solicitudes = action.payload;
    },
    [obtenerEventos.fulfilled]: (state, action) => {
      state.eventos = action.payload;
    },
    [nuevaSolicitud.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [eliminarSolicitud.fulfilled]: (state, action) => {
      state.result = action.payload;
    },
    [obtenerCarrerasByNivelId.fulfilled]: (state, action) => {
      state.carreras = action.payload;
    },
  },
});

export default solicitudesSlice.reducer;
