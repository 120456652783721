import jwt_decode from "jwt-decode";

export const convertToBoolean = (myString) => {
  if (myString != undefined) {
    let myBool = myString.toString().toLowerCase() === "true";
    return myBool;
  }
};

export const extractRoles = () => {
  let token = localStorage.getItem("token");
  if (token) {
    let decoded = jwt_decode(token);
    if (typeof decoded.role === "string") {
      let arr = [];
      arr.push(decoded.role);
      return arr;
    } else {
      return decoded.role;
    }
  }
};
