import React from 'react'
import logoC from '../../SVG/LogoCenfotec.svg'
import logoM from '../../SVG/Logocircular.svg'
import { Link, useLocation } from 'react-router-dom'


export default function TitleBar(props) {

    const location = useLocation();
    return (
        <>
            <div className="nav-inf">
                <div className="imageContainer">
                    <Link to='/'>
                        <img src={logoC} className="imgEsc" alt="Logo CENFOTEC" />
                        <img src={logoM} className="imgMovil" alt="Logo CENFOTEC en móvil" />
                    </Link>

                </div>
                <div className="title">
                    {location.pathname !== "/" ?
                        <Link to='/' className='homeLink'><i className='material-icons'></i></Link>
                        : <></>}
                    <Link to='/' >
                        <h1>{props.titulo}</h1>
                    </Link>
                    <div className="title-image">
                        <img src={props.imagen} alt="Imagen de titulo admisiones" />
                    </div>
                    {props.num}
                </div>
            </div>
        </>
    )
}
