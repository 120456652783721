import React, {  useState } from "react";
import "../css/registro.css";
import Logo from "../../SVG/Logocircular.svg";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch} from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OperationStatus from "../../config/OperationStatus";
import { reiniciarContrasenaSolicitud } from "../../redux/usuariosSlice";
import Required from "../../Components/Required";

export default function RecuperarContrasena() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      correo: "",
    },
  });
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let { payload } = await dispatch(reiniciarContrasenaSolicitud(data));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      setSuccess(true);
    } else {
      toast.error(payload.message);
    }
  };

  return (
    <div className="reset-container">
      <div className="reset-card">
        <div>
          <div className="mt-4">
            <img
              onClick={() => {
                navigate("/Login");
              }}
              className="reset-image"
              src={Logo}
              alt="Logo"
            />
          </div>
          <br />

          {!success && <span className="subtitle">Recuperar contraseña</span>}
        </div>
        <div className="reset-form-container">
          {!success ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2">
                <span className="spanInfo">
                  Escribe tu correo electrónico para enviarte el enlace de
                  restauración de contraseña
                </span>
              </div>

              <div className="form-group">
                <label>Correo electrónico</label>
                <Required />
                <input
                  className="form-control"
                  autoComplete="email"
                  type="email"
                  {...register("correo", {
                    required: "Campo requerido",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Escriba un correoElectronico valido",
                    },
                  })}
                />
                {errors.correo && (
                  <p className="error">{errors.correo.message}</p>
                )}
              </div>

              <input
                type="submit"
                id="btnForm"
                value="Enviar enlace de acceso"
              />
              <h5>
                <Link to="/Login" className="text-primary">
                  Volver atrás
                </Link>
              </h5>
            </form>
          ) : (
            <div className="mt-4">
              <span className="subtitle">
                Se ha enviado un enlace de recuperación a su correo electrónico
              </span>
              <span className="sub-info d-block mt-4">
                Puede cerrar esta pestaña
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
