import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import Modal from "./Modal";
import ModalRemove from "./ModalRemove";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerPlantillas,
  eliminarPlantilla,
  actualizarPlantilla,
  almacenarPlantilla,
  obtenerPlantillasPorTipo,
} from "../../redux/plantillasSlice";
import { obtenerTiposPlantillas } from "../../redux/genericosSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import SummerNoteReactV from "../../Components/SummerNoteReactV";
import ReactSelectGroup from "../../Components/ReactSelectGroup";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "plantillaId", sortable: true },
  { label: "Nombre", accessor: "plantillaNombre", sortable: true },
  { label: "Tipo", accessor: "tipo", sortable: true },
  { label: "Acción", accessor: "action", sortable: false },
];

function ListaPlantillas(props) {
  const [modalState, changeModalState] = useState(false);
  const [removeState, changeRemoveState] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const [registerSelected = [], selectReg] = useState();
  const [variables = [], setVariables] = useState([]);
  const [valor, setValor] = useState(undefined);
  const [clickPosition, setClickPosition] = useState({ start: 0, end: 0 });

  const [tituloModal, setTituloModal] = useState("Agregar plantilla");
  const { width } = WindowDimension();

  const { submitAction } = useSubmit();
  const dispatch = useDispatch();

  const { plantillas } = useSelector((state) => state.plantillas);
  const { tiposPlantillas } = useSelector((state) => state.genericos);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      esTextoPlano: false,
      plantillId: 0,
      plantillaNombre: "",
      asunto: "",
      cuerpo: "",
      tipoPlantillaId: undefined,
    },
  });
  const tipoPlantillaId = watch("tipoPlantillaId");
  const esTextoPlano = watch("esTextoPlano");

  const showModalEdit = (reg) => {
    reset(reg);
    setEditing(true);
    setCreating(false);
    selectReg(reg);

    setTituloModal("Editar plantilla");
    changeModalState(true);
  };

  const closeModalEdit = () => {
    reset({
      plantillId: 0,
      plantillaNombre: "",
      asunto: "",
      cuerpo: "",
      tipoPlantillaId: undefined,
      esTextoPlano: false,
    });
    changeModalState(false);
  };

  const showRemoveModalEdit = (reg) => {
    selectReg(reg);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    selectReg();
    changeRemoveState(false);
  };

  const cargarPlantillas = () => {
    dispatch(obtenerPlantillas());
    dispatch(obtenerTiposPlantillas());
  };

  const cargarPlantillasPorTipo = (e) => {
    dispatch(obtenerPlantillasPorTipo(e.target.value));
  };

  const eliminar = async () => {
    submitAction(eliminarPlantilla, registerSelected.plantillaId, () => {
      closeRemoveModalEdit();
      cargarPlantillas();
    });
  };

  const actualizar = async (data) => {
    setCreating(false);
    submitAction(
      data.plantillaId > 0 ? actualizarPlantilla : almacenarPlantilla,
      data,
      () => {
        closeModalEdit();
        cargarPlantillas();
      }
    );
  };

  useEffect(() => {
    cargarPlantillas();
  }, [dispatch]);

  useEffect(() => {
    let a = tiposPlantillas?.find((x) => x.tipoPlantillaId == tipoPlantillaId);
    if (a) {
      setVariables(JSON.parse(a.variables));
    }
  }, [tipoPlantillaId]);

  useEffect(() => {
    let cuerpo = getValues("cuerpo");
    if (esTextoPlano) {
      var plainText = cuerpo
        .replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/&nbsp;|<br>/g, " ");
      setValue("cuerpo", plainText);
    }
  }, [esTextoPlano]);

  const agregarParametroClick = (val) => {
    let text = getValues("cuerpo");
    let a =
      text.substring(0, clickPosition.start) +
      `{{${val}}}` +
      text.substring(clickPosition.end);
    setValue("cuerpo", a);
  };

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <button
          className="main-admin-button"
          onClick={() => {
            changeModalState(true);
            setTituloModal("Nueva plantilla");
            reset();
            setEditing(false);
            setCreating(true);
          }}
        >
          +
        </button>
        <h2>Plantillas de notificaciones</h2>
        <div className="select-filter-section  ml-auto">
          <label hidden>Filtrar por tipo</label>
          <select onChange={cargarPlantillasPorTipo}>
            <option value="0">Todos los tipos</option>
            {tiposPlantillas?.map((a, i) => {
              return (
                <option key={i} value={a.tipoPlantillaId}>
                  {a.tipoPlantillaNombre}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <Modal
        estado={modalState}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "medium"}`}
        buttonGroup={
          <>
            <button className="button-text" onClick={handleSubmit(actualizar)}>
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div>
            <div className="form-group">
              <label>Nombre de la plantilla</label>
              <Required />
              <input
                className="form-control"
                id="titleEdit"
                type="text"
                {...register("plantillaNombre", {
                  required: "Campo requerido",
                })}
                name="plantillaNombre"
              ></input>
              {errors.plantillaNombre && (
                <p className="error">{errors.plantillaNombre.message}</p>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Tipo</label>
            <Required />
            <select
              className="form-control"
              {...register("tipoPlantillaId", { required: "Campo requerido" })}
              name="tipoPlantillaId"
            >
              <option value={undefined}>Seleccione</option>
              {tiposPlantillas?.map((a, i) => {
                return (
                  <option key={i} value={a.tipoPlantillaId}>
                    {a.tipoPlantillaNombre}
                  </option>
                );
              })}
            </select>
            {errors.tipo && <p className="error">{errors.tipo.message}</p>}
          </div>
          <div className="form-group">
            <label>Asunto</label>
            <Required />
            <input
              className="form-control"
              type="text"
              {...register("asunto", { required: "Campo requerido" })}
              name="asunto"
            ></input>
            {errors.asunto && <p className="error">{errors.asunto.message}</p>}
          </div>
          <div className="flex-modal-group">
            {/* TODO: ME QUEDE POR AQUI */}
            <div className="flex-check ">
              <input
                checked={esTextoPlano}
                onInput={(e) => {
                  setValue("esTextoPlano", e.target.checked);
                }}
                ref={register}
                {...register("esTextoPlano")}
                type="checkbox"
              />
              <label>Guardar texto plano</label>
            </div>
            {esTextoPlano && (
              <div className="flex-check ">
                <ReactSelectGroup
                  lista={variables}
                  value={valor}
                  handleChange={agregarParametroClick}
                />
              </div>
            )}
          </div>

          {esTextoPlano ? (
            <div className="form-group">
              <label>Cuerpo</label>
              <Required />
              <textarea
                className="form-control"
                {...register("cuerpo", {
                  required: "Campo requerido",
                  onBlur: (e) => {
                    setClickPosition({
                      start: e.target.selectionStart,
                      end: e.target.selectionEnd,
                    });
                  },
                })}
              ></textarea>
              {errors.cuerpo && (
                <p className="error">{errors.cuerpo.message}</p>
              )}
            </div>
          ) : (
            <div className="editorContainerModal form-group">
              <label>Cuerpo de texto</label>
              <Required />
              <div className="divEditorModal">
                <SummerNoteReactV
                  control={control}
                  name="cuerpo"
                  rules={{ required: "Campo requerido" }}
                  isEditing={isEditing}
                  isCreating={isCreating}
                  val={registerSelected.plantillaId}
                  lista={variables}
                />
                {errors.cuerpo && (
                  <p className="error">{errors.cuerpo.message}</p>
                )}
              </div>
            </div>
          )}
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={closeRemoveModalEdit}
        title="Eliminar plantillla"
        message="¿Seguro que desea eliminar el siguiente registro?"
        item={registerSelected.nombre}
        icon={null}
        btnGroup={
          <>
            <button className="button-text text-red" onClick={eliminar}>
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />

      <div className="table-container">
        {plantillas.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={plantillas}
              clase="table-adm"
              actions={[
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showModalEdit(item),
                },
                {
                  icon: "",
                  clase: "delete",
                  event: (item) => showRemoveModalEdit(item),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>
    </main>
  );
}

export default ListaPlantillas;
