import React from "react";
import "../css/modal.css";
import { useState } from "react";
import Notificacion from "./Notificacion";

function ModalNotificaciones({ notificaciones, estado, cambiarEstado }) {

  const [detailState, changeDetailState] = useState({ titulo: '', cuerpo: '', display: false });

  return (
    <>
      <div className={estado ? "active notifContainer" : "notifContainer"}>
        <div className="notifTitle">
          <h3>Notificaciones</h3>
          <button onClick={() => cambiarEstado(false)} className="btnCloseModal">
            <i className="material-icons"></i>
          </button>
        </div>

        {notificaciones.length != 0 ? (
          <div className="notifBody">
            {notificaciones.map((item, i) => {
              return (
                <div key={i} className="notifHeader">
                  <div className="notifInfo">
                    <h3>{item.asunto}</h3>
                    <h3>{item.fecha}</h3>
                    <p>{item.cuerpo}</p>
                  </div>
                  <button onClick={() => { changeDetailState({ titulo: item.asunto, cuerpo: item.cuerpo, display: true }); cambiarEstado(false) }}>
                    <i className="material-icons"></i>
                  </button>
                </div>
              );
            })}

          </div>
        ) : (
          <div className="message-container">
            <span className="message">No tiene notificaciones 🔕🔕🔕</span></div>
        )
        }

      </div>
      <Notificacion
        display={detailState.display}
        cambiarEstado={changeDetailState}
        titulo={'Detalle'}
        cuerpo={detailState.cuerpo}
        cambiarEstadoPadre={cambiarEstado}
      />
    </>
  );
}

export default ModalNotificaciones;
