import React, { useEffect, useState } from "react";
import infoIMG from "../../SVG/InformaciondecontactoCenfotec.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  obtenerDistritosByProvinciaId,
  obtenerCantonesByProvinciaId,
  obtenerProvinciasByPaisId,
  obtenerSolicitudInfoContacto,
  agregarSolicitudInfoContacto,
} from "../../redux/solicitudesSlice";
import OperationStatus from "../../config/OperationStatus";
import ReactSelect from "../../Components/ReactSelect";
import Required from "../../Components/Required";

export default function ContactInfo(props) {
  const [listaPaises, setListaPaises] = useState([]);
  const [listaProvincias, setListProvincias] = useState([]);
  const [listaCantones, setListCantones] = useState([]);
  const [listaDistritos, setListDistritos] = useState([]);
  const { solicitudInfoContacto, provincias, cantones, distritos } =
    useSelector((state) => state.solicitudes);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const paisId = watch("paisResidenciaId");
  const provinciaId = watch("provinciaId");
  const cantonId = watch("cantonId");
  const distritoId = watch("distritoId");
  const costaRicaId = 192;
  useEffect(() => {
    dispatch(obtenerSolicitudInfoContacto(props.solicitudId));
  }, [dispatch]);

  useEffect(() => {
    reset(solicitudInfoContacto);
  }, [solicitudInfoContacto]);

  useEffect(() => {
    if (paisId) {
      if (paisId == costaRicaId) cargarProvincias();
      else {
        setValue("provinciaId", null);
        setValue("cantonId", null);
        setValue("distritoId", null);
      }
    }
  }, [paisId]);
  useEffect(() => {
    if (provinciaId) cargarCantones();
  }, [provinciaId]);

  useEffect(() => {
    if (cantonId) cargarDistritos();
  }, [cantonId]);

  useEffect(() => {
    let a = solicitudInfoContacto?.paises?.map((item, i) => {
      return { label: item.paisNombre, value: item.paisId };
    });
    setListaPaises(a);
  }, [solicitudInfoContacto.paises]);
  useEffect(() => {
    let a = provincias?.map((item, i) => {
      return { label: item.provinciaNombre, value: item.provinciaId };
    });
    setListProvincias(a);
  }, [provincias]);

  useEffect(() => {
    let a = cantones?.map((item, i) => {
      return { label: item.cantonNombre, value: item.cantonId };
    });
    setListCantones(a);
  }, [cantones]);

  useEffect(() => {
    let a = distritos?.map((item, i) => {
      return { label: item.distritoNombre, value: item.distritoId };
    });
    setListDistritos(a);
  }, [distritos]);

  const onSubmit = async (data) => {
    if (data.etapaActual === 2) data.etapaActual += 1;

    data.solicitudId = props.solicitudId;

    let { payload } = await dispatch(agregarSolicitudInfoContacto(data));
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      props.onGoNext();
      props.handleStepChange(data.etapaActual);
    } else {
      toast.error(payload.message);
    }
  };

  const cargarProvincias = () => {
    dispatch(obtenerProvinciasByPaisId(paisId));
  };

  const cargarCantones = () => {
    dispatch(obtenerCantonesByProvinciaId(provinciaId));
  };

  const cargarDistritos = () => {
    dispatch(obtenerDistritosByProvinciaId(cantonId));
  };

  const isValidC = (e) => {
    return cantones.some((a) => a.cantonId == e);
  };
  const isValidD = (e) => {
    return distritos.some((a) => a.distritoId == e);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-solicitud">
      <div className="form-c">
        <div className="title-container">
          <span className="form-title">Información de contacto</span>
        </div>
        <br />
        <div className="grid-container col-2 container">
          <div>
            <div className="grid-container col-4 col-lg-2">
              <div className="grid-item">
                <div className="form-group">
                  <label>Correo</label>
                  <Required />
                  <input
                    disabled={true}
                    className="form-control"
                    {...register("correoElectronico", {
                      required: "Campo requerido",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Escriba un correoElectronico valido",
                      },
                    })}
                    type="email"
                  />
                  {errors.correoElectronico && (
                    <p className="error">{errors.correoElectronico.message}</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>No. de teléfono</label>
                  <Required />
                  <input
                    disabled={true}
                    className="form-control"
                    {...register("telefono", { required: "Campo requerido" })}
                    type="text"
                  />
                  {errors.telefono && (
                    <p className="error">{errors.telefono.message}</p>
                  )}
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label>País de residencia</label>
                  <Required />
                  <ReactSelect
                    name="paisResidenciaId"
                    control={control}
                    lista={listaPaises}
                    rules={{ required: "Campo requerido" }}
                  />
                  {errors.paisResidenciaId && (
                    <p className="error">{errors.paisResidenciaId.message}</p>
                  )}
                </div>
              </div>

              {paisId == costaRicaId && (
                <>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Provincia</label>
                      <Required />
                      <ReactSelect
                        name="provinciaId"
                        control={control}
                        lista={listaProvincias}
                        rules={{ required: "Campo requerido" }}
                      />

                      {errors.provinciaId && (
                        <p className="error">{errors.provinciaId.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Cantón</label>
                      <Required />

                      <ReactSelect
                        name="cantonId"
                        control={control}
                        lista={listaCantones}
                        rules={{
                          required: "Campo requerido",
                          validate: isValidC,
                        }}
                      />
                      {errors.cantonId && (
                        <p className="error">{errors.cantonId.message}</p>
                      )}
                      {errors?.cantonId?.type === "validate" && (
                        <p className="error">Campo requerido</p>
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="form-group">
                      <label>Distrito</label>
                      <Required />

                      <ReactSelect
                        name="distritoId"
                        control={control}
                        lista={listaDistritos}
                        rules={{
                          required: "Campo requerido",
                          validate: isValidD,
                        }}
                      />

                      {errors.distritoId && (
                        <p className="error">{errors.distritoId.message}</p>
                      )}
                      {errors?.distritoId?.type === "validate" && (
                        <p className="error">Campo requerido</p>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="grid-item">
                <div className="form-group">
                  <label>Dirección exacta</label>
                  <Required />
                  <textarea
                    className="form-control"
                    {...register("direccion", { required: "Campo requerido" })}
                    cols="30"
                    rows="3"
                  ></textarea>
                  {errors.direccion && (
                    <p className="error">{errors.direccion.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="button-control">
            <img src={infoIMG} alt="ilustración Información de contacto" />
            <div>
              <button onClick={props.onGoBack}>Atrás</button>
              <button type="submit">Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
