import React, { useEffect } from "react";
import PrincipalContainer from "./PrincipalContainer";
import TableSolicitudes from "./TableSolicitudes";
import NoSolicitudImg from "../../SVG/SinsolicitudesCenfotec.svg";
import nuevaSolicitudImg from "../../SVG/NuevasolicitudCenfotec.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerSolicitudesPersona,
  obtenerEventos,
  nuevaSolicitud,
  eliminarSolicitud,
} from "../../redux/solicitudesSlice";
import OperationStatus from "../../config/OperationStatus";
import { toast } from "react-toastify";

function VistaInicio() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { solicitudes } = state.solicitudes;

  useEffect(() => {
    cargarSolicitudes();
    dispatch(obtenerEventos());
  }, [dispatch]);

  const navigate = useNavigate();

  const crearSolicitud = async () => {
    let { payload } = await dispatch(nuevaSolicitud());
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      navigate(`/Solicitud/${payload.result}/InfoPersonal`);
    } else {
      toast.error(payload.message);
    }
  };

  const editarSolicitud = (id) => {
    navigate(`/Solicitud/${id}/InfoPersonal`);
  };
  const verSolicitud = (id, numero) => {
    navigate(`Historial/${id}`, { state: { numero: numero } });
  };

  const removerSolicitud = async (id) => {
    let { payload } = await dispatch(eliminarSolicitud(id));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      cargarSolicitudes();
    } else {
      toast.error(payload.message);
    }
  };

  const cargarSolicitudes = () => {
    dispatch(obtenerSolicitudesPersona());
  };

  return (
    <>
      {" "}
      <div className="principal d-flex flex-gap-20">
        <PrincipalContainer
          containerId=""
          titulo="Solicitudes"
          imagenId="noSolicitud"
          imagen={NoSolicitudImg}
          message="Actualmente sin solicitudes"
          alt="Imagen sin solicitudes hechas"
          lista={solicitudes}
          component={
            <TableSolicitudes
              lista={solicitudes}
              handleRemoveBtn={(id) => removerSolicitud(id)}
              handleViewBtn={(id, val) => verSolicitud(id, val)}
              handleEditButton={(id) => editarSolicitud(id)}
            />
          }
        />
        <div className="menu">
          <div className="menu-item addBtn">
            <h2>
              Realiza tus solicitudes de admisión de forma simple y segura.
            </h2>
            <button onClick={crearSolicitud}>
              <div className="d-flex flex-gap-2">
                <span>+ </span>
                <span> Nueva solicitud</span>
              </div>
            </button>
            <img
              src={nuevaSolicitudImg}
              alt="Imagen Nueva Solicitud"
              id="nuevaSolicitud"
            />
          </div>
        </div>
      </div>
      <div className="popout">
        <div className="btn" onClick={crearSolicitud}>
          <i className="material-icons"></i>
        </div>
      </div>
    </>
  );
}

export default VistaInicio;
