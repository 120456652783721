import React from 'react'

export default function PrincipalContainer(props) {
    return (
        <div className="container-inicio" id={props.containerId}>
            <h2>{props.titulo}</h2>
            <div className="tableArea">
                {props.lista?.length > 0 ?
                    (
                        <>
                            {props.component}
                        </>
                    ) :
                    (
                        <div className="table-noData">
                            <img id={props.imagenId} src={props.imagen} alt={props.alt} />
                            <h1>{props.message}</h1>
                        </div>
                    )

                }
            </div>
        </div>
    )
}
