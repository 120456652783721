import React from "react";
import "../Styles/detalleSolicitud.css";

function DetailMainSection({ title, isInitialOpened = true, children }) {
  const showInfo = (event) => {
    event.preventDefault();
    if (
      event.target.classList.contains("mainTitleBtn") ||
      event.target.classList.contains("title-btn")
    ) {
      event.target.nextElementSibling.classList.toggle("showing");
    } else {
      event.target.classList.toggle("showing");
    }

    event.target.closest(".divDisplay").classList.toggle("invisibleContent");
  };

  return (
    <section>
      <div
        className={`divDisplay mainTitle ${
          isInitialOpened ? "" : "invisibleContent"
        }`}
      >
        <a className="mainTitleBtn showing" role="button" onClick={showInfo}>
          <h3>{title}</h3>
        </a>
        <button className="displayBtn showing" onClick={showInfo}>
          <i className="material-icons up"></i>
          <i className="material-icons down"></i>
        </button>
      </div>
      <hr className="mainDivider" />
      <div>{children}</div>
    </section>
  );
}

export default DetailMainSection;
