import Multiselect from "multiselect-react-dropdown";
import React from "react";
import Summernotereact from "./SummernoteReact";
import { useState } from "react";
import { useEffect } from "react";
import Required from "../../Components/Required";
import { convertToBoolean } from "../../Utils/utils";

export const TextoCorto = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <input
        ref={props.refe}
        className="form-control"
        autoComplete="off"
        value={props.value}
        name={props.nombre}
        onChange={props.onChange}
      />
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Numerico = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <input
        autoComplete="off"
        type="number"
        className="form-control"
        name={props.nombre}
        onChange={props.onChange}
        value={props.value}
      />
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const TextoLargo = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <textarea
        autoComplete="off"
        className="form-control"
        name={props.nombre}
        onChange={props.onChange}
        value={props.value}
      />
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Correo = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <input
        type="email"
        autoComplete="off"
        className="form-control"
        name={props.nombre}
        onChange={props.onChange}
        value={props.value}
      />
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};
export const Clave = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        {" "}
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <input
        type="password"
        className="form-control"
        name={props.nombre}
        onChange={props.onChange}
        value={props.value}
      />
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Booleano = (props) => {
  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <input
        onChange={props.onChange}
        type="checkbox"
        className="form-control-check.inline"
        name={props.nombre}
        checked={convertToBoolean(props.value)}
      />
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Opciones = (props) => {
  const [val, setVal] = useState();

  useEffect(() => {
    setVal(props.valor);
  }, [props.valor]);

  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <select
        ref={props.refe}
        className="form-control"
        onInput={props.onChange}
        value={props.value}
        name={props.nombre}
      >
        <option value={""}>Seleccione</option>
        {props.opciones?.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.opcion}
            </option>
          );
        })}
      </select>
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const Editor = (props) => {
  return (
    <div className="editorContainerModal form-group">
      <abbr title={props.descripcion}>
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <div className="divEditorModal">
        <Summernotereact
          name={props.nombre}
          rules={props.rules}
          isEditing={props.value != props.valorPorDefecto}
          control={props.control}
          {...props}
        />
        <p className="error">{props.errors}</p>
        {props.valor != props.valorPorDefecto && (
          <button className="button-text" onClick={props.handleDefault}>
            Restablecer al valor por defecto
          </button>
        )}
      </div>
    </div>
  );
};

export const Radio = (props) => {
  const convertToBoolean = (myString) => {
    if (myString != undefined) {
      let myBool = myString.toString().toLowerCase() === "true";
      return myBool;
    }
  };

  const isValid = (e) => {
    return typeof convertToBoolean(e) == "boolean";
  };

  const [val, setVal] = useState();

  useEffect(() => {
    setVal(convertToBoolean(props.valor));
  }, [props.valor]);

  function handleDefault(e) {
    setVal(convertToBoolean(e.target.value));
    props.onChange(e);
  }

  return (
    <div className="form-group">
      <abbr title={props.descripcion}>
        <label>{props.label}</label>
      </abbr>
      {props.requerido && <Required />}
      <div className="check-container">
        <div className="form-control-check inline">
          <input
            name={props.nombre}
            onChange={handleDefault}
            type="radio"
            value={true}
            checked={val == true}
          />
          <label>Si</label>
        </div>
        <div className="form-control-check inline">
          <input
            type="radio"
            value={false}
            onChange={handleDefault}
            name={props.nombre}
            checked={val == false}
          />
          <label>No</label>
        </div>
      </div>
      <p className="error">{props.errors}</p>
      {props.valor != props.valorPorDefecto && (
        <button className="button-text" onClick={props.handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};

export const MultiOpciones = ({
  setValue,
  nombre,
  opciones,
  requerido,
  label,
  onChange,
  descripcion,
  control,
  valor,
  valorPorDefecto,
  placeholder,
  errors,
  handleDefault,
}) => {
  const [options, setOpciones] = useState();
  useEffect(() => {
    setOpciones(opciones);
  }, [opciones]);

  return (
    <div className="form-group">
      <abbr title={descripcion}>
        <label>{label}</label>
      </abbr>
      {requerido && <Required />}

      <Multiselect
        className="docSelect"
        options={options}
        displayValue="opcion"
        name={nombre}
        onChange={onChange}
        control={control}
        selectedValues={
          valor ? opciones.filter((a) => valor.includes(a.id)) : valorPorDefecto
        }
        onSelect={(selected, item) => {
          setValue(
            nombre,
            Object.values(selected).map((o) => o.id)
          );
        }}
        onRemove={(selected, item) => {
          setValue(
            nombre,
            Object.values(selected).map((o) => o.id)
          );
        }}
        placeholder={placeholder}
      />
      <p className="error">{errors}</p>
      {valor != valorPorDefecto && (
        <button className="button-text" onClick={handleDefault}>
          Restablecer al valor por defecto
        </button>
      )}
    </div>
  );
};
