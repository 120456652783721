import React from 'react';
import { useController } from 'react-hook-form';
import { Multiselect } from 'multiselect-react-dropdown';
import { useEffect } from 'react';
import { useState } from 'react';

function MultiSelect({
  name,
  control,
  rules = {},
  options,
  setValue,
  selectedValues,
  displayValue,
  setValueArray,
  placeholder,
  onSelectDoc,
  onRemoveDoc,
  innerRef = null,
  isObject = true,
}) {
  const {
    field: { ref, value },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: '',
  });

  const [opciones, setOpciones] = useState();
  useEffect(() => {
    setOpciones(options);
  }, [options]);

  return (
    <Multiselect
      ref={innerRef}
      isObject={isObject}
      className="docSelect"
      style={{
        multiselectContainer: { backgroundColor: '#FCFAFA', borderRadius: 10 },
        searchBox: {
          borderRadius: 10,
        },
      }}
      options={opciones}
      emptyRecordMsg={'Sin resultados'}
      hidePlaceholder
      displayValue={displayValue}
      name={name}
      selectedValues={selectedValues}
      value={value}
      onSelect={(selected, item) => {
        setValue(setValueArray, selected);
      }}
      onRemove={(selected, item) => {
        setValue(setValueArray, selected);
      }}
      placeholder={placeholder}
    />
  );
}

export default MultiSelect;
