import WebApi from '../Api/WebApi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const ObtenerTodos = createAsyncThunk(
  'perfilesDocumentosTipos/ObtenerTodos',
  async (i, { rejectWithValue }) => {
    try {
      if (typeof i === 'undefined') {
        const res = await WebApi.api().get(`admin/documentos`);
        return res.data.result;
      } else {
        const res = await WebApi.api().get(`admin/documentos?nombre=${i}`);
        return res.data.result;
      }
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);
export const ObtenerById = createAsyncThunk(
  'perfilesDocumentosTipos/ObtenerById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('admin/documentos/' + id);
      return res.data.result;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);
export const Insertar = createAsyncThunk(
  'perfilesDocumentosTipos/Insertar',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/documentos', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const Actualizar = createAsyncThunk(
  'perfilesDocumentosTipos/Actualizar',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/documentos/' + item.documentoId,
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ImportarDocumentos = createAsyncThunk(
  'perfilesDocumentosTipos/ImportarDocumentos',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/Documentos/ucenfotec');
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const Eliminar = createAsyncThunk(
  'perfilesDocumentosTipos/Eliminar',
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().delete('admin/documentos/' + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const perfilesDocumentosTiposSlice = createSlice({
  name: 'perfilesDocumentosTipos',
  initialState: {
    perfilesDocumentosTipos: [],
    model: {},
  },
  reducers: {},
  extraReducers: {
    [ObtenerTodos.fulfilled]: (state, action) => {
      state.perfilesDocumentosTipos = action.payload;
    },
    [ObtenerById.fulfilled]: (state, action) => {
      state.model = action.payload;
    },
  },
});

export default perfilesDocumentosTiposSlice.reducer;
