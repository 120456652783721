import React from 'react';
import Modal from './Modal';
import WindowDimension from '../../Hooks/WindowDimension';

function ModalRemove(props) {
  const { width } = WindowDimension();

  return (
    <Modal
      estado={props.estado}
      cambiarEstado={() => {
        props.closeModalHandled();
      }}
      titulo={props.title}
      clase={`${
        props.clase ? props.clase : width < 576 ? 'full-width' : 'medium'
      }`}
      buttonGroup={props.btnGroup}
    >
      <div className="modalFlexRemove">
        {props.icon === null ? (
          <i className="material-icons warning"></i>
        ) : (
          props.icon
        )}
        <label>{props.message}</label>
        {props.children}
        <span
          className={props.color === undefined ? 'remove-item' : props.color}
        >
          {props.item}
        </span>
      </div>
    </Modal>
  );
}

export default ModalRemove;
