import React, { useState, useEffect } from "react";
import infoIMG from "../../SVG/InformacionpersonalCenfotec.svg";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerSolicitudInfoPersona,
  agregarSolicitudInfoPersona,
} from "../../redux/solicitudesSlice";
import { toast } from "react-toastify";
import OperationStatus from "../../config/OperationStatus";
import ReactSelect from "../../Components/ReactSelect";
import Required from "../../Components/Required";

export default function PersonalInfo(props) {
  const [otrosCheck, setOtroCheck] = useState(false);
  const [pasaTiemposArray, setPasatiemposArray] = useState([]);
  const [anios, setAnios] = useState(0);
  const [listaPaises, setListaPaises] = useState([]);
  const [listaTiposDni, setListaTiposDni] = useState([]);
  const [listaGeneros, setListaGeneros] = useState([]);
  const [listaEstados, setListaEstados] = useState([]);
  const [listaFrecuencias, setListaFrecuencias] = useState([]);

  const dispatch = useDispatch();
  const { solicitudInfoPersona } = useSelector((state) => state.solicitudes);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "solicitudesRedesSociales",
  });
  const consultadoEnPadron = watch("consultadoEnPadron");

  const pasatiemposOtros = watch("pasaTiemposOtro");
  const pasatiempos = watch("pasaTiempos");
  const fechaN = watch("fechaNacimiento");
  useEffect(() => {
    dispatch(obtenerSolicitudInfoPersona(props.solicitudId));
  }, []);

  useEffect(() => {
    reset(solicitudInfoPersona);
  }, [solicitudInfoPersona]);

  useEffect(() => {
    if (pasatiemposOtros) setOtroCheck(true);
    else setOtroCheck(false);
  }, [pasatiemposOtros]);

  useEffect(() => {
    if (pasatiempos) {
      if (pasatiempos.length > 0) {
        setPasatiemposArray(pasatiempos);
      } else {
        setPasatiemposArray([]);
      }
    }
  }, [pasatiempos]);

  useEffect(() => {
    if (fechaN) verifyAge();
  }, [fechaN]);

  useEffect(() => {
    let a = solicitudInfoPersona?.paises?.map((item, i) => {
      return { label: item.paisNombre, value: item.paisId };
    });
    setListaPaises(a);
    let b = solicitudInfoPersona?.tiposDni?.map((item, i) => {
      return { label: item.tipoDniNombre, value: item.tipoDniId };
    });
    setListaTiposDni(b);
    let c = solicitudInfoPersona?.generos?.map((item, i) => {
      return { label: item.generoNombre, value: item.generoId };
    });
    setListaGeneros(c);
    let d = solicitudInfoPersona?.estadosCiviles?.map((item, i) => {
      return { label: item.estadoCivilNombre, value: item.estadoCivilId };
    });
    setListaEstados(d);
    let e = solicitudInfoPersona?.redesSocialesFrecuenciasUso?.map(
      (item, i) => {
        return { label: item.frecuenciaUsoNombre, value: item.frecuenciaUsoId };
      }
    );
    setListaFrecuencias(e);
  }, [solicitudInfoPersona.paises]);

  const onSubmit = async (data) => {
    if (data.etapaActual === 1) data.etapaActual += 1;

    data.solicitudId = props.solicitudId;
    let { payload } = await dispatch(agregarSolicitudInfoPersona(data));

    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      props.onGoNext();
      props.handleStepChange(data.etapaActual);
    } else {
      toast.error(payload.message);
    }
  };

  const verifyAge = () => {
    let value = fechaN;
    var today = new Date();
    var birthDate = new Date(value);
    var anios = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      anios--;
    }
    setAnios(anios);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-solicitud">
      <div className="form-c">
        <div className="title-container">
          <span className="form-title">Información personal</span>
        </div>
        <br />
        <div className="grid-container col-2 container">
          <div>
            <div className="grid-container col-2 col-lg-2 col-sm-12">
              <div className="grid-item">
                <div className="form-group">
                  <label>Tipo identificación</label>
                  <Required />
                  <ReactSelect
                    name="tipoDniId"
                    control={control}
                    lista={listaTiposDni}
                    rules={{ required: "Campo requerido" }}
                    disabled={consultadoEnPadron}
                  />
                  {errors.tipoDniId && (
                    <p className="error">{errors.tipoDniId.message}</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>No. de identificación</label>
                  <Required />
                  <input
                    ref={register}
                    disabled={consultadoEnPadron}
                    {...register("dni", { required: "Campo requerido" })}
                    className="form-control"
                    type="text"
                  />
                  {errors.dni && <p className="error">{errors.dni.message}</p>}
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label>Nombre(s)</label>
                  <Required />
                  <input
                    ref={register}
                    disabled={consultadoEnPadron}
                    {...register("nombres", { required: "Campo requerido" })}
                    name="nombres"
                    className="form-control"
                    type="text"
                  />
                  {errors.nombres && (
                    <p className="error">{errors.nombres.message}</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>Primer Apellido</label>
                  <Required />
                  <input
                    disabled={consultadoEnPadron}
                    ref={register}
                    {...register("primerApellido", {
                      required: "Campo requerido",
                    })}
                    className="form-control"
                    type="text"
                  />
                  {errors.primerApellido && (
                    <p className="error">{errors.primerApellido.message}</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>Segundo Apellido</label>
                  <Required />
                  <input
                    ref={register}
                    disabled={consultadoEnPadron}
                    {...register("segundoApellido", {
                      required: "Campo requerido",
                    })}
                    className="form-control"
                    type="text"
                  />
                  {errors.segundoApellido && (
                    <p className="error">{errors.segundoApellido.message}</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>Fecha de nacimiento</label>
                  <Required />
                  <input
                    ref={register}
                    disabled={consultadoEnPadron}
                    className="form-control"
                    type="date"
                    name="fechaNacimiento"
                    {...register("fechaNacimiento", {
                      required: "Campo requerido",
                    })}
                  />
                  {anios > 0 && <span className="info">{anios} años</span>}
                  {errors.fechaNacimiento && (
                    <p className="error">{errors.fechaNacimiento.message}</p>
                  )}
                </div>
              </div>

              <div className="grid-item">
                <div className="form-group">
                  <label>Género</label>
                  <Required />
                  <ReactSelect
                    name="generoId"
                    control={control}
                    lista={listaGeneros}
                    rules={{ required: "Campo requerido" }}
                  />
                  {errors.generoId && (
                    <p className="error">{errors.generoId.message}</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>Estado civil</label>
                  <Required />
                  <ReactSelect
                    name="estadoCivilId"
                    control={control}
                    lista={listaEstados}
                    rules={{ required: "Campo requerido" }}
                  />
                  {errors.estadoCivilId && (
                    <p className="error">{errors.estadoCivilId.message}</p>
                  )}
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label>País de procedencia</label>
                  <Required />

                  <ReactSelect
                    name="paisProcedenciaId"
                    control={control}
                    lista={listaPaises}
                    rules={{ required: "Campo requerido" }}
                  />
                  {errors.paisProcedenciaId && (
                    <p className="error">{errors.paisProcedenciaId.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="grid-container">
              <div className="grid-item">
                <div className="form-group">
                  <label>Pasatiempos</label>
                  <div className="check-container flex">
                    {solicitudInfoPersona?.listaPasatiempos?.map(
                      (item, index) => (
                        <div
                          className="form-control-check flex "
                          key={item.pasatiempoId}
                        >
                          <input
                            value={item.pasatiempoNombre}
                            checked={
                              pasaTiemposArray.indexOf(item.pasatiempoNombre) !=
                              -1
                                ? true
                                : false
                            }
                            ref={register}
                            {...register("pasaTiempos")}
                            type="checkbox"
                          />
                          <label>{item.pasatiempoNombre}</label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group ">
                  <div className="check-container">
                    <div className="form-control-check inline">
                      <label>Otro</label>
                      <input
                        type="checkbox"
                        checked={otrosCheck}
                        value={otrosCheck}
                        onChange={(e) => {
                          setOtroCheck(e.target.checked);
                        }}
                      />
                    </div>
                    <input
                      className="form-control"
                      id="inputAlergia"
                      disabled={!otrosCheck}
                      ref={register}
                      {...register("pasaTiemposOtro")}
                      type="text"
                      placeholder="Especifique"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid-item">
              <label>Frecuencia de uso de redes sociales</label>
            </div>
            <div className="grid-container col-4 col-sm-12 col-md-2 ">
              {solicitudInfoPersona?.redesSocialesTipos?.map((item, index) => (
                <div className="grid-item" key={index}>
                  <div className="form-group">
                    <input
                      type="hidden"
                      value={item.redSocialId}
                      {...register(
                        `solicitudesRedesSociales.${index}.redSocialId`
                      )}
                    />
                    <label>{item.redSocialNombre}</label>
                    <Required />

                    <ReactSelect
                      name={`solicitudesRedesSociales.${index}.frecuenciaUsoId`}
                      control={control}
                      lista={listaFrecuencias}
                      rules={{ required: "Campo requerido" }}
                    />
                    {errors.solicitudesRedesSociales?.[index]
                      ?.frecuenciaUsoId && (
                      <p className="error">
                        {
                          errors?.solicitudesRedesSociales?.[index]
                            ?.frecuenciaUsoId.message
                        }
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="button-control">
            <img src={infoIMG} alt="ilustración Información personal" />
            <div>
              <button type="submit">Continuar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
