import WebApi from "../Api/WebApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const obtenerConfiguraciones = createAsyncThunk(
  "carreras/obtenerConfiguraciones",
  async (i, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get(`admin/ConfiguracionesGenerales`);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const actualizarConfiguraciones = createAsyncThunk(
  "carreras/actualizarConfiguraciones",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(`admin/ConfiguracionesGenerales/`,data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const configuracionesGeneralesDefault = createAsyncThunk(
  "carreras/configuracionesGeneralesDefault",
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(`admin/ConfiguracionesGeneralesDefault/`+data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const configuracionesSlice = createSlice({
  name: "configuraciones",
  initialState: {
    configuraciones: [],
    status: "",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [obtenerConfiguraciones.pending]: (state, action) => {
      state.loading = true;
    },
    [obtenerConfiguraciones.fulfilled]: (state, action) => {
      state.loading = false;
      state.configuraciones = action.payload;
    },
    [obtenerConfiguraciones.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default configuracionesSlice.reducer;
