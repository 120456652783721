import { useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

function Signer({
  height,
  width,
  style = {},
  control,
  name,
  valor = null,
  rules,
  placeholder = 'Firme aquí',
  setValue,
}) {
  const {
    field: { ref, value },
  } = useController({
    name,
    control,
    rules,
  });

  const [canvasClean, setCanvasClean] = useState(false);
  const [canvasData, setCanvasData] = useState(null);
  const [signatureComplete, setSignatureComplete] = useState(false);
  const [defaultData, setDefaultData] = useState(null);

  const refCanvas = useRef();
  const buttonContainer = useRef();

  useEffect(() => {
    setDefaultData(valor);
    drawImage(valor);
  }, [valor]);

  const drawImage = (src) => {
    var obj = new Image();
    var canvas = refCanvas.current;
    var ctx = refCanvas?.current.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (src != null && src != undefined) {
      setSignatureComplete(true);
      obj.src = src;
      obj.onload = setTimeout(() => {
        ctx.drawImage(obj, 0, 0);
      }, 500);
    }
  };

  useEffect(() => {
    var canvas = refCanvas.current;
    var ctx = refCanvas?.current.getContext('2d');
    var drawing = false;
    var clean = true;

    const clear = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const init = () => {
      ctx.font = '40px arial';
      ctx.fillStyle = '#dddddd';
      clear();
      if (!signatureComplete) {
        ctx.fillText(
          placeholder,
          canvas.width / 2 - ctx.measureText(placeholder).width / 2,
          canvas.height / 2
        );
      }
      buttonContainer.current.hidden = true;
      clean = true;
    };
    init();

    function drawstart(e) {
      if (!signatureComplete) {
        if (clean) clear();
        clean = false;
        drawing = true;
        ctx.beginPath();
      }
    }
    function drawmove(e) {
      let bounds = canvas.getBoundingClientRect();

      let scaleX = canvas.width / bounds.width;
      let scaleY = canvas.height / bounds.height;

      let pos = {
        x: (e.pageX - bounds.left) * scaleX,
        y: (e.pageY - (bounds.top + window.scrollY)) * scaleY,
      };
      if (drawing) {
        ctx.lineTo(pos.x, pos.y);
        ctx.stroke();
        buttonContainer.current.hidden = false;
      }
    }
    function drawend(e) {
      setCanvasData(canvas.toDataURL());
      drawing = false;
    }
    function touchstart(event) {
      drawstart(event.touches[0]);
    }
    function touchmove(event) {
      drawmove(event.touches[0]);
      event.preventDefault();
    }
    function touchend(event) {
      drawend(event.changedTouches[0]);
    }

    canvas.ontouchstart = touchstart;
    canvas.ontouchmove = touchmove;
    canvas.ontouchend = touchend;

    canvas.onmousedown = drawstart;
    canvas.onmousemove = drawmove;
    canvas.onmouseup = drawend;

    const resetCanvas = () => {
      if (canvasClean) {
        clear();
        setCanvasClean(false);
        setCanvasData(null);
        setValue(name, null);
      }
    };
    resetCanvas();

    return () => {
      setCanvasData(null);
      setDefaultData(null);

      clear();
    };
  }, [canvasClean, signatureComplete, defaultData]);

  return (
    <div
      className="signer"
      style={{
        width,
        height,
      }}
    >
      <button
        className="quit-button"
        hidden={!signatureComplete}
        onClick={(e) => {
          e.preventDefault();
          setSignatureComplete(false);
          setCanvasClean(true);
          setDefaultData(null);
        }}
      >
        <i className="material-icons"></i>
      </button>
      <canvas
        ref={refCanvas}
        height={height}
        width={width}
        style={style}
      ></canvas>
      <div
        className="signer-button-container"
        ref={buttonContainer}
        hidden={signatureComplete}
      >
        <button
          className="reset-button"
          onClick={(e) => {
            e.preventDefault();
            setCanvasClean(true);
          }}
        >
          <i className="material-icons"></i>
        </button>

        <button
          className="accept-button"
          onClick={(e) => {
            e.preventDefault();
            setValue(name, canvasData);
            drawImage(canvasData);
          }}
        >
          <i className="material-icons"></i>
        </button>
      </div>
    </div>
  );
}

export default Signer;
