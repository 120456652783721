
import WebApi from '../Api/WebApi'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const ObtenerTodos = createAsyncThunk(
    "tiposOrganizaciones/ObtenerTodos",
    async (i, { rejectWithValue }) => {
        try {
            if(typeof i === 'undefined'){
                const res = await WebApi.api().get(`admin/tipoOrganizaciones`)
            return res.data.result;
             }
             else{
                const res = await WebApi.api().get(`admin/tipoOrganizaciones?nombre=${i}`)
                return res.data.result;
            }
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const ObtenerById = createAsyncThunk(
    "tiposOrganizaciones/ObtenerById",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().get('admin/tipoOrganizaciones/' + id)
            return res.data.result;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const Insertar = createAsyncThunk(
    "tiposOrganizaciones/Insertar",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().post('admin/tipoOrganizaciones/', item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const Actualizar = createAsyncThunk(
    "tiposOrganizaciones/Actualizar",
    async (item, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().put('admin/tipoOrganizaciones/' + item.tipoOrganizacionId, item)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const Eliminar = createAsyncThunk(
    "tiposOrganizaciones/Eliminar",
    async (id, { rejectWithValue }) => {
        try {
            const res = await WebApi.api().delete('admin/tipoOrganizaciones/' + id)
            return res.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

const tiposOrganizacionesSlice = createSlice({
    name: 'tiposOrganizaciones',
    initialState: {
        tiposOrganizaciones: [],
        model: {},
    },
    reducers: {
    },
    extraReducers: {
        [ObtenerTodos.fulfilled]: (state, action) => {
            state.tiposOrganizaciones = action.payload;
        },
        [ObtenerById.fulfilled]: (state, action) => {
            state.model = action.payload;
        },
    }
})

export default tiposOrganizacionesSlice.reducer;