import NavLinks from './NavLinks'

function AppBar(props) {

    const clickedButton = (e) => {
        const profile = document.getElementsByClassName('menuPerfil')[0];
        const toggle = document.querySelector('.toggleBtn');
        const closeBar = document.querySelectorAll('.toggleBtn');

        closeBar.forEach(el => {
            el.classList.toggle('invisibleBtn');
        });

        const nav = document.querySelector('.nav');

        profile.classList.toggle('Active');
        toggle.classList.toggle('toggleBlue');
    }

    return (
        <>
            <div className="nav">
                <NavLinks />
            </div>
            <button className="toggleBtn" onClick={clickedButton}>
                <i className='material-icons'></i>
            </button>
            <button className="toggleBtn invisibleBtn" onClick={clickedButton}>
                <i className='material-icons'></i>
            </button>
        </>
    );
}

export default AppBar;