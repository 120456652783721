import { useState } from "react";
import { useDispatch } from "react-redux";
import OperationStatus from "../config/OperationStatus";
import { toast } from "react-toastify";

function useSubmit() {
  const [sendingData, setSendingData] = useState(false);
  const dispatch = useDispatch();

  const submitAction = async (apiSubmit, data, callbackSuccess, callbackFail) => {
    let result;
    setSendingData(true);
      let { payload } = await dispatch(apiSubmit(data));
      result = payload;
    if (result.operationStatus === OperationStatus.SUCCESS) {
      if (callbackSuccess) callbackSuccess();
      toast.success(result.message);
    } else {
      if (callbackFail) callbackFail();
      toast.error(result.message);
    }
    setSendingData(false);
  };

  return { submitAction, sendingData };
}

export default useSubmit;
