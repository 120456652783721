import React from 'react'
import {Grid} from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

function Loading(props) {
  return (
    <div className={props.clase}>
      <div className="divLoader">
        <Grid
         color={'#006aea'}
         height={100}
         width={100}
         timeout={3000}/>
         </div>
    </div>
  )
}

export default Loading