import React from 'react';
import { Grid } from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function Loading(props) {
  return (
    <div className={props.classParent}>
      <div className={props.classLoader}>
        <Grid color={'#006aea'} height={100} width={100} timeout={3000} />
        {props.message && (
          <span className="loading-message">{props.message}</span>
        )}
      </div>
    </div>
  );
}

export default Loading;
