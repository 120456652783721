import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import ModalRemove from "./ModalRemove";
import { useDispatch, useSelector } from "react-redux";
import {
  ObtenerTodos,
  Actualizar,
  Eliminar,
  Insertar,
} from "../../redux/formasSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "formaConocernosId", sortable: true },
  { label: "Nombre", accessor: "formaNombre", sortable: true },
  { label: "Acción", accessor: "action", sortable: false },
];

function Formas(props) {
  const [showModal, setShowModal] = useState(false);
  const [tituloModal, setTituloModal] = useState("Agregar");
  const [removeState, changeRemoveState] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const { width } = WindowDimension();

  const dispatch = useDispatch();
  let busqueda = useRef();

  const { submitAction } = useSubmit();

  const { formas } = useSelector((state) => state.formas);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const showModalEdit = (item) => {
    reset(item);
    setTituloModal("Editar");
    setShowModal(true);
  };

  const closeModalEdit = () => {
    reset({ formaConocernosId: 0, formaNombre: "" });
    setShowModal(false);
  };

  const showRemoveModalEdit = (item) => {
    setItemSelected(item);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    setItemSelected({});
    changeRemoveState(false);
  };

  const cargarFormas = () => {
    dispatch(ObtenerTodos());
  };

  const cargarFormasPorCaracter = () => {
    dispatch(ObtenerTodos(busqueda.current.value));
  };

  const eliminar = async () => {
    submitAction(Eliminar, itemSelected.formaConocernosId, () => {
      closeRemoveModalEdit();
      cargarFormas();
    });
  };

  const onSubmit = async (data) => {
    submitAction(
      data.formaConocernosId > 0 ? Actualizar : Insertar,
      data,
      () => {
        closeModalEdit();
        cargarFormas();
      }
    );
  };

  useEffect(() => {
    cargarFormas();
  }, [dispatch]);

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <div className="header-admin-bar-items">
          <button
            className="main-admin-button"
            onClick={() => {
              setShowModal(true);
              setTituloModal("Agregar");
              setValue("formaNombre", "");
              reset();
            }}
          >
            +
          </button>
          <h2>Formas en que se enteró de CENFOTEC</h2>
        </div>
        <div className="header-admin-bar-items ml-auto">
          <input
            type="search"
            className="form-control"
            onInput={cargarFormasPorCaracter}
            placeholder="Búsqueda"
            ref={busqueda}
          />
        </div>
      </div>

      <div className="table-container">
        {formas.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={formas}
              clase="table-adm"
              actions={[
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showModalEdit(item),
                },
                {
                  icon: "",
                  clase: "text-primary",
                  event: (item) => showRemoveModalEdit(item),
                },
              ]}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <label>Forma de conocer a CENFOTEC</label>
            <Required />
            <input
              className="form-control"
              name="formaNombre"
              {...register("formaNombre", { required: "Campo requerido" })}
            />
            {errors.formaNombre && (
              <p className="error">{errors.formaNombre.message}</p>
            )}
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={closeRemoveModalEdit}
        title="Eliminar plantillla"
        message="¿Seguro que desea eliminar el siguiente registro?"
        item={itemSelected.formaNombre}
        btnGroup={
          <>
            <button className="button-text text-red" onClick={eliminar}>
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />
    </div>
  );
}

export default Formas;
