import WebApi from "../Api/WebApi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const obtenerPerfiles = createAsyncThunk(
  "perfiles/obtenerPerfiles",
  async (i, { rejectWithValue }) => {
    try {
      if (typeof i === "undefined") {
        const res = await WebApi.api().get(`admin/perfilesDocumento`);
        return res.data.result;
      } else {
        const res = await WebApi.api().get(
          `admin/perfilesDocumento?nombre=${i}`
        );
        return res.data.result;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const almacenarPerfil = createAsyncThunk(
  "perfiles/CrearNuevoPerfil",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("admin/perfilesDocumento", item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const obtenerPerfilPorId = createAsyncThunk(
  "perfiles/ObtenerPerfilPorId",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/perfilesDocumento/" + id);
      return res.data.result;
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const eliminarPerfil = createAsyncThunk(
  "perfiles/RemoverPerfil",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().delete("admin/perfilesDocumento/" + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const actualizarPerfil = createAsyncThunk(
  "plantillas/ModificarPerfil",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        "admin/perfilesDocumento/" + item.perfilId,
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const obtenerPerfilesParametros = createAsyncThunk(
  "perfiles/obtenerPerfilesParametros",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get("admin/PerfilesParametros");
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const actualizarPerfilParametro = createAsyncThunk(
  "plantillas/actualizarPerfilParametro",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put("admin/PerfilesParametros", item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const AgregarPerfilParametro = createAsyncThunk(
  "plantillas/AgregarPerfilParametro",
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post("admin/PerfilesParametros", item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const eliminarParametro = createAsyncThunk(
  "perfiles/eliminarParametro",
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().delete("admin/PerfilesParametros/" + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const perfilSlice = createSlice({
  name: "perfiles",
  initialState: {
    perfiles: [],
    perfilesParametros: [],
    perfil: {},
    status: "",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [obtenerPerfiles.pending]: (state, action) => {
      state.loading = true;
    },
    [obtenerPerfiles.fulfilled]: (state, action) => {
      state.loading = false;
      state.perfiles = action.payload;
    },
    [obtenerPerfiles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [obtenerPerfilPorId.fulfilled]: (state, action) => {
      state.loading = false;
      state.perfil = action.payload;
    },
    [obtenerPerfilesParametros.fulfilled]: (state, action) => {
      state.loading = false;
      state.perfilesParametros = action.payload;
    },
  },
});

export default perfilSlice.reducer;
