import WebApi from '../Api/WebApi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const ObtenerTodos = createAsyncThunk(
  'periodos/ObtenerTodos',
  async (item, { rejectWithValue }) => {
    try {
      if (item === undefined) {
        const res = await WebApi.api().get(`admin/periodos`);
        return res.data.result;
      } else if (item.periodoNombre !== null) {
        const res = await WebApi.api().get(
          `admin/periodos?nombre=${item.periodoNombre}`
        );
        return res.data.result;
      } else if (item.anio !== null) {
        const res = await WebApi.api().get(`admin/periodos?anio=` + item.anio);
        return res.data.result;
      }
    } catch (err) {
      return { success: false, message: err, show: true };
    }
  }
);

export const ObtenerPeriodoFechas = createAsyncThunk(
  'periodosFechas/ObtenerPeriodosFechas',
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('admin/periodosFechas/' + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const InsertarPeriodosFechas = createAsyncThunk(
  'periodosFechas/InsertarPeriodosFechas',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/periodosFechas/', item);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ActualizarPeriodosFechas = createAsyncThunk(
  'periodosFechas/ActualizarPeriodosFechas',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/periodosFechas/' + item.periodosFechaAperturaId,
        item
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ObtenerById = createAsyncThunk(
  'periodos/ObtenerById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('admin/periodos/' + id);
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ActualizarEstadoPeriodo = createAsyncThunk(
  'periodos/Eliminar',
  async (item, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/Periodos/CambiarEstado/' + item.id + '?estadoId=' + item.estado
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ImportarPeriodos = createAsyncThunk(
  'periodos/Importar',
  async (i, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/Periodos/ucenfotec');
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ObtenerTiposPeriodos = createAsyncThunk(
  'periodos/ObtenerTiposPeriodos',
  async (i, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('admin/TipoPeriodos');
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const InsertarTipoPeriodo = createAsyncThunk(
  'periodos/InsertarTipoPeriodo',
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().post('admin/tipoPeriodos', data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ActualizarTipoPeriodo = createAsyncThunk(
  'periodos/ActualizarTipoPeriodo',
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put(
        'admin/tipoPeriodos/' + data.periodoTipoId,
        data
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const EliminarTipoPeriodo = createAsyncThunk(
  'periodos/EliminarTipoPeriodo',
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().delete(
        'admin/tipoPeriodos/' + data.periodoTipoId
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ObtenerTiposSesiones = createAsyncThunk(
  'periodos/obtenerSesiones',
  async (i, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().get('admin/Sesion');
      return res.data.result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const ActualizarPeriodos = createAsyncThunk(
  'periodos/ActualizarPeriodos',
  async (data, { rejectWithValue }) => {
    try {
      const res = await WebApi.api().put('admin/Periodos/' + data.id, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const periodosSlice = createSlice({
  name: 'periodos',
  initialState: {
    periodoFechas: {},
    periodos: [],
    model: {},
    tiposPeriodos: [],
    tiposSesiones: [],
  },
  reducers: {},
  extraReducers: {
    [ObtenerTodos.fulfilled]: (state, action) => {
      state.periodos = action.payload;
    },
    [ObtenerTiposPeriodos.fulfilled]: (state, action) => {
      state.tiposPeriodos = action.payload;
    },
    [ObtenerTiposSesiones.fulfilled]: (state, action) => {
      state.tiposSesiones = action.payload;
    },
    [ObtenerPeriodoFechas.fulfilled]: (state, action) => {
      state.periodosFechas = action.payload;
    },
    [ObtenerById.fulfilled]: (state, action) => {
      state.model = action.payload;
    },
  },
});

export default periodosSlice.reducer;
