import React, { useState, useEffect } from "react";
import TableBody from "./TableBody";
import TableHead from "./TableHead";

export const OrderTable = ({
  clase,
  columns,
  data,
  actions = [],
  renderActionItems,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  return (
    <table className={clase}>
      <TableHead {...{ columns, handleSorting }} />
      <TableBody
        actions={actions}
        {...{ columns, tableData }}
        renderActionItems={renderActionItems}
      />
    </table>
  );
};
