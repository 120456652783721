import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import ModalRemove from "./ModalRemove";
import { useDispatch, useSelector } from "react-redux";
import {
  ObtenerTodos,
  ActualizarEstadoPeriodo,
  ImportarPeriodos,
  ActualizarPeriodosFechas,
  InsertarPeriodosFechas,
  ObtenerTiposSesiones,
  ObtenerTiposPeriodos,
  ActualizarPeriodos,
} from "../../redux/periodosSlice";
import WindowDimension from "../../Hooks/WindowDimension";
import Required from "../../Components/Required";
import { OrderTable } from "../../Components/OrderTable";
import useSubmit from "../../Hooks/useSubmit";

const columns = [
  { label: "Código", accessor: "periodoId", sortable: true },
  { label: "Nombre", accessor: "periodoNombre", sortable: true },
  { label: "Tipo de periodo", accessor: "tipoNombre", sortable: true },
  { label: "Año", accessor: "anio", sortable: true },
  {
    label: "Estado",
    accessor: "estatus",
    sortable: true,
    render: (item) => <td style={{ color: item.color }}>{item.estatus}</td>,
  },
  { label: "Acción", accessor: "action", sortable: false },
];

function Periodos(props) {
  const { width } = WindowDimension();

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [tituloModal, setTituloModal] = useState("Agregar");
  const [removeState, changeRemoveState] = useState(false);
  const [itemSelected, setItemSelected] = useState({});

  const dispatch = useDispatch();
  let caracter = useRef();
  let filtroAnio = useRef();
  const { periodos, tiposPeriodos } = useSelector((state) => state.periodos);

  const { submitAction } = useSubmit();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const {
    register: registerP,
    formState: { errors: errorsP },
    handleSubmit: handleSubmitP,
    reset: resetP,
  } = useForm();

  const showModalEdit = (item) => {
    setItemSelected(item);
    reset(item);
    item.periodosFechaAperturaId === 0
      ? setTituloModal("Agregar fechas")
      : setTituloModal("Editar fechas");

    setShowModal(true);
  };
  const showEdit = (item) => {
    setItemSelected(item);
    resetP(item);
    setShowEditModal(true);
  };

  const closeModalEdit = () => {
    reset({ periodoId: 0, periodoNombre: "" });
    setShowModal(false);
  };
  const closeModal = () => {
    resetP({ periodoId: 0, periodoTipoId: 0 });
    setShowEditModal(false);
  };

  const showRemoveModalEdit = (item) => {
    setItemSelected(item);
    changeRemoveState(true);
  };

  const closeRemoveModalEdit = () => {
    setItemSelected({});
    changeRemoveState(false);
  };

  const cargarPeriodos = () => {
    dispatch(ObtenerTodos());
    dispatch(ObtenerTiposSesiones());
    dispatch(ObtenerTiposPeriodos());
  };

  const cargarPeriodosPorCaracter = () => {
    let object = { anio: null, periodoNombre: caracter.current.value };
    dispatch(ObtenerTodos(object));
  };

  const cargarPeriodosPorAnio = () => {
    let object = { anio: filtroAnio.current.value, periodoNombre: null };
    dispatch(ObtenerTodos(object));
  };

  const actualizarEstado = async (estado) => {
    let object = { id: itemSelected.periodoId, estado: estado };
    submitAction(ActualizarEstadoPeriodo, object, () => {
      closeRemoveModalEdit();
      cargarPeriodos();
    });
  };

  const importar = async () => {
    submitAction(ImportarPeriodos, undefined, cargarPeriodos);
  };

  const onSubmit = async (data) => {
    submitAction(
      data.periodosFechaAperturaId > 0
        ? ActualizarPeriodosFechas
        : InsertarPeriodosFechas,
      data,
      () => {
        closeModalEdit();
        cargarPeriodos();
      }
    );
  };

  const onSubmitP = async (data) => {
    let model = {
      id: data.periodoId,
      periodoTipo: data.periodoTipoId,
    };
    submitAction(ActualizarPeriodos, model, () => {
      closeModal();
      cargarPeriodos();
    });
  };

  useEffect(() => {
    cargarPeriodos();
  }, [dispatch]);

  return (
    <div className="main-admin">
      <div className="main-admin-bar">
        <div className="header-admin-bar-items">
          <h2>Periodos</h2>
        </div>
        <div className="header-admin-bar-items ml-auto">
          <label hidden>Filtrar por año</label>
          <select ref={filtroAnio} onChange={cargarPeriodosPorAnio}>
            <option value={0}>Todos los años</option>
            {new Array(40).fill(0).map((b, i) => (
              <option key={i} value={new Date().getFullYear() + 1 - i}>
                {new Date().getFullYear() + 1 - i}
              </option>
            ))}
          </select>
          <label hidden>Búsqueda</label>
          <input
            onChange={cargarPeriodosPorCaracter}
            type="search"
            className="form-control"
            ref={caracter}
            placeholder="Búsqueda"
          />

          <button className="search-button" onClick={importar}>
            <i className="material-icons"></i>
          </button>
        </div>
      </div>

      <div className="table-container">
        {periodos?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={periodos}
              clase="table-adm"
              renderActionItems={(item) => {
                return (
                  <td key={Math.random()}>
                    <button
                      className="modBtn text-primary "
                      onClick={() => {
                        showEdit(item);
                      }}
                    >
                      <i className="material-icons"></i>
                    </button>
                    <button
                      className="modBtn text-primary "
                      onClick={() => {
                        showModalEdit(item);
                      }}
                    >
                      <i className="material-icons"></i>
                    </button>
                    <button
                      className="delete"
                      onClick={() => {
                        showRemoveModalEdit(item);
                      }}
                    >
                      {item.estadoId === 4 ? (
                        <i className="material-icons statusRed"></i>
                      ) : (
                        <i className="material-icons statusGreen"></i>
                      )}
                    </button>
                  </td>
                );
              }}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>

      <Modal
        estado={showModal}
        cambiarEstado={closeModalEdit}
        titulo={tituloModal}
        clase={""}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(onSubmit)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          {itemSelected.periodosFechaAperturaId === 0 ? (
            <label>
              Nuevas fechas de inscripción del período
              <pre />
              {itemSelected.periodoNombre} {itemSelected.anio}
            </label>
          ) : (
            <label>
              Edición de fechas de inscripción establecidas para el período
              <pre />
              {itemSelected.periodoNombre} {itemSelected.anio}
            </label>
          )}
          <div className="flex-form gap-20">
            <div>
              <div className="form-group flex-form">
                <input
                  type="checkbox"
                  {...register("abiertoAestudiante")}
                  name="abiertoAestudiante"
                />
                <label>Estado del periodo - estudiantes</label>
              </div>

              <div className="form-group">
                <label>Fecha de inicio para estudiantes</label>
                <Required />
                <input
                  type="date"
                  className="form-control"
                  name="fechaEstudianteApertura"
                  {...register("fechaEstudianteAperturaFormato", {
                    required: "Campo requerido",
                  })}
                />
                {errors.fechaEstudianteAperturaFormato && (
                  <p className="error">
                    {errors.fechaEstudianteAperturaFormato.message}
                  </p>
                )}
              </div>

              <div className="form-group">
                <label>Fecha de fin para estudiantes</label>
                <Required />
                <input
                  type="date"
                  className="form-control"
                  {...register("fechaEstudianteCierreFormato", {
                    required: "Campo requerido",
                  })}
                />
                {errors.fechaFin && (
                  <p className="error">
                    {errors.fechaEstudianteCierreFormato.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <div className="form-group flex-form">
                <input
                  type="checkbox"
                  {...register("abiertoAadministrador")}
                  name="abiertoAadministrador"
                />
                <label>Estado del periodo - administradores</label>
              </div>

              <div className="form-group">
                <label>Fecha de inicio para administradores</label>
                <Required />
                <input
                  type="date"
                  className="form-control"
                  {...register("fechaAdministradorAperturaFormato", {
                    required: "Campo requerido",
                  })}
                />
                {errors.fechaInicio && (
                  <p className="error">
                    {errors.fechaAdministradorApertura.message}
                  </p>
                )}
              </div>

              <div className="form-group">
                <label>Fecha de fin para administradores</label>
                <Required />
                <input
                  type="date"
                  className="form-control"
                  {...register("fechaAdministradorCierreFormato", {
                    required: "Campo requerido",
                  })}
                />
                {errors.fechaFin && (
                  <p className="error">
                    {errors.fechaAdministradorCierre.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <ModalRemove
        estado={removeState}
        closeModalHandled={closeRemoveModalEdit}
        title="Eliminar período"
        message={
          itemSelected.estadoId === 4
            ? "¿Seguro que desea desactivar el siguiente registro?"
            : "¿Seguro que desea activar el siguiente período?"
        }
        item={itemSelected.periodoNombre}
        btnGroup={
          <>
            <button
              className="button-text text-red"
              onClick={() =>
                actualizarEstado(itemSelected.estadoId === 4 ? 5 : 4)
              }
            >
              Confirmar
            </button>
            <button
              className="button-text text-grey"
              onClick={() => {
                closeRemoveModalEdit(false);
              }}
            >
              Cancelar
            </button>
          </>
        }
      />

      <Modal
        estado={showEditModal}
        cambiarEstado={closeModal}
        titulo={"Editar"}
        clase={`${width < 576 ? "full-width" : "small"}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmitP(onSubmitP)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="form-group">
            <label>Tipo de periodo</label>
            <Required />
            <select
              className="form-control"
              {...registerP("periodoTipoId", { required: "Campo requerido" })}
            >
              <option value={undefined}>Seleccione</option>
              {tiposPeriodos?.map((a, i) => {
                return (
                  <option key={i} value={a.periodoTipoId}>
                    {a.periodoTipoNombre}
                  </option>
                );
              })}
            </select>
            {errorsP.periodoTipoId && (
              <p className="error">{errorsP.periodoTipoId.message}</p>
            )}
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Periodos;
