import React, { useState, useEffect } from "react";
import "../css/inicio.css";
import TitleBar from "../Components/TitleBar";
import AppBar from "../Components/AppBar";
import { useLocation, Outlet } from "react-router-dom";
import UserInfo from "../Components/UserInfo";
import tituloIMG from "../../SVG/TitleImage.svg";
import tituloIMGSolicitud from "../../SVG/TitleImage2.svg";
import { useDispatch, useSelector } from "react-redux";
import { obtenerUsuarioActual } from "../../redux/usuariosSlice";
import { obtenerNotificacionesUsuario } from "../../redux/usuariosSlice";

export default function Inicio(props) {
  const { usuario } = useSelector((state) => state.usuarios);
  const dispatch = useDispatch();

  const [section, setSection] = useState("ADMISIONES");
  const [image, setImage] = useState(tituloIMG);
  const [secciones, setSecciones] = useState([
    { id: 1, titulo: "ADMISIONES", url: "", img: tituloIMG },
    { id: 2, titulo: "EDITAR USUARIO", url: "userInfo", img: tituloIMG },
    { id: 3, titulo: "CONFIGURACIÓN", url: "configuracion", img: tituloIMG },
    {
      id: 4,
      titulo: "NUEVA SOLICITUD",
      url: "Solicitud",
      img: tituloIMGSolicitud,
    },
  ]);

  const location = useLocation();

  useEffect(() => {
    dispatch(obtenerUsuarioActual());
    dispatch(obtenerNotificacionesUsuario());
  }, [dispatch]);

  useEffect(() => {
    verifyURL();
  }, [location]);

  const verifyURL = () => {
    let a = location.pathname;
    var x = a.split("/");
    let e;
    e = secciones.find((b) => b.url == x[1]);

    if (e != undefined) {
      setSection(e.titulo);
      setImage(e.img);
    }
  };

  return (
    <div className="rootInicio">
      <AppBar />
      <TitleBar titulo={section} imagen={image} />
      <UserInfo usuario={usuario} />

      <Outlet />
    </div>
  );
}
