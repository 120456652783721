import axios from 'axios';
import OperationStatus from '../config/OperationStatus';
import EventBus from '../services/EventBus';

let token = null;

function interceptors(axios) {
  axios.interceptors.response.use(null, (error) => {
    if (
      error.config &&
      error.response?.status === 401 &&
      !error.config.__isRetry
    ) {
      return new Promise((resolve, reject) => {
        refreshToken(axios, error.config)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    return Promise.reject(error);
  });
}

const refreshToken = (axios, config) => {
  return new Promise((resolve, reject) => {
    let refrToken = JSON.parse(localStorage.getItem('refreshToken'));
    if (refrToken) {
      let data = { refreshToken: refrToken.token };

      axios
        .post(`${process.env.REACT_APP_BASEURL}Usuarios/RefreshToken`, data)
        .then((res) => {
          if (res.data.operationStatus === OperationStatus.SUCCESS) {
            token = res.data.result;
            localStorage.setItem('token', res.data.result);
            config.headers.Authorization = 'Bearer ' + res.data.result; // Attach the new token to the headers
            axios
              .request(config)
              .then((result) => {
                return resolve(result);
              })
              .catch((err) => {
                console.log(err);
                return reject(err);
              });
          } else {
            //log out
            EventBus.dispatch('logout');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};

export default (function WebApi() {
  let t = localStorage.getItem('token');
  if (t) {
    setAuthToken(t);
  }

  function api() {
    if (token == null) {
      return axios.create({
        baseURL: process.env.REACT_APP_BASEURL,
      });
    } else {
      const x = axios.create({
        baseURL: process.env.REACT_APP_BASEURL,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      interceptors(x);
      return x;
    }
  }

  function setAuthToken(el) {
    token = el;
  }

  function apiMultiPart(url, formData) {
    if (token == null) {
      return fetch(process.env.REACT_APP_BASEURL + url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        body: formData,
      });
    } else {
      return fetch(process.env.REACT_APP_BASEURL + url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: formData,
      });
    }
  }

  return { api, setAuthToken, apiMultiPart };
})();
